import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'store';

import { userPermissionsValues } from 'shared/enum/userPermissionsEnum';
import { UserPermissions } from 'shared/interface/UserPermissions';

export const useHasPermissions = (
  data: userPermissionsValues | userPermissionsValues[]
) => {
  const permissions = useSelector(
    (state: RootState) => state.authReducer.user?.permissions
  );

  const userHasPermission = permissions?.some((p: UserPermissions) =>
    Array.isArray(data) ? data.includes(p.name) : p.name === data
  );

  const [hasPermissions, setHasPermissions] =
    useState<undefined | boolean>(userHasPermission);

  useEffect(() => {
    if (permissions) {
      setHasPermissions(userHasPermission);
    }
  }, [permissions]);

  return hasPermissions;
};
