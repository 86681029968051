import styled from 'styled-components';
import { Controller, FieldError, useForm, FormProvider } from 'react-hook-form';
import { RootState } from 'store';
import { createPortal } from 'react-dom';
import { toast } from 'react-toastify';
import { useEffect, useRef, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router';
import { useSelector } from 'react-redux';

import { ReactComponent as InfoWarningIcon } from 'assets/icons/info-warning.svg';

import FinishColor from 'order/wizard/orderStyles/productLines/steps/OrderStylesSpecifications/FinishColor/FinishColor';
import ImpressionFinish from 'order/wizard/orderStyles/productLines/steps/OrderStylesSpecifications/Impression/ImpressionFinish';
import OrderFinishLabel from 'order/components/OrderFinishLabel/OrderFinishLabel';
import OrderFinishValue from 'order/components/OrderFinishValue/OrderFinishValue';
import OrderStyleNote from 'order/wizard/orderStyles/shared/OrderStyleNote';
import VarnishSheen from 'order/wizard/orderStyles/productLines/steps/OrderStylesSpecifications/VarnishSheen/VarnishSheen';
import { CabinetBoxMaterialOptionsEnum } from 'order/wizard/orderStyles/enums/CabinetBoxMaterialOptionsEnum';
import { DoorBuilderBreakingChange } from 'order/wizard/orderStyles/interface/DoorBuilderBreakingChange';
import { OrderFooter } from 'order/components/OrderFooter/OrderFooter';
import { OrderPageParams } from 'order/interfaces/OrderPageParams';
import { SpecificationsOptionsEnum } from 'order/wizard/orderStyles/enums/SpecificationsOptionsEnum';
import { Style } from 'order/wizard/orderStyles/interface/Style';
import { StyleModalEnum } from 'order/wizard/orderStyles/enums/StyleModalEnum';
import { StylesStepsEnum } from 'order/wizard/orderStyles/enums/StylesStepsEnum';
import { setOrderIsValid } from 'order/store/orderActions';

import {
  SpecFieldRequest,
  StyleSpecifications,
  StyleSpecificationsRequest,
} from 'order/wizard/orderStyles/interface/StyleSpecifications';

import FormError from 'shared/components/FormError';
import Loader, { LoaderFullScreen } from 'shared/components/Loader';
import SVG from 'shared/components/SVG';
import UtilService from 'shared/services/util.service';
import WarningTooltip from 'shared/components/WarningTooltip';
import { ButtonPrimary, ButtonSecondary } from 'shared/components/Button';
import { Checkbox } from 'shared/components/Checkbox';
import { ConfirmationModal } from 'shared/components/ConfirmationModal';
import { Form } from 'shared/components/Form';
import { FormElement } from 'shared/components/FormElement';
import { FormLabel } from 'shared/components/FormLabel';
import { H2, H3, H4, PMedium } from 'shared/components/Typography';
import { Input } from 'shared/components/Input';
import { ModalWrapper } from 'shared/components/ModalWrapper';
import { RadioButton } from 'shared/components/RadioButton';
import { Select } from 'shared/components/Select';
import { SelectOptionProps } from 'shared/interface/SelectOptionProps';
import { ServerErrorResponse } from 'shared/interface/serverResponses/ServerErrorResponse';
import { Spacer } from 'shared/components/Layout';
import { Textarea } from 'shared/components/Textarea';
import { Wrapper } from 'shared/components/Wrapper';
import { anzac } from 'shared/config/Colors';
import { useAppDispatch } from 'shared/hooks/useAppDispatch';
import { useCanSubmit } from 'shared/hooks/useCanSubmit';
import { useHasPermissions } from 'shared/hooks/useHasPermissions';
import { usePopupMessage } from 'shared/hooks/usePopupMessage';
import { useProductLineConfiguration } from 'shared/hooks/useProductLineConfiguration';
import { useUndefinedOptionAsFirst } from 'shared/hooks/useUndefinedOptionAsFirst';
import { userPermissionsValues } from 'shared/enum/userPermissionsEnum';

import {
  noteValidation,
  specialFinishSampleValidation,
  upchargeValidation,
  workOrderNumberValidation,
} from 'shared/validations/validations';

import { testId } from 'tests/utils';

import {
  OrderStyleSpecificationsTestComboBoxEnum,
  OrderStyleSpecificationsTestInputEnum,
  OrderStyleSpecificationsTestRadioButtonEnum,
  OrderStyleSpecificationsTestTextAreaEnum,
} from 'tests/enums/OrderStyleSpecificationsTestEnums';

import DoorBuilderBreakingChanges from '../OrderStylesDoorBuilder/DoorBuilderBreakingChanges/DoorBuilderBreakingChanges';
import FinishEffectNew from './FinishEffectNew/FinishEffectNew';
import FinishNotes from './FinishNotes/FinishNotes';
import ShowAvailableFinishes from './ShowAvailableFinishes/ShowAvailableFinishes';
import VendorColor from './VendorColor/VendorColor';
import WoodEffectNew from './WoodEffectNew/WoodEffectNew';
import { useIsStepDirty } from '../../../../../../shared/hooks/useIsStepDirty';
import { useScrollToElement } from '../../../../../../shared/hooks/useScrollToElement';

import {
  resetDoorBuilder,
  validateStyleDoorBuilder,
} from '../../store/doorBuilder/orderStylesDoorBuilderActions';

import {
  changeStylesStep,
  clearStyleSpecificationsOptions,
  getStyle,
  setIsStylesStepDirty,
} from '../../store/orderStylesActions';

import {
  clearFinishColors,
  getCabinetBoxMaterials,
  getFinishColors,
  getSpecsOptionsByWoodOrMaterial,
  getWoodOrMaterial,
  saveStyleSpecs,
  saveStyleSpecsImpressions,
  validateStyleSpecifications,
} from '../../store/specifications/orderStylesSpecificationActions';

const StaticValue = styled(Wrapper)`
  height: 44px;
  font-weight: 600;

  &.invalid {
    color: ${({ theme }) => theme.valencia};
  }
`;

const FormColumns = styled(Wrapper)``;

const LeftColumn = styled.div`
  max-width: 456px;
  flex: 1;
`;

const RightColumn = styled.div`
  flex: 1;
`;

const IconWrapper = styled(Wrapper)`
  min-width: 18px;
`;

const StyledP = styled(PMedium)`
  color: ${({ theme }) => theme.anzac};
`;

const FooterAvailableWarning = () => {
  return (
    <Wrapper flex middle maxWidth={600}>
      <IconWrapper flex middle>
        <SVG icon={<InfoWarningIcon />} color={anzac} />
      </IconWrapper>
      <Spacer w="20px" />

      <StyledP>
        A door only sample is required for the specified finish. You must submit
        an order for the door only sample and that sample must be approved
        before processing the order.
      </StyledP>
    </Wrapper>
  );
};

const OrderStylesSpecifications = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const location = useLocation();
  const { orderId } = useParams<OrderPageParams>();

  const [loading, setLoading] = useState(false);
  const [isResetModalVisible, setIsResetModalVisible] = useState(false);
  const [shouldGoBack, setShouldGoBack] = useState(false);
  const [finishColorsLoading, setFinishColorsLoading] = useState(false);

  const [breakingChanges, setBreakingChanges] =
    useState<DoorBuilderBreakingChange[] | null>(null);

  const isUserCSR = useHasPermissions(
    userPermissionsValues.CSR_VIEW_ORDERBOARD
  );

  const styleOverride = useSelector(
    (state: RootState) => state.orderStylesReducer.style?.override
  );

  const storedSpecifications = useSelector(
    (state: RootState) => state.orderStylesReducer.specifications
  );

  const storedStyleNote = useSelector(
    (state: RootState) => state.orderStylesReducer.style?.note
  );

  const storedColorSample = useSelector(
    (state: RootState) => state.orderStylesReducer.style?.colorSample
  );

  const storedWorkOrderNumber = useSelector(
    (state: RootState) =>
      state.orderStylesReducer.style?.originalWorkOrderNumber
  );

  const styleId = useSelector(
    (state: RootState) => state.orderStylesReducer.styleId
  );

  const storedWoodNotes = useSelector(
    (state: RootState) => state.orderStylesReducer.style?.woodNotes
  );

  const storedFinishNotes = useSelector(
    (state: RootState) => state.orderStylesReducer.style?.finishNotes
  );

  const storedEdgebandColor = useSelector(
    (state: RootState) => state.orderStylesReducer.style?.edgebandColor
  );

  const { doorOverlay, faceFrame, frameStyle, drawerBox } = useSelector(
    (state: RootState) => state.orderStylesReducer.style ?? ({} as Style)
  );

  const styleStep = useSelector(
    (state: RootState) => state.orderStylesReducer.style?.step
  );

  const editMode = useSelector(
    (state: RootState) => state.orderStylesReducer.style?.woodMaterial !== null
  );

  const isStyleComplete = useSelector(
    (state: RootState) => state.orderStylesReducer.style?.isComplete
  );

  const selectedProductLine = useSelector(
    (state: RootState) => state.orderStylesReducer.productLine
  );

  const woodOrMaterialOptions = useSelector(
    (state: RootState) =>
      state.orderStylesReducer.specificationsOptions.woodOrMaterialOptions
  );

  const woodInteriorOptions = useSelector(
    (state: RootState) =>
      state.orderStylesReducer.specificationsOptions.woodInteriorOptions
  );

  const finishImpressionData = useSelector(
    (state: RootState) => state.orderStylesReducer.finishImpressionData
  );

  const finishImpressionOptions = useSelector(
    (state: RootState) =>
      state.orderStylesReducer.specificationsOptions.finishImpressionOptions
  );

  const canEdit = useSelector((state: RootState) => state.orderReducer.canEdit);

  const isConvertFlow = useSelector(
    (state: RootState) =>
      state.orderStylesReducer.modalOpenedForStyleId ===
      StyleModalEnum.CONVERT_STYLE
  );

  const cabinetBoxOptions = useSelector(
    (state: RootState) =>
      state.orderStylesReducer.specificationsOptions.cabinetBoxMaterialOptions
  );

  const finishColorOptions = useSelector(
    (state: RootState) =>
      state.orderStylesReducer.specificationsOptions.finishColorOptions
  );

  const orderStyleWoodMaterial = useSelector(
    (state: RootState) => state.orderStylesReducer.style?.woodMaterial
  );

  const defaultValues: Partial<StyleSpecifications> = {
    cabinetBoxMaterial: null,
    cabinetBoxMaterialUpcharge: '',
    engineeredFurnitureBoardConstruction: false,
    finishColor: null,
    finishColorUpcharge: '',
    finishImpression: null,
    finishNotes: '',
    selectWoodSpecies: '',
    showAvailableFinishesOnly: false,
    specialCabinetBoxMaterial: '',
    specialFinishSample: '',
    specificationOption: '1',
    styleNote: storedStyleNote ?? '',
    varnishSheen: null,
    varnishUpcharge: '',
    woodInterior: null,
    woodNotes: '',
    woodOrMaterial: null,
    woodOrMaterialUpcharge: '',

    // csr fields default values
    colorSample: true,
    edgebandColor: '',
    naturalInterior: false,
    woodTape: true,
    workOrderNumber: storedWorkOrderNumber ?? '',
  };

  const methods = useForm<StyleSpecifications>({
    defaultValues,
    mode: 'onSubmit',
    reValidateMode: 'onChange',
  });

  useIsStepDirty({
    isDirty: methods.formState.isDirty,
    dirtySetter: setIsStylesStepDirty,
    dirtyGetter: (state: RootState) => state.orderStylesReducer.isStepDirty,
  });

  const config = useProductLineConfiguration();
  const { onFormChange, PopupModal } = usePopupMessage();

  useEffect(() => {
    onFormChange(methods);

    const errorFieldsArray = Object.keys(methods.formState.errors);

    if (methods.formState.isDirty && errorFieldsArray.length > 0) {
      setIsResetModalVisible(false);
      UtilService.onPopupClose();
    }
  }, [methods.formState]);

  const finishColorWatched = methods.watch('finishColor');
  const specificationOptionWatched = methods.watch('specificationOption');
  const woodOrMaterialWatched = methods.watch('woodOrMaterial');
  const woodInteriorWatched = methods.watch('woodInterior');
  const specialFinishSampleWatched = methods.watch('specialFinishSample');
  const edgebandColorWatched = methods.watch('edgebandColor');

  const showAvailableFinishesOnlyWatched = methods.watch(
    'showAvailableFinishesOnly'
  );

  const finishEffectsWatched = methods.watch('finishEffects');
  const cabinetBoxMaterialWatched = methods.watch('cabinetBoxMaterial');

  const [impressionSelected, setImpressionSelected] = useState(false);
  const [specSaving, setSpecSaving] = useState(false);
  const [specSavingOnBack, setSpecSavingOnBack] = useState(false);
  const [validationLoading, setValidationLoading] = useState(false);

  const redirect = (goBack: boolean) => {
    if (goBack) {
      dispatch(changeStylesStep(StylesStepsEnum.PRODUCT_LINE));
    } else {
      dispatch(changeStylesStep(StylesStepsEnum.DOOR_BUILDER));
    }
  };

  const styleSpecSavedHandler = (goBack: boolean = false) => {
    dispatch(
      getStyle(
        { orderId, styleId: styleId! },
        () => redirect(goBack),
        (isLoading) => (goBack ? setSpecSavingOnBack : setSpecSaving)(isLoading)
      )
    );
  };

  const styleSpecImpressionSavedHandler = (goBack: boolean = false) => {
    dispatch(
      getStyle(
        { orderId, styleId: styleId! },
        () => redirect(goBack),
        (isLoading) => (goBack ? setSpecSavingOnBack : setSpecSaving)(isLoading)
      )
    );
  };

  const saveStyleSpectErrorHandler = (err: ServerErrorResponse) => {
    setSpecSaving(false);
    setSpecSavingOnBack(false);

    if (err.status === 403) {
      toast.error("You don't have permission to update this style.");
    } else {
      toast.error('Something went wrong.');
    }
  };

  const mapStyleSpecificationsToRequest = (data: StyleSpecifications) => {
    return {
      orderId,
      styleId: styleId!,
      woodNotes: data.woodNotes,
      finishNotes: data.finishNotes,
      finishColor: {
        id: data.finishColor!.value,
        upcharge: +data.finishColorUpcharge!,
      },
      ...(data.specialFinishSample && {
        specialFinishSample: data.specialFinishSample,
      }),
      varnish: {
        id: data.varnishSheen?.value,
        upcharge: +data.varnishUpcharge,
      },
      ...(data.woodInterior && {
        woodInterior: {
          id: data.woodInterior.value,
        },
      }),
      ...((data.vendorColorCode || data.vendorColorName) && {
        vendorColor: {
          code: data.vendorColorCode ?? '',
          name: data.vendorColorName ?? '',
        },
      }),
      selectWoodSpecies: data.selectWoodSpecies,
      showAvailableFinishesOnly: data.showAvailableFinishesOnly,
      engineeredFurnitureBoardConstruction:
        data.engineeredFurnitureBoardConstruction,
      woodMaterial: {
        id: data.woodOrMaterial!.value!,
        upcharge: +data.woodOrMaterialUpcharge,
      },
      finishEffects: data.finishEffects?.reduce((agg, curr) => {
        if (curr.selectedOption) {
          agg.push({
            id: curr.selectedOption.value,
            orderNumber: +curr.orderNumber,
            upcharge: +curr.upcharge,
            showAvailableEffectsOnly: curr.showAvailableOnly ?? false,
          });
        }

        return agg;
      }, [] as SpecFieldRequest[]),
      woodEffects: data.woodEffects?.reduce((agg, curr) => {
        if (curr.selectedOption) {
          agg.push({
            id: curr.selectedOption.value,
            orderNumber: +curr.orderNumber,
            upcharge: +curr.upcharge,
          });
        }

        return agg;
      }, [] as SpecFieldRequest[]),
      cabinetBoxMaterialId: data.cabinetBoxMaterial
        ? +data.cabinetBoxMaterial.value
        : undefined,
      cabinetBoxMaterialUpcharge: data.cabinetBoxMaterialUpcharge
        ? +data.cabinetBoxMaterialUpcharge
        : undefined,
      productLine: selectedProductLine?.name,
      note: data.styleNote,
      // csr fields
      colorSample: data.colorSample,
      edgebandColor: data.edgebandColor,
      naturalInterior: data.naturalInterior,
      woodTape: data.woodTape,
      specialCabinetBoxMaterial: data.specialCabinetBoxMaterial,
      workOrderNumber: data.workOrderNumber,
      ...(styleOverride && {
        isOverriden: true,
        overrideReason: styleOverride.overridenReason,
      }),
    } as StyleSpecificationsRequest;
  };

  const onSubmitHandler = ({
    data,
    goBack = false,
    shouldResetDoorBuilder = false,
  }: {
    data: StyleSpecifications;
    goBack?: boolean;
    shouldResetDoorBuilder?: boolean;
  }) => {
    if (shouldResetDoorBuilder) {
      dispatch(resetDoorBuilder());
      dispatch(setOrderIsValid(false));
    }

    if (!canEdit) {
      redirect(goBack);
      return;
    }

    if (goBack) {
      setSpecSavingOnBack(true);
    } else {
      setSpecSaving(true);
    }

    if (!data.finishImpression) {
      dispatch(
        saveStyleSpecs(
          mapStyleSpecificationsToRequest(data),
          () => styleSpecSavedHandler(goBack),
          saveStyleSpectErrorHandler
        )
      );
    } else {
      dispatch(
        saveStyleSpecsImpressions(
          {
            orderId,
            styleId: styleId!,
            finishImpressionId: data.finishImpression.value,
            ...(data.cabinetBoxMaterial && {
              cabinetBoxMaterial: {
                id: data.cabinetBoxMaterial
                  ? +data.cabinetBoxMaterial.value
                  : undefined,
                upcharge: data.cabinetBoxMaterialUpcharge
                  ? +data.cabinetBoxMaterialUpcharge
                  : undefined,
              },
            }),
            specialCabinetBoxMaterial: data.specialCabinetBoxMaterial,
            finishNotes: data.finishNotes,
            woodNotes: data.woodNotes,
            note: data.styleNote,
            workOrderNumber: data.workOrderNumber,
            naturalInterior: data.naturalInterior,
            colorSample: data.colorSample,
            woodTape: data.woodTape,
            edgebandColor: data.edgebandColor,

            woodMaterialUpCharge: +data.woodOrMaterialUpcharge,

            ...(styleOverride && {
              isOverriden: true,
              overrideReason: styleOverride.overridenReason,
            }),
          },
          () => styleSpecImpressionSavedHandler(goBack),
          saveStyleSpectErrorHandler
        )
      );
    }
  };

  const onDoorBuilderBreakingChangesModalDismissHandler = (
    close: () => void
  ) => {
    setBreakingChanges(null);
    close();
  };

  const styleDoorBuilderValidationResponse = (
    breakingChangeItems: DoorBuilderBreakingChange[],
    goBack: boolean
  ) => {
    setValidationLoading(false);

    if (breakingChangeItems.length > 0) {
      setBreakingChanges(breakingChangeItems);
      setShouldGoBack(goBack);
      return;
    }

    methods.handleSubmit((data) => onSubmitHandler({ data, goBack }))();
  };

  const validateLineItems = (goBack: boolean) => {
    if (styleId && cabinetBoxMaterialWatched) {
      dispatch(
        validateStyleDoorBuilder(
          {
            orderId,
            styleId,
            cabinetBoxMaterialId: cabinetBoxMaterialWatched!.value,
            doorOverlayId: doorOverlay.id,
            drawerBoxId: drawerBox.id,
            faceFrameId: faceFrame.id,
            frameStyleId: frameStyle.id,
          },
          (breakingChangeItems) =>
            styleDoorBuilderValidationResponse(breakingChangeItems, goBack)
        )
      );
    }
  };

  const validationResponse = (isValid: boolean, goBack: boolean) => {
    if (!isValid) {
      setIsResetModalVisible(true);
      setShouldGoBack(goBack);
      setValidationLoading(false);
      return;
    }

    if (
      editMode &&
      isStyleComplete &&
      isValid &&
      cabinetBoxMaterialWatched &&
      methods.formState.dirtyFields.cabinetBoxMaterial
    ) {
      validateLineItems(goBack);
      return;
    }

    setValidationLoading(false);
    methods.handleSubmit((data) =>
      onSubmitHandler({
        data,
        goBack,
      })
    )();
  };

  const handleValidateOrSubmit = (goBack: boolean) => {
    if (
      editMode &&
      !styleOverride &&
      (methods.formState.dirtyFields.woodOrMaterial ||
        methods.formState.dirtyFields.finishImpression ||
        methods.formState.dirtyFields.cabinetBoxMaterial) &&
      (woodOrMaterialWatched ||
        finishImpressionData ||
        cabinetBoxMaterialWatched)
    ) {
      setValidationLoading(true);

      dispatch(
        validateStyleSpecifications(
          {
            orderId,
            styleId: styleId!,
            woodMaterialId:
              finishImpressionData?.woodMaterial.id ??
              woodOrMaterialWatched?.value ??
              '',
          },
          (isValid) => validationResponse(isValid, goBack)
        )
      );

      return;
    }

    methods.handleSubmit((data) =>
      onSubmitHandler({
        data,
        goBack,
      })
    )();
  };

  const setFormValuesOnConfigChange = () => {
    const rippedDefaultValues = UtilService.omit(defaultValues, [
      'specificationOption',
    ]);

    // reset values to initial (without ripped values)
    dispatch(clearFinishColors());

    Object.entries(rippedDefaultValues).forEach(([key, value]) => {
      if (key === 'showAvailableEffectsOnly') {
        methods.setValue(key as keyof StyleSpecifications, false, {
          shouldDirty: false,
        });
      } else {
        methods.setValue(key as keyof StyleSpecifications, value, {
          shouldDirty: false,
        });
      }
    });
  };

  const onChangeWoodTypeHandler = (type: SpecificationsOptionsEnum) => {
    if (type === SpecificationsOptionsEnum.CONFIGURE_WOOD) {
      setFormValuesOnConfigChange();
    }
  };

  const onAvailableChange = (checked: boolean) => {
    if (woodOrMaterialWatched && selectedProductLine) {
      setFinishColorsLoading(true);
      dispatch(
        getFinishColors(
          {
            showOnlyAvailable: checked,
            woodMaterialId: woodOrMaterialWatched.value,
            productLineId: selectedProductLine.id,
            specialFinishSample: specialFinishSampleWatched,
          },
          setFinishColorsLoading
        )
      );
    }
  };
  const validateForm = () => {
    setTimeout(() => {
      methods.trigger();
    }, 0);
  };

  useEffect(() => {
    if (
      UtilService.dirtyOrTouchedFields(methods.formState, [
        'woodOrMaterial',
        'specialFinishSample',
        'showAvailableFinishesOnly',
        'finishColor',
      ]) &&
      !specSaving &&
      !specSavingOnBack
    ) {
      onAvailableChange(showAvailableFinishesOnlyWatched);
    }
  }, [showAvailableFinishesOnlyWatched]);

  // cleanup
  useEffect(() => {
    return () => {
      dispatch(clearStyleSpecificationsOptions());
    };
  }, []);

  useEffect(() => {
    if (
      finishImpressionOptions &&
      finishImpressionData &&
      !methods.formState.isDirty &&
      storedColorSample !== undefined &&
      storedEdgebandColor !== undefined
    ) {
      const {
        cabinetBoxMaterial,
        cabinetBoxMaterialUpcharge,
        specialCabinetBoxMaterial,
        naturalInterior,
        woodTape,
      } = finishImpressionData;

      methods.reset({
        ...methods.getValues(),
        cabinetBoxMaterial,
        cabinetBoxMaterialUpcharge,
        specialCabinetBoxMaterial,
        naturalInterior,
        colorSample: storedColorSample,
        woodTape,
        specificationOption: SpecificationsOptionsEnum.SELECT_IMPRESSION,
        edgebandColor: storedEdgebandColor,
      });
    }
    if (finishImpressionData) {
      const woodOrMaterialUpcharge = !methods.formState.isDirty
        ? orderStyleWoodMaterial?.upcharge?.toString() ?? ''
        : finishImpressionData.woodMaterial.upcharge?.toString() ?? '';

      UtilService.withDecimal<StyleSpecifications>(
        'woodOrMaterialUpcharge',
        woodOrMaterialUpcharge,
        methods.setValue
      );
    }
  }, [
    storedColorSample,
    storedEdgebandColor,
    finishImpressionOptions,
    finishImpressionData,
  ]);

  useEffect(() => {
    if (!methods.formState.isDirty) {
      methods.setValue('woodNotes', storedWoodNotes ?? '');
      methods.setValue('finishNotes', storedFinishNotes ?? '');
    }
  }, [storedWoodNotes, storedFinishNotes]);

  useEffect(() => {
    methods.clearErrors();
    setImpressionSelected(
      specificationOptionWatched === SpecificationsOptionsEnum.SELECT_IMPRESSION
    );
  }, [specificationOptionWatched]);

  useEffect(() => {
    if (!specSaving && !specSavingOnBack && storedSpecifications) {
      methods.reset(storedSpecifications);
    }
  }, [storedSpecifications]);

  // validate form on load when stored specifications and finish color options are loaded
  useEffect(() => {
    if (
      isConvertFlow &&
      ((storedSpecifications && finishColorOptions) ||
        woodOrMaterialWatched === null)
    ) {
      validateForm();
    }
  }, [
    isConvertFlow,
    storedSpecifications,
    finishColorOptions,
    woodOrMaterialWatched,
  ]);

  useEffect(() => {
    if (isConvertFlow && !methods.formState.isValid) {
      dispatch(setIsStylesStepDirty(true));
    }
  }, [isConvertFlow, methods.formState.isValid]);

  useEffect(() => {
    if (styleId) {
      history.push(`${location.pathname}?styleId=${styleId}`, location.state);
    }
  }, [styleId]);

  useEffect(() => {
    if (selectedProductLine) {
      setLoading(true);
      dispatch(getWoodOrMaterial(selectedProductLine.id, setLoading));
    }
  }, [selectedProductLine]);

  // wood material change
  useEffect(() => {
    if (
      woodOrMaterialWatched &&
      selectedProductLine &&
      !specSaving &&
      !specSavingOnBack
    ) {
      if (methods.formState.dirtyFields.woodOrMaterial) {
        methods.trigger('woodOrMaterial');
        methods.clearErrors('selectWoodSpecies');
        methods.setValue('selectWoodSpecies', '');
        methods.setValue('edgebandColor', '');
        methods.setValue('specialFinishSample', '');
        methods.setValue('showAvailableFinishesOnly', false);
      }

      const woodOrMaterialUpcharge = !methods.formState.isDirty
        ? storedSpecifications?.woodOrMaterialUpcharge ?? ''
        : woodOrMaterialWatched.upcharge?.toString() ?? '';

      UtilService.withDecimal<StyleSpecifications>(
        'woodOrMaterialUpcharge',
        woodOrMaterialUpcharge,
        methods.setValue
      );

      setLoading(true);
      dispatch(
        getSpecsOptionsByWoodOrMaterial(
          {
            woodMaterialId: woodOrMaterialWatched.value,
            productLineId: selectedProductLine.id,
            specialFinishSample: specialFinishSampleWatched,
            showAvailableFinishesOnly: methods.formState.isDirty
              ? false
              : storedSpecifications?.showAvailableFinishesOnly ?? false,
            ...(styleOverride && { isOverriden: true }),
          },
          setLoading
        )
      );
    }
  }, [woodOrMaterialWatched, selectedProductLine]);

  useEffect(() => {
    if (
      config.styleSpecifications.showCabinetBoxMaterial &&
      selectedProductLine
    ) {
      dispatch(getCabinetBoxMaterials(selectedProductLine.id));
    }
  }, [config.styleSpecifications.showCabinetBoxMaterial, selectedProductLine]);

  useEffect(() => {
    if (cabinetBoxMaterialWatched) {
      const newCabinetBoxMaterialUpcharge = !methods.formState.isDirty
        ? storedSpecifications?.cabinetBoxMaterialUpcharge ??
          finishImpressionData?.cabinetBoxMaterialUpcharge ??
          ''
        : cabinetBoxMaterialWatched.upcharge?.toString() ?? '';

      UtilService.withDecimal<StyleSpecifications>(
        'cabinetBoxMaterialUpcharge',
        newCabinetBoxMaterialUpcharge,
        methods.setValue
      );

      if (methods.formState.isDirty) {
        methods.setValue('specialCabinetBoxMaterial', '');
      }
    }
  }, [cabinetBoxMaterialWatched]);

  useEffect(() => {
    if (
      UtilService.dirtyOrTouchedFields(methods.formState, [
        'woodOrMaterial',
        'specialFinishSample',
        'showAvailableFinishesOnly',
        'finishColor',
      ])
    ) {
      if (
        (specialFinishSampleWatched === '' &&
          !showAvailableFinishesOnlyWatched) ||
        (specialFinishSampleWatched.length === 1 &&
          showAvailableFinishesOnlyWatched) ||
        (specialFinishSampleWatched === '' &&
          storedSpecifications?.showAvailableFinishesOnly)
      ) {
        onAvailableChange(showAvailableFinishesOnlyWatched);
      }

      methods.setValue(
        'showAvailableFinishesOnly',
        (storedSpecifications?.showAvailableFinishesOnly ?? false) ||
          specialFinishSampleWatched?.length > 0,
        {
          shouldDirty: true,
        }
      );
    }
  }, [specialFinishSampleWatched]);

  const canSubmit = useCanSubmit({
    isFormDity: methods.formState.isDirty,
    editMode,
    isStyleComplete,
    step: StylesStepsEnum.SPECIFICATIONS,
    styleStep,
  });

  const goNextAndSaveForFirstTime = () => {
    return !methods.formState.isDirty && !isStyleComplete && !editMode;
  };

  const finishColorRef = useRef<HTMLDivElement | null>(null);

  useScrollToElement({
    errors: methods.formState.errors,
    error: methods.formState.errors.finishColor,
    ref: finishColorRef,
    fieldName: 'finishColor',
  });

  const styleLoaderContainer = document.getElementById(
    'style-loader-container'
  ) as HTMLElement;

  useEffect(() => {
    methods.trigger('edgebandColor');
  }, [edgebandColorWatched]);

  useEffect(() => {
    methods.trigger('cabinetBoxMaterial');
  }, [cabinetBoxMaterialWatched]);

  useEffect(() => {
    if (finishColorWatched && finishColorOptions) {
      const finishColorItem = finishColorOptions.find(
        (fc) => fc.value === finishColorWatched.value
      );

      if (!finishColorItem) {
        methods.setValue('finishColor', null, { shouldDirty: true });
        methods.setValue('finishColorUpcharge', '', { shouldDirty: true });
        methods.setValue('specialFinishSample', '');
        if (!methods.formState.dirtyFields.showAvailableFinishesOnly) {
          methods.setValue('showAvailableFinishesOnly', false);
        }
      }
    }
  }, [finishColorOptions]);

  useUndefinedOptionAsFirst();

  return (
    <>
      <Spacer h="49px" />

      <ConfirmationModal
        cancel={() => setIsResetModalVisible(false)}
        confirm={() =>
          methods.handleSubmit((data) =>
            onSubmitHandler({
              data,
              goBack: shouldGoBack,
              shouldResetDoorBuilder: true,
            })
          )()
        }
        confirmBgColor={anzac}
        message="By changing your wood species the selected door style is no longer valid. If you choose to continue the door builder page will reset. Do you wish to continue?"
        opened={isResetModalVisible}
        title="Warning!"
        buttonText="Yes, Reset"
      />

      <H2>Specifications</H2>

      <FormProvider {...methods}>
        <Form>
          <FormColumns flex>
            <LeftColumn>
              <Spacer h="48px" />

              <FormElement>
                <FormLabel>Original W.O #</FormLabel>
                <Input
                  {...methods.register(
                    'workOrderNumber',
                    workOrderNumberValidation()
                  )}
                  type="text"
                  data-test="input-workOrderNumber"
                  readOnly={!canEdit}
                  aria-invalid={
                    methods.formState.errors.workOrderNumber ? 'true' : 'false'
                  }
                />
                <FormError
                  label="Original W.O #"
                  error={methods.formState.errors.workOrderNumber}
                  validationSchema={workOrderNumberValidation()}
                />
              </FormElement>
              <Spacer h="32px" />

              {config.styleSpecifications.showFinishImpression && (
                <>
                  <H3>Select an option</H3>

                  <Spacer h="20px" />

                  <Wrapper flex stretch disabled={!canEdit}>
                    <RadioButton
                      {...methods.register('specificationOption')}
                      flexOne
                      id={`specificationOption--${SpecificationsOptionsEnum.CONFIGURE_WOOD}`}
                      data-test={`specificationOption--${SpecificationsOptionsEnum.CONFIGURE_WOOD}`}
                      title="Configure Wood & Finish"
                      onClick={() =>
                        onChangeWoodTypeHandler(
                          SpecificationsOptionsEnum.CONFIGURE_WOOD
                        )
                      }
                      value={SpecificationsOptionsEnum.CONFIGURE_WOOD}
                      className="boxed"
                      {...testId(
                        OrderStyleSpecificationsTestRadioButtonEnum.CONFIGURE_WOOD_RB
                      )}
                    />

                    <Spacer w="20px" />

                    <RadioButton
                      {...methods.register('specificationOption')}
                      flexOne
                      id={`specificationOption--${SpecificationsOptionsEnum.SELECT_IMPRESSION}`}
                      onClick={() =>
                        onChangeWoodTypeHandler(
                          SpecificationsOptionsEnum.CONFIGURE_WOOD
                        )
                      }
                      data-test={`specificationOption--${SpecificationsOptionsEnum.SELECT_IMPRESSION}`}
                      title="Select An Impression"
                      value={SpecificationsOptionsEnum.SELECT_IMPRESSION}
                      className="boxed"
                      {...testId(
                        OrderStyleSpecificationsTestRadioButtonEnum.SELECT_IMPRESSION_RB
                      )}
                    />
                  </Wrapper>
                  <Spacer h="20px" />
                </>
              )}

              <H3>{config.styleSpecifications.woodTypeLabel}</H3>
              <Spacer h="20px" />

              {!impressionSelected && (
                <>
                  <Wrapper flex alignStart>
                    <FormElement flexGrow>
                      <Spacer h="10px" />
                      <FormLabel>
                        {config.styleSpecifications.woodLabel}
                      </FormLabel>

                      {config.styleSpecifications.showEngineeredCheckbox && (
                        <Wrapper flex disabled={!canEdit}>
                          <Checkbox
                            {...methods.register(
                              'engineeredFurnitureBoardConstruction'
                            )}
                            id="engineeredFurniture"
                            title="Engineered Furniture Board Construction"
                            readOnly={!canEdit}
                          />
                        </Wrapper>
                      )}

                      <WarningTooltip
                        fieldName={config.styleSpecifications.woodLabel}
                        withTooltip={UtilService.shouldShowTooltip(
                          woodOrMaterialOptions,
                          woodOrMaterialWatched
                        )}
                      >
                        <Controller
                          control={methods.control}
                          name="woodOrMaterial"
                          rules={{ required: !impressionSelected }}
                          render={({ field: { onChange, ref, value } }) => (
                            <Select
                              ref={ref}
                              value={value}
                              onChange={onChange}
                              isDisabled={
                                !woodOrMaterialOptions?.length || !canEdit
                              }
                              aria-invalid={
                                methods.formState.errors.woodOrMaterial
                                  ? 'true'
                                  : 'false'
                              }
                              options={woodOrMaterialOptions}
                              {...testId(
                                OrderStyleSpecificationsTestComboBoxEnum.WOOD_CB
                              )}
                            />
                          )}
                        />
                      </WarningTooltip>

                      <FormError
                        label="Wood"
                        error={
                          methods.formState.errors.woodOrMaterial as FieldError
                        }
                        validationSchema={{ required: !impressionSelected }}
                      />
                    </FormElement>
                    <Spacer w="24px" />
                    <Wrapper flex middle>
                      <FormElement maxWidth={82}>
                        <Spacer
                          h={
                            config.styleSpecifications.showEngineeredCheckbox
                              ? '32px'
                              : '9px'
                          }
                        />
                        <FormLabel>Upcharge %</FormLabel>
                        <Input
                          {...methods.register(
                            'woodOrMaterialUpcharge',
                            upchargeValidation()
                          )}
                          onBlur={(e) =>
                            UtilService.withDecimal<StyleSpecifications>(
                              'woodOrMaterialUpcharge',
                              e.target.value,
                              methods.setValue
                            )
                          }
                          type="text"
                          disabled={!woodOrMaterialWatched || !canEdit}
                          placeholder="0.00"
                          data-test="input-woodOrMaterialUpcharge"
                          aria-invalid={
                            methods.formState.errors.woodOrMaterialUpcharge
                              ? 'true'
                              : 'false'
                          }
                        />
                        <FormError
                          label="Wood Or Material Upcharge"
                          error={
                            methods.formState.errors
                              .woodOrMaterialUpcharge as FieldError
                          }
                          validationSchema={upchargeValidation()}
                        />
                      </FormElement>
                    </Wrapper>
                  </Wrapper>

                  {config.styleSpecifications.showSelectWoodSpecies && (
                    <Wrapper flex>
                      <FormElement flexGrow>
                        <FormLabel>Select Wood Species</FormLabel>

                        <Input
                          {...methods.register('selectWoodSpecies', {
                            required:
                              woodOrMaterialWatched?.label ===
                                SpecificationsOptionsEnum.TYPE_SW ||
                              woodOrMaterialWatched?.label ===
                                SpecificationsOptionsEnum.COMP,
                            maxLength: 200,
                          })}
                          type="text"
                          readOnly={
                            (finishColorWatched?.label !==
                              SpecificationsOptionsEnum.TYPE_SW &&
                              woodOrMaterialWatched?.label !==
                                SpecificationsOptionsEnum.TYPE_SW &&
                              woodOrMaterialWatched?.label !==
                                SpecificationsOptionsEnum.COMP) ||
                            !canEdit
                          }
                          data-test="input-selectWoodSpecies"
                          aria-invalid={
                            methods.formState.errors.selectWoodSpecies
                              ? 'true'
                              : 'false'
                          }
                        />

                        <FormError
                          label="Select Wood Species"
                          error={methods.formState.errors.selectWoodSpecies}
                          validationSchema={{
                            required:
                              woodOrMaterialWatched?.label ===
                                SpecificationsOptionsEnum.TYPE_SW ||
                              woodOrMaterialWatched?.label ===
                                SpecificationsOptionsEnum.COMP,
                            maxLength: 200,
                          }}
                        />
                      </FormElement>
                      <Spacer w="106px" />
                    </Wrapper>
                  )}

                  <WoodEffectNew />

                  {config.styleSpecifications.showWoodInterior && (
                    <FormElement flexGrow>
                      <FormLabel>Wood Interior (Optional)</FormLabel>

                      <WarningTooltip
                        fieldName="Wood Interior"
                        withTooltip={UtilService.shouldShowTooltip(
                          woodInteriorOptions,
                          woodInteriorWatched
                        )}
                      >
                        <Controller
                          control={methods.control}
                          name="woodInterior"
                          render={({ field: { onChange, ref, value } }) => (
                            <Select
                              ref={ref}
                              isClearable
                              options={woodInteriorOptions}
                              aria-invalid={
                                methods.formState.errors.woodInterior
                                  ? 'true'
                                  : 'false'
                              }
                              isDisabled={
                                !woodInteriorOptions?.length || !canEdit
                              }
                              value={woodInteriorOptions?.find(
                                (woodInteriorOption) =>
                                  woodInteriorOption.value ===
                                    methods.getValues('woodInterior')?.value ??
                                  value?.value
                              )}
                              onChange={(val: SelectOptionProps) =>
                                onChange(val ?? null)
                              }
                            />
                          )}
                        />
                      </WarningTooltip>

                      <FormError
                        label="Wood Interior"
                        error={
                          methods.formState.errors.woodInterior as FieldError
                        }
                        validationSchema={{
                          required: woodOrMaterialWatched?.label === 'Paint',
                        }}
                      />
                    </FormElement>
                  )}
                </>
              )}

              {impressionSelected && (
                <>
                  <Wrapper flex middle>
                    <FormElement flexGrow>
                      <FormLabel>Wood</FormLabel>
                      <H4>
                        {UtilService.displayNotAvailableIfEmpty(
                          finishImpressionData?.woodMaterial.name
                        )}
                      </H4>
                    </FormElement>
                    <Wrapper flex middle>
                      <FormElement maxWidth={82}>
                        <Spacer
                          h={
                            config.styleSpecifications.showEngineeredCheckbox
                              ? '32px'
                              : '9px'
                          }
                        />
                        <FormLabel>Upcharge %</FormLabel>
                        <Input
                          {...methods.register(
                            'woodOrMaterialUpcharge',
                            upchargeValidation()
                          )}
                          onBlur={(e) =>
                            UtilService.withDecimal<StyleSpecifications>(
                              'woodOrMaterialUpcharge',
                              e.target.value,
                              methods.setValue
                            )
                          }
                          type="text"
                          disabled={!canEdit}
                          placeholder="0.00"
                          data-test="input-woodOrMaterialUpcharge"
                          aria-invalid={
                            methods.formState.errors.woodOrMaterialUpcharge
                              ? 'true'
                              : 'false'
                          }
                        />
                        <FormError
                          label="Wood Or Material Upcharge"
                          error={
                            methods.formState.errors
                              .woodOrMaterialUpcharge as FieldError
                          }
                          validationSchema={upchargeValidation()}
                        />
                      </FormElement>
                    </Wrapper>
                  </Wrapper>

                  {finishImpressionData?.woodEffects?.map((woodEffect) => (
                    <Wrapper flex middle>
                      <FormElement flexGrow key={woodEffect.id}>
                        <FormLabel>Wood Effect</FormLabel>
                        <H4>
                          {UtilService.displayNotAvailableIfEmpty(
                            woodEffect.name
                          )}
                        </H4>
                      </FormElement>
                    </Wrapper>
                  ))}
                  <Spacer h="28px" />
                </>
              )}

              {config.styleSpecifications.showCabinetBoxMaterial && (
                <>
                  <Wrapper flex alignStart>
                    <FormElement flexGrow>
                      <Wrapper flex middle between>
                        <FormLabel>Cabinet Box Material</FormLabel>
                      </Wrapper>

                      <WarningTooltip
                        fieldName="Cabinet Box Material"
                        withTooltip={UtilService.shouldShowTooltip(
                          cabinetBoxOptions,
                          cabinetBoxMaterialWatched
                        )}
                      >
                        <Controller
                          control={methods.control}
                          rules={{
                            required:
                              config.styleSpecifications.showCabinetBoxMaterial,
                          }}
                          name="cabinetBoxMaterial"
                          render={({ field: { onChange, ref, value } }) => (
                            <Select
                              ref={ref}
                              aria-invalid={
                                methods.formState.errors.cabinetBoxMaterial
                                  ? 'true'
                                  : 'false'
                              }
                              isDisabled={
                                !cabinetBoxOptions?.length || !canEdit
                              }
                              value={value}
                              options={cabinetBoxOptions}
                              onChange={(val: SelectOptionProps) =>
                                onChange(val ?? null)
                              }
                            />
                          )}
                        />
                      </WarningTooltip>

                      <FormError
                        label="Cabinet Box Material"
                        error={
                          methods.formState.errors
                            .cabinetBoxMaterial as FieldError
                        }
                        validationSchema={{
                          required:
                            config.styleSpecifications.showCabinetBoxMaterial,
                        }}
                      />
                    </FormElement>

                    <Spacer w="24px" />

                    <Wrapper flex middle>
                      <FormElement maxWidth={82}>
                        <FormLabel>Upcharge %</FormLabel>
                        <Input
                          {...methods.register(
                            'cabinetBoxMaterialUpcharge',
                            upchargeValidation()
                          )}
                          onBlur={(e) =>
                            UtilService.withDecimal<StyleSpecifications>(
                              'cabinetBoxMaterialUpcharge',
                              e.target.value,
                              methods.setValue
                            )
                          }
                          type="text"
                          disabled={!cabinetBoxMaterialWatched || !canEdit}
                          placeholder="0.00"
                          data-test="input-cabinetBoxMaterialUpcharge"
                          aria-invalid={
                            methods.formState.errors.cabinetBoxMaterialUpcharge
                              ? 'true'
                              : 'false'
                          }
                          {...testId(
                            OrderStyleSpecificationsTestInputEnum.CABINET_BOX_MATERIAL_UPCHARGE_INPUT
                          )}
                        />
                        <FormError
                          label="Cabinet Box Material Upcharge"
                          error={
                            methods.formState.errors
                              .cabinetBoxMaterialUpcharge as FieldError
                          }
                          validationSchema={upchargeValidation()}
                        />
                      </FormElement>
                    </Wrapper>
                  </Wrapper>
                </>
              )}

              <Spacer h="8px" />

              {cabinetBoxMaterialWatched?.label ===
                CabinetBoxMaterialOptionsEnum.SPECIAL && (
                <Wrapper flex>
                  <FormElement flexGrow>
                    <FormLabel>Special Cabinet Box Material</FormLabel>

                    <Input
                      type="text"
                      aria-invalid={
                        methods.formState.errors.specialCabinetBoxMaterial
                          ? 'true'
                          : 'false'
                      }
                      {...methods.register('specialCabinetBoxMaterial', {
                        maxLength: 200,
                      })}
                      readOnly={!canEdit}
                    />

                    <FormError
                      label="Special Cabinet Box Material"
                      error={methods.formState.errors.specialCabinetBoxMaterial}
                      validationSchema={{
                        maxLength: 200,
                      }}
                    />
                  </FormElement>
                </Wrapper>
              )}

              {isUserCSR && config.styleSpecifications.showNaturalInterior && (
                <FormElement flexGrow>
                  <Checkbox
                    {...methods.register('naturalInterior')}
                    title="Natural Interior"
                    id="naturalInterior"
                    readOnly={!canEdit}
                  />
                </FormElement>
              )}

              {isUserCSR && config.styleSpecifications.showWoodTape && (
                <>
                  <FormElement flexGrow>
                    <Checkbox
                      {...methods.register('woodTape')}
                      title="Wood Tape"
                      id="woodTape"
                      readOnly={!canEdit}
                    />
                  </FormElement>
                  <Spacer h="8px" />
                </>
              )}

              {isUserCSR && config.styleSpecifications.showEdgebandColor && (
                <FormElement>
                  <FormLabel>Edge Band Color</FormLabel>

                  <Input
                    aria-invalid={
                      methods.formState.errors.edgebandColor ? 'true' : 'false'
                    }
                    {...methods.register('edgebandColor', {
                      maxLength: 200,
                      required: true,
                    })}
                    readOnly={!canEdit}
                  />

                  <FormError
                    label="Edge Band Color"
                    error={methods.formState.errors.edgebandColor}
                    validationSchema={{ maxLength: 200 }}
                  />
                </FormElement>
              )}

              <Spacer h="12px" />

              <FormElement>
                <FormLabel>Wood Notes</FormLabel>
                <Textarea
                  placeholder="Wood notes"
                  aria-invalid={
                    methods.formState.errors.woodNotes ? 'true' : 'false'
                  }
                  {...methods.register('woodNotes', noteValidation())}
                  readOnly={!canEdit}
                  {...testId(
                    OrderStyleSpecificationsTestTextAreaEnum.WOOD_NOTES
                  )}
                />

                <FormError
                  label="Wood Notes"
                  error={methods.formState.errors.woodNotes}
                  validationSchema={noteValidation()}
                />
              </FormElement>
            </LeftColumn>

            <Spacer w="120px" />

            <RightColumn>
              <Spacer h="25px" />

              <OrderStyleNote />

              <H4>Finish Color / Effects</H4>

              {impressionSelected && <Spacer h="32px" />}

              {!impressionSelected && (
                <Wrapper ref={finishColorRef}>
                  <Spacer h="32px" />

                  <Wrapper flex alignStart>
                    <FormElement flexGrow>
                      <OrderFinishLabel
                        style={{
                          productLine: selectedProductLine ?? undefined,
                          ...(woodOrMaterialWatched && {
                            woodMaterial: {
                              id: woodOrMaterialWatched.value,
                              name: woodOrMaterialWatched.label,
                            },
                          }),
                        }}
                        render={(label) => <FormLabel>{label}</FormLabel>}
                      />

                      <OrderFinishValue
                        defaultValue="Not selected"
                        style={{
                          productLine: selectedProductLine ?? undefined,
                          ...(finishColorWatched && {
                            finishColor: {
                              id: finishColorWatched.value,
                              name: finishColorWatched.label,
                            },
                          }),
                        }}
                        render={(value) => (
                          <StaticValue
                            flex
                            middle
                            className={
                              methods.formState.errors.finishColor
                                ? 'invalid'
                                : ''
                            }
                          >
                            {value}
                          </StaticValue>
                        )}
                      />

                      <OrderFinishLabel
                        style={{
                          productLine: selectedProductLine ?? undefined,
                          ...(woodOrMaterialWatched && {
                            woodMaterial: {
                              id: woodOrMaterialWatched.value,
                              name: woodOrMaterialWatched.label,
                            },
                          }),
                        }}
                        render={(label) => (
                          <FormError
                            label={label}
                            error={
                              (methods.formState.errors
                                .finishColor as FieldError) ?? null
                            }
                            validationSchema={{ required: true }}
                          />
                        )}
                      />
                    </FormElement>

                    <Spacer w="24px" />

                    <Wrapper flex middle>
                      <FormElement maxWidth={112}>
                        <FormLabel>Upcharge %</FormLabel>
                        <Input
                          {...methods.register(
                            'finishColorUpcharge',
                            upchargeValidation()
                          )}
                          type="text"
                          placeholder="0.00"
                          data-test="input-finishColorUpcharge"
                          disabled={!finishColorWatched || !canEdit}
                          onBlur={(e) =>
                            UtilService.withDecimal<StyleSpecifications>(
                              'finishColorUpcharge',
                              e.target.value,
                              methods.setValue
                            )
                          }
                          aria-invalid={
                            methods.formState.errors.finishColorUpcharge
                              ? 'true'
                              : 'false'
                          }
                        />
                        <FormError
                          label="Finish Color Upcharge"
                          error={
                            methods.formState.errors
                              .finishColorUpcharge as FieldError
                          }
                          validationSchema={upchargeValidation()}
                        />
                      </FormElement>
                    </Wrapper>
                  </Wrapper>

                  {config.styleSpecifications.showSpecialFinishSample && (
                    <FormElement maxWidth={405}>
                      <FormLabel>Special Finish Sample #</FormLabel>
                      <Input
                        {...methods.register(
                          'specialFinishSample',
                          specialFinishSampleValidation()
                        )}
                        type="text"
                        data-test="input-specialFinishSample"
                        readOnly={!canEdit || !woodOrMaterialWatched}
                        aria-invalid={
                          methods.formState.errors.specialFinishSample
                            ? 'true'
                            : 'false'
                        }
                      />

                      <FormError
                        label="Special Finish Sample #"
                        error={methods.formState.errors.specialFinishSample}
                        validationSchema={specialFinishSampleValidation()}
                      />
                    </FormElement>
                  )}

                  {woodOrMaterialWatched &&
                    config.styleSpecifications
                      .showAvailableFinishesCheckbox && (
                      <ShowAvailableFinishes />
                    )}

                  <FinishColor isFinishColorsLoading={finishColorsLoading} />

                  <Spacer h="30px" />

                  {isUserCSR && config.styleSpecifications.showColorSample && (
                    <>
                      <FormElement>
                        <Checkbox
                          {...methods.register('colorSample')}
                          title="Color Sample Door"
                          id="colorSample"
                          readOnly={!canEdit}
                        />
                      </FormElement>
                      <Spacer h="12px" />
                    </>
                  )}
                </Wrapper>
              )}

              <VendorColor />

              {!impressionSelected && (
                <>
                  <FinishEffectNew />
                  <VarnishSheen />
                </>
              )}

              {impressionSelected && (
                <>
                  <ImpressionFinish />

                  <Spacer h="30px" />

                  {isUserCSR && config.styleSpecifications.showColorSample && (
                    <>
                      <FormElement>
                        <Checkbox
                          {...methods.register('colorSample')}
                          title="Color Sample Door"
                          id="colorSample"
                          readOnly={!canEdit}
                        />
                      </FormElement>
                      <Spacer h="12px" />
                    </>
                  )}
                </>
              )}

              <Spacer h="30px" />

              <FinishNotes />
            </RightColumn>
          </FormColumns>

          <Spacer h="150px" />

          <OrderFooter>
            <Wrapper flex middle between>
              <ButtonSecondary
                onClick={() =>
                  (canSubmit ? handleValidateOrSubmit : redirect)(true)
                }
                type="button"
                disabled={specSavingOnBack || validationLoading}
              >
                {UtilService.styleNavigationActionsLables(
                  'Back',
                  canEdit && methods.formState.isDirty
                )}
                <Loader
                  hidden={!specSavingOnBack && !validationLoading}
                  insideButton
                  noSpacing
                  size={16}
                />
              </ButtonSecondary>

              {(showAvailableFinishesOnlyWatched ||
                specialFinishSampleWatched?.length > 0 ||
                finishEffectsWatched?.some((fe) => fe.showAvailableOnly)) && (
                <FooterAvailableWarning />
              )}

              <ButtonPrimary
                data-test="button-StylesSpecsSubmit"
                type="button"
                onClick={() =>
                  (canSubmit || goNextAndSaveForFirstTime()
                    ? handleValidateOrSubmit
                    : redirect)(false)
                }
                disabled={specSaving || validationLoading}
              >
                {UtilService.styleNavigationActionsLables(
                  'Next',
                  canEdit && methods.formState.isDirty
                )}
                <Loader
                  hidden={!specSaving && !validationLoading}
                  insideButton
                  noSpacing
                  size={16}
                />
              </ButtonPrimary>
            </Wrapper>
          </OrderFooter>

          {loading &&
            styleLoaderContainer &&
            createPortal(
              <LoaderFullScreen flex middle center bottom={71} top={132}>
                <Loader noSpacing size={40} />
              </LoaderFullScreen>,
              styleLoaderContainer
            )}
        </Form>
      </FormProvider>

      <ModalWrapper
        open={breakingChanges !== null}
        closeOnDocumentClick={false}
        closeOnEscape={false}
        nested
        onClose={UtilService.onPopupClose}
      >
        {(close: () => void) => (
          <DoorBuilderBreakingChanges
            breakingChanges={breakingChanges}
            loading={specSaving || specSavingOnBack}
            dismiss={() =>
              onDoorBuilderBreakingChangesModalDismissHandler(close)
            }
            confirm={methods.handleSubmit((data) =>
              onSubmitHandler({ data, goBack: shouldGoBack })
            )}
          />
        )}
      </ModalWrapper>

      {PopupModal}
    </>
  );
};
export default OrderStylesSpecifications;
