/* eslint-disable */

import axios, { AxiosRequestConfig } from 'axios';
import qs from 'qs';
import { toast } from 'react-toastify';
import { ErrorCodesEnum } from 'shared/interface/serverResponses/ErrorCodesEnum';
import { ServerErrorResponse } from 'shared/interface/serverResponses/ServerErrorResponse';

import { Token } from 'shared/interface/Token';
import UtilService from 'shared/services/util.service';

import env from '../../env_config';

const newAxios = axios.create({
  baseURL: env.apiGatewayUrl,
});

const refreshAxios = axios.create({
  baseURL: env.keycloakGatewayUrl,
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded',
  },
});

const refreshAccessToken = async () => {
  const token: Token = JSON.parse(UtilService.getLocalStorageItem('token')!);

  const data = {
    client_id: env.keycloakClientId!,
    grant_type: 'refresh_token',
    refresh_token: token.refresh_token,
  };

  const newToken = await refreshAxios
    .post(
      `/auth/realms/${env.keycloakRealm}/protocol/openid-connect/token`,
      qs.stringify(data)
    )
    .then((response) => response.data)
    .catch((err) => {
      UtilService.removeLocalStorageItem('token');
      window.location.href = '/';
    });

  return newToken;
};

newAxios.interceptors.request.use((req) => {
  if (UtilService.getLocalStorageItem('token')) {
    const token: Token = JSON.parse(UtilService.getLocalStorageItem('token')!);
    req.headers.authorization = `Bearer ${token.access_token}`;
  }
  return req;
});

newAxios.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    if (UtilService.getLocalStorageItem('token')) {
      const originalRequest = error.config;

      // 401 unauthorsized / 403 forbiden
      if (error.response.status === 401 && !originalRequest._retry) {
        originalRequest._retry = true;
        const token: Token = await refreshAccessToken();

        UtilService.setLocalStorageItem('token', JSON.stringify(token));
        originalRequest.headers.authorization = `Bearer ${token.access_token}`;

        return newAxios(originalRequest);
      }
    }

    return Promise.reject(error);
  }
);

class ApiService {
  static async get<T>(url: string, config?: AxiosRequestConfig): Promise<T> {
    return new Promise((resolve, reject) => {
      newAxios
        .get(url, config)
        .then((response) => {
          return resolve(response.data);
        })
        .catch((err) =>
          reject(
            (err.response.data as ServerErrorResponse) ||
              ({
                detail: err.response.data,
                status: err.response.status,
                errors: [],
                title: 'Something went wrong.',
                type: ErrorCodesEnum.Default,
              } as ServerErrorResponse)
          )
        );
    });
  }

  static async post<T>(
    url: string,
    data: T,
    config?: AxiosRequestConfig
  ): Promise<T> {
    return new Promise((resolve, reject) => {
      return newAxios
        .post(url, data, config)
        .then((response) => resolve(response.data))
        .catch((err) =>
          reject(
            (err.response.data as ServerErrorResponse) ||
              ({
                detail: err.response.data,
                status: err.response.status,
                errors: [],
                title: 'Something went wrong.',
                type: ErrorCodesEnum.Default,
              } as ServerErrorResponse)
          )
        );
    });
  }

  static async put<T>(
    url: string,
    data?: T,
    config?: AxiosRequestConfig
  ): Promise<T> {
    return new Promise((resolve, reject) => {
      newAxios
        .put(url, data, config)
        .then((response) => resolve(response.data))
        .catch((err) =>
          reject(
            (err.response.data as ServerErrorResponse) ||
              ({
                detail: err.response.data,
                status: err.response.status,
                errors: [],
                title: 'Something went wrong.',
                type: ErrorCodesEnum.Default,
              } as ServerErrorResponse)
          )
        );
    });
  }

  static async delete<T>(url: string, config?: AxiosRequestConfig): Promise<T> {
    return new Promise((resolve, reject) => {
      return newAxios
        .delete(url, config)
        .then((response) => resolve(response.data))
        .catch((err) =>
          reject(
            (err.response.data as ServerErrorResponse) ||
              ({
                detail: err.response.data,
                status: err.response.status,
                errors: [],
                title: 'Something went wrong.',
                type: ErrorCodesEnum.Default,
              } as ServerErrorResponse)
          )
        );
    });
  }
}

export default ApiService;
