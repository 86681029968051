import { getShipViaOptions } from 'order/store/orderActions';
import { useEffect, useState } from 'react';
import { SelectOptionProps } from 'shared/interface/SelectOptionProps';
import { useAppDispatch } from './useAppDispatch';

export const useGetShipViaOption = (shipOptionId: string | undefined) => {
  const dispatch = useAppDispatch();

  const [shipViaOptions, setShipViaOptions] =
    useState<SelectOptionProps[] | null>(null);

  const getShipViaOptionsSuccessHandler = (options: SelectOptionProps[]) => {
    setShipViaOptions(options);
  };

  useEffect(() => {
    if (shipOptionId) {
      dispatch(getShipViaOptions(getShipViaOptionsSuccessHandler));
    }
  }, [shipOptionId]);

  return shipViaOptions?.find((option) => option.value === shipOptionId);
};
