import { FormProvider, useForm } from 'react-hook-form';
import { RootState } from 'store';
import { toast } from 'react-toastify';
import { FC, useEffect, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import {
  getDealership,
  setDealership,
} from 'overview/dealership/store/dealershipActions';

import { Collaborator } from 'order/interfaces/Collaborator';
import { collaboratorAccessEnums } from 'order/enums/collaboratorAccessEnums';
import { Order } from 'order/interfaces/Order';
import { OrderFooter } from 'order/components/OrderFooter/OrderFooter';
import { OrderPageParams } from 'order/interfaces/OrderPageParams';
import { OrderScheduling } from 'order/components/OrderScheduling/OrderScheduling';
import { ScheduleType } from 'order/enums/scheduleType';
import DealershipPreferences from 'order/components/DealershipPreferences/DealershipPreferences';
import OrderCollaborators from 'order/components/OrderCollaborators/OrderCollaborators';
import OrderDealershipSelection from 'order/components/OrderDealershipSelection/OrderDealershipSelection';
import OrderDealershipInfo from 'order/components/OrderDealershipInfo/OrderDealershipInfo';
import OrderJob from 'order/components/OrderJob/OrderJob';
import OrderSelectedCollaborators from 'order/components/OrderSelectedCollaborators/OrderSelectedCollaborators';
import OrderTransportation from 'order/components/OrderTransportation/OrderTransportation';
import ShipVia from 'order/components/ShipVia/ShipVia';

import {
  OrderDescriptionTypeEnums,
  OrderStatusEnums,
  OrderStylizationTypeEnums,
  OrderTransportationTypeEnums,
  OrderTypeEnums,
} from 'order/enums/orderEnums';

import {
  createNonExistingShippingAddress,
  createOrder,
  createOrderJob,
  getOrder,
  lockOrderForEdit,
  saveOrderCollaborators,
  saveOrderExistingShippingAddress,
  saveShipViaOption,
  selectCollaborator,
  setCanEditOrder,
  setCurrentUserCollaboratorAccess,
  setNonExistingShippingAddress,
  unlockOrderForEdit,
  updateOrder,
} from 'order/store/orderActions';

import FormError from 'shared/components/FormError';
import Loader, { LoaderFullScreen } from 'shared/components/Loader';
import UserInfo from 'shared/components/UserInfo';
import UtilService from 'shared/services/util.service';
import { ButtonPrimary } from 'shared/components/Button';
import { ConfirmationModal } from 'shared/components/ConfirmationModal';
import { Container } from 'shared/components/Container';
import { CustomAddressRequestObject } from 'shared/interface/CustomAddressRequestObject';
import { Form } from 'shared/components/Form';
import { FormElement } from 'shared/components/FormElement';
import { FormElementHint } from 'shared/components/FormElementHint';
import { FormLabel } from 'shared/components/FormLabel';
import { Grades } from 'shared/components/Grades';
import { H2, P } from 'shared/components/Typography';
import { Input } from 'shared/components/Input';
import { OrderFlowsEnum, useIsOrderFlow } from 'shared/hooks/useIsOrderFlow';
import { RadioButton } from 'shared/components/RadioButton';
import { SelectOptionProps } from 'shared/interface/SelectOptionProps';
import { ServerErrorResponse } from 'shared/interface/serverResponses/ServerErrorResponse';
import { Spacer, WhiteBox } from 'shared/components/Layout';
import { Textarea } from 'shared/components/Textarea';
import { User } from 'shared/interface/User';
import { Wrapper } from 'shared/components/Wrapper';
import { zeroGuid } from 'shared/config/Variables';
import { useAppDispatch } from 'shared/hooks/useAppDispatch';
import { useGetAvailableDealershipId } from 'shared/hooks/useGetAvailableDealershipId';
import { useHasPermissions } from 'shared/hooks/useHasPermissions';
import { useIsOrderInStatus } from 'shared/hooks/useIsOrderInStatus';
import { useOrderFlow } from 'shared/hooks/useOrderFlow';
import { useQueryParams } from 'shared/hooks/useQueryParams';
import { userPermissionsValues } from 'shared/enum/userPermissionsEnum';

import {
  countertopThicknessValidation,
  dateRequestedValidation,
  descriptionTypeValidation,
  orderJobValidation,
  orderNameValidation,
  postOfficeNumberValidation,
  transportationTypeValidation,
  workOrderNumberValidation,
  ceilingHeightValidation,
} from 'shared/validations/validations';

import { testId } from 'tests/utils';
import { OrderTestInputEnum } from 'tests/enums/OrderTestEnums';

import useWindowScrollPosition from 'shared/hooks/useWindowScrollPosition';
import OrderStylizationTypeField from './components/OrderStylizationTypeField';
import OrderTypeField from './components/OrderTypeField';
import { OrderAsapParams } from '../orderASAP/interface/OrderAsapParams';
import { OrderStylesAdjustments } from '../orderStyles/interface/Adjustments';
import { Provider } from './OrderFormContext/orderFormContext';
import { setAsapStep } from '../orderASAP/store/OrderAsapActions';
import { useOrderProductType } from '../../../shared/hooks/useOrderProductType';

export interface OrderFormFields {
  ceilingHeight: string;
  countertopThickness: string;
  dateRequested: string;
  descriptionType: OrderDescriptionTypeEnums;
  depositPaid: string;
  depositPaidDate: string | null;
  jobId?: string;
  name: string;
  note: string;
  postOfficeNumber: string;
  transportationType: OrderTransportationTypeEnums;
  type: string;
  workOrderNumber: string;
  stylizationType: string;
  shipOptionId: string;
  jobSiteDelivery: boolean;

  // ship week and target dates
  startDate?: string;
  shipWeek?: string;
  writingDate?: string;
  secondCheckDate?: string;
  draftingDate?: string;
  acknowledgementDate?: string;
  redTag?: boolean;
  adjustment: OrderStylesAdjustments | null;
}

const SectionHeading = styled(H2)`
  &:first-child {
    margin-bottom: 30px;
  }

  &:not(:first-child) {
    margin: 30px 0;
  }
`;

const WhiteBoxSection = styled(WhiteBox)`
  margin-bottom: 50px;
  padding: 48px 96px;
`;

const GradesContainer = styled.div`
  left: 0;
  pointer-events: none;
  position: fixed;
  right: 0;
  top: 199px;
`;

const GradesInner = styled(Wrapper)`
  max-width: 745px;
  margin: 0 auto;
`;

// const FormColumns = styled(Wrapper)``;

// const Column = styled.div`
//   max-width: 270px;
//   flex: 1;
// `;

interface OrderFormProps {
  isOrderFormSubmitting?: (isSubmitting: boolean) => void;
}

const OrderForm: FC<OrderFormProps> = ({ isOrderFormSubmitting }) => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const location = useLocation();

  const isQuoteFlow = useIsOrderFlow(OrderFlowsEnum.QUOTE);

  const canViewDealershipPreferences = useHasPermissions(
    userPermissionsValues.DEALER_VIEW_PREFERENCES
  );

  const canUpdateOrderGrade = useHasPermissions(
    userPermissionsValues.ORDER_UPDATE_GRADING_OPTIONS
  );

  const canGetShipViaOptions = useHasPermissions(
    userPermissionsValues.ORDER_GET_SHIP_VIA_OPTIONS
  );

  const canUpdateShipViaOptions = useHasPermissions(
    userPermissionsValues.ORDER_UPDATE_SHIP_VIA_OPTIONS
  );

  const canViewScheduling = useHasPermissions(
    userPermissionsValues.ORDER_VIEW_SCHEDULING
  );

  // edit mode
  const { orderId } = useParams<OrderPageParams>();
  const editMode = !!orderId;

  // asap or duplicate
  const [isAsap] = useOrderFlow('asap');
  const [isDuplicate] = useOrderFlow('duplicate');

  const asapOrDuplicate = isAsap || isDuplicate;

  const [queryFields] = useQueryParams<OrderAsapParams>([
    'orderId',
    'newOrderId',
  ]);

  const [orderLoading, setOrderLoading] = useState(false);
  const [isEditWarningModalOpen, setIsEditWarningModalOpen] = useState(false);

  const [
    nonExistingShippingAddresCreated,
    setNonExistingShippingAddresCreated,
  ] = useState(false);

  const [existingShippingAddresSaved, setExistingShippingAddresSaved] =
    useState(false);

  const [collaboratorsSaved, setCollaboratorsSaved] = useState(false);

  const [shipViaSaved, setShipViaSaved] = useState(false);

  const [clearSearchedCollaborators, setClearSearchedCollaborators] =
    useState(false);

  const [createdOrUpdatedOrderId, setCreatedOrUpdatedOrderId] =
    useState<string | null>(null);

  const [orderFormSubmitting, setOrderFormSubmitting] = useState(false);

  const [selectedAddressId, setSelectedAddressId] =
    useState<string | null>(null);

  const [csrSelectedDealership, setCsrSelectedDealership] =
    useState<SelectOptionProps | null>(null);

  const [isCSRSelectedDealershipDirty, setIsCSRSelectedDealershipDirty] =
    useState(false);

  const [csrSelectedDealershipUser, setCsrSelectedDealershipUser] =
    useState<SelectOptionProps | null>(null);

  const [
    isCSRSelectedDealershipUserDirty,
    setIsCSRSelectedDealershipUserDirty,
  ] = useState(false);

  const [isFormDirty, setIsFormDirty] = useState(false);

  const [removedMyselfAsCollaborator, setRemovedMyselfAsCollaborator] =
    useState(false);

  const [addMeAsCollaboratorModalOpened, setAddMeAsCollaboratorModalOpened] =
    useState(false);

  const loggedInUser = useSelector(
    (state: RootState) => state.authReducer.user
  );

  const selectedNonExistingAddress = useSelector(
    (state: RootState) => state.orderReducer.nonExistingAddress
  );

  const selectedShippingAddress = useSelector(
    (state: RootState) => state.orderReducer.selectedShippingAddress
  );

  const [selectedJob, setSelectedJob] =
    useState<SelectOptionProps | null>(null);

  const selectedCollaborators = useSelector(
    (state: RootState) => state.orderReducer.selectedCollaborators
  );

  const { order, canEdit } = useSelector(
    (state: RootState) => state.orderReducer
  );

  const isOrderSubmitted = useIsOrderInStatus({
    greaterThen: true,
    status: OrderStatusEnums['Not Submitted'],
  });

  const isUserCSR = useHasPermissions(
    userPermissionsValues.CSR_VIEW_ORDERBOARD
  );

  const isUserSalesRep = useHasPermissions(
    userPermissionsValues.SALES_REPRESENTATIVE_VIEW_ORDER_BOARD
  );

  const createOrderOnBehalfOfDealer = useHasPermissions(
    userPermissionsValues.ORDER_CREATE_DRAFT_ON_BEHALF_OF_DEALER
  );

  const orderLockInfo = useSelector(
    (state: RootState) => state.orderReducer.lockInfo
  );

  const moreThenOneAvailableDealerships = useSelector(
    (state: RootState) =>
      state.dealershipReducer.availableDealerships.length > 1
  );

  const noAvailableDealerships = useSelector(
    (state: RootState) =>
      state.dealershipReducer.availableDealerships.length === 0
  );

  // available dealership
  // loaded from available dealership when we are loading users
  // loaded from order
  // loaded from job
  const availableDealershipId = useGetAvailableDealershipId();

  const [dealershipId, setDealershipId] = useState<string | null | undefined>();

  const defaultValues: OrderFormFields = {
    ceilingHeight: '',
    countertopThickness: '',
    dateRequested: '',
    descriptionType: OrderDescriptionTypeEnums.REMODELED,
    depositPaid: '',
    depositPaidDate: '',
    name: '',
    note: '',
    postOfficeNumber: '',
    shipOptionId: '',
    transportationType: isQuoteFlow
      ? OrderTransportationTypeEnums.NONE
      : OrderTransportationTypeEnums.PLATO_CONTRACTED_TRANSPORTATION,
    type: isQuoteFlow
      ? OrderTypeEnums.Quote.toString()
      : OrderTypeEnums.Standard.toString(),
    workOrderNumber: '',
    stylizationType: OrderStylizationTypeEnums.NONE.toString(),
    jobSiteDelivery: false,
    redTag: false,
    shipWeek: '',
    writingDate: '',
    secondCheckDate: '',
    draftingDate: '',
    acknowledgementDate: '',
    adjustment: null,
    startDate: '',
  };

  const methods = useForm<OrderFormFields>({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    defaultValues,
  });

  const transportationTypeWatched = methods.watch('transportationType');
  const shipViaWatched = methods.watch('shipOptionId');

  const typeWatched = methods.watch('type');
  const stylizationTypeWatched = methods.watch('stylizationType');

  const [isOrderSalesAids, setIsOrderSalesAids] = useState(false);
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);

  const [isDealershipLoading, setIsDealershipLoading] = useState(false);

  const isOrderSalesMaterial =
    useOrderProductType(OrderStylizationTypeEnums.SALES_MATERIAL) ||
    stylizationTypeWatched ===
      OrderStylizationTypeEnums.SALES_MATERIAL.toString();

  const setJobId = (jobId: string) => {
    methods.setValue('jobId', jobId);

    if (jobId) {
      methods.trigger('jobId');
    }
  };

  const orderRequestBodyMapper = (data: OrderFormFields) => {
    return {
      // PLAT-345: depositPaidDate and depositPaid are currently set to null
      //  ...(data.depositPaidDate && { depositPaidDate: data.depositPaidDate }),
      //   depositPaid:  +data.depositPaid,
      ...(isAsap && { asapRequested: true }),
      ...(data.startDate !== '' && { startDate: data.startDate }),
      transportationType: +data.transportationType,

      // TODO: PLAT-3908 when creating a quote send 0 for ch and ctt as default values until BE implement validation for quote
      //       This condition should be removed
      ceilingHeight:
        isQuoteFlow && data.ceilingHeight === '' ? '0' : data.ceilingHeight,
      countertopThickness:
        isQuoteFlow && data.countertopThickness === ''
          ? '0'
          : data.countertopThickness,
      depositPaid: 0,
      depositPaidDate: null,
      adjustment: data.adjustment,
      dateRequested: data.dateRequested,
      descriptionType: +data.descriptionType,
      jobId: data.jobId!,
      jobSiteDelivery: data.jobSiteDelivery,
      name: data.name,
      note: data.note,
      ownerId: csrSelectedDealershipUser?.value,
      postOfficeNumber: data.postOfficeNumber,
      stylizationType: +data.stylizationType,
      type: +data.type,
      workOrderNumber: isOrderSalesMaterial ? data.workOrderNumber : '',
    } as Order;
  };

  const populateShipWeek = () => {
    if (!order) return '';

    switch (order.scheduleType) {
      case ScheduleType.None:
        return '';
      case ScheduleType.Regular:
        return order.shipWeekDescription;
      case ScheduleType.Asap:
        return 'ASAP';
      case ScheduleType['Out Of Schedule']:
        return '000';
      default:
        return '';
    }
  };

  const populateShcheduleInput = (date?: string) => {
    return date && (order?.scheduleType ?? 0) === ScheduleType.Regular
      ? (UtilService.formatDate({
          date,
          asElement: false,
          withTime: false,
        }) as string)
      : '';
  };

  const getDateRequested = () => {
    if (isAsap) return 'ASAP';
    if (isDuplicate) return '';
    return order!.dateRequested;
  };

  const getDescriptionType = () => {
    if (!isDuplicate)
      return order!.descriptionType.toString() as OrderDescriptionTypeEnums;
    return OrderDescriptionTypeEnums.REMODELED;
  };

  const getWorkOrderNumber = () => {
    if (isAsap) return order!.serialNumber?.toString() ?? '';
    if (isDuplicate) return '';
    return order!.workOrderNumber.toString();
  };

  const orderFormInputsMapper = (): OrderFormFields | null => {
    if (!order) {
      return null;
    }

    return {
      acknowledgementDate: populateShcheduleInput(order.acknowledgementDate),
      adjustment: order.adjustment ?? null,
      ceilingHeight: !isDuplicate ? order.ceilingHeight.toString() : '',
      countertopThickness: !isDuplicate ? order.countertopThickness : '',
      dateRequested: getDateRequested(),
      depositPaid: '0',
      depositPaidDate: null,
      descriptionType: getDescriptionType(),
      draftingDate: populateShcheduleInput(order.draftingDate),
      jobId: order.job?.id ?? '',
      jobSiteDelivery: order.jobSiteDelivery,
      name: !isDuplicate ? order.name : '',
      note: !isDuplicate ? order.note : '',
      postOfficeNumber: asapOrDuplicate ? '' : order.postOfficeNumber,
      redTag: order.redTag ?? false,
      secondCheckDate: populateShcheduleInput(order.secondCheckDate),
      shipOptionId: order.shipOptionId ?? '',
      shipWeek: populateShipWeek(),
      startDate: order.startDate ?? '',
      type: order.type.toString(),
      workOrderNumber: isOrderSalesMaterial ? getWorkOrderNumber() : '',
      writingDate: populateShcheduleInput(order.writingDate),
      transportationType: !isAsap
        ? (order.transportationType.toString() as OrderTransportationTypeEnums)
        : OrderTransportationTypeEnums.PLATO_CONTRACTED_TRANSPORTATION,
      stylizationType:
        order.stylizationType?.toString() ??
        OrderStylizationTypeEnums.NONE.toString(),
    };
  };

  const setOrderDefaultValues = () => {
    const formDefaults: OrderFormFields | null = orderFormInputsMapper();

    if (formDefaults) {
      methods.reset(formDefaults);
    }
  };

  const createNonExistingShippingAddressSuccessHandler = () => {
    setNonExistingShippingAddresCreated(true);
  };

  const createNonExistingShippingAddressErrorHandler = (
    err: ServerErrorResponse
  ) => {
    if (err.status === 403) {
      toast.error("You don't have permission to save the shipping address.");
    } else {
      toast.error('Shipping address could not be saved.');
    }
  };

  const onCreateOrUpdateOrderErrorHandler = (err: ServerErrorResponse) => {
    setOrderFormSubmitting(false);

    if (err.status === 403) {
      toast.error("You don't have permission to save or update the order.");
    } else {
      toast.error('Order could not be saved or updated.');
    }
  };

  const saveExistingShippingAddressSuccessHandler = () => {
    setExistingShippingAddresSaved(true);
  };

  const saveExistingShippingAddressErrorHandler = (
    err: ServerErrorResponse
  ) => {
    if (err.status === 403) {
      toast.error("You don't have permission to save the shipping address.");
    } else {
      toast.error('Shipping address could not be saved.');
    }
  };

  const saveOrderCollaboratorsSuccess = () => {
    setCollaboratorsSaved(true);
  };

  const saveShipViaOptionSuccess = () => {
    setShipViaSaved(true);
  };

  const lockOrderForEditSuccessHandler = () => {
    // set current viewer as not viewer but editor.
    dispatch(setCanEditOrder(true));
  };

  const onCreateOrUpdateOrderSuccessHandler = (newOrUpdatedOrderId: string) => {
    setCreatedOrUpdatedOrderId(newOrUpdatedOrderId);

    setRemovedMyselfAsCollaborator(false);

    setOrderLoading(true);

    // save ship via option only when creating a draft and user has permission to update ship via option
    if (
      !isQuoteFlow &&
      canUpdateShipViaOptions &&
      shipViaWatched &&
      transportationTypeWatched !== OrderTransportationTypeEnums.WILL_CALL &&
      transportationTypeWatched !== OrderTransportationTypeEnums.NONE
    ) {
      dispatch(
        saveShipViaOption(
          {
            orderId: newOrUpdatedOrderId,
            shipOptionId: shipViaWatched,
          },
          saveShipViaOptionSuccess
        )
      );
    }

    // save collaborators
    if (selectedCollaborators) {
      dispatch(
        saveOrderCollaborators(
          {
            orderId: newOrUpdatedOrderId,
            editMode,
            collaborators: UtilService.orderCollaboratorsMapper(
              selectedCollaborators
            ),
          },
          saveOrderCollaboratorsSuccess
        )
      );
    }

    // Do not save address in 2 cases

    // 1. when user selects 'will call' in transportation bubble (will call option will be preserved in DB)
    // 2. when user is creating a quote ('will call' option will not be preserved in DB because whole transportation bubble is hidden)

    if (
      transportationTypeWatched === OrderTransportationTypeEnums.WILL_CALL ||
      transportationTypeWatched === OrderTransportationTypeEnums.NONE ||
      isQuoteFlow
    )
      return;

    // save existing address
    if (selectedAddressId) {
      dispatch(
        saveOrderExistingShippingAddress(
          {
            orderId: newOrUpdatedOrderId,
            addressId: selectedAddressId,
            editMode,
            dealershipId: dealershipId ?? '',
            isAsapOrDuplicate: (isAsap ?? false) || (isDuplicate ?? false),
          },
          saveExistingShippingAddressSuccessHandler,
          saveExistingShippingAddressErrorHandler
        )
      );
    } else {
      // save non existing address
      const nonExistingShippingAddress: CustomAddressRequestObject = {
        ...selectedNonExistingAddress!,
        dealershipId: dealershipId ?? '',
        orderId: newOrUpdatedOrderId,
        editMode,
        isAsapOrDuplicate: (isAsap ?? false) || (isDuplicate ?? false),
        number: selectedNonExistingAddress?.number
          ? selectedNonExistingAddress?.number
          : null,
      };

      dispatch(
        createNonExistingShippingAddress(
          nonExistingShippingAddress,
          createNonExistingShippingAddressSuccessHandler,
          createNonExistingShippingAddressErrorHandler
        )
      );
    }
  };

  const updateOrderHandler = (orderRequestBody: Order) => {
    dispatch(
      updateOrder(
        { ...orderRequestBody, id: orderId },
        onCreateOrUpdateOrderSuccessHandler,
        onCreateOrUpdateOrderErrorHandler
      )
    );
  };

  // when i removed myself first turning off edit mode, and updates the order
  const iRemovedMyselfFromOrder = (orderRequestBody: Order) => {
    if (orderLockInfo) {
      dispatch(
        unlockOrderForEdit({ orderId, orderLockId: orderLockInfo.id }, () =>
          updateOrderHandler(orderRequestBody)
        )
      );

      dispatch(
        setCurrentUserCollaboratorAccess({
          isCurrentUserEditor: false,
          isCurrentUserOwner: false,
          isCurrentUserViewer: false,
        })
      );

      setRemovedMyselfAsCollaborator(false);
      dispatch(setCanEditOrder(false));
    }
  };

  // check if i'm no longer in the list when in edit mode
  const didIRemovedMyselfFromCollaborators = () => {
    const iDontExistsInCollaborators = !selectedCollaborators?.some(
      (collab) => collab.id === loggedInUser?.id
    );

    return (
      removedMyselfAsCollaborator && editMode && iDontExistsInCollaborators
    );
  };

  const amISelectedAsCollaboratorOrOwner = () => {
    const meAsOwner = csrSelectedDealershipUser?.value === loggedInUser?.id;

    return (
      meAsOwner ||
      ((isUserCSR || isUserSalesRep) &&
        selectedCollaborators?.some(
          (selectedCollaborator) => selectedCollaborator.id === loggedInUser?.id
        ))
    );
  };

  const shouldLockOrderForEditingForMe = () => {
    return (
      isUserSalesRep ||
      (!selectedCollaborators && isUserCSR) ||
      selectedCollaborators?.some(
        (selectedCollaborator) =>
          selectedCollaborator.id === loggedInUser?.id &&
          selectedCollaborator.collaboratorType ===
            collaboratorAccessEnums.EDITOR
      )
    );
  };

  const redirectToOrderStyles = (newOrUpdatedOrderId: string) => {
    UtilService.onPopupClose();

    if (!editMode) {
      if (isOrderFormSubmitting) {
        isOrderFormSubmitting(false);
      }

      history.push(`/order/${newOrUpdatedOrderId}/styles`, {
        ...location.state,
        orderCreated: true,
      });

      if (
        createOrderOnBehalfOfDealer ? shouldLockOrderForEditingForMe() : true
      ) {
        // dispatch lock order
        dispatch(
          lockOrderForEdit(
            { orderId: newOrUpdatedOrderId },
            lockOrderForEditSuccessHandler
          )
        );
      }
    } else {
      toast.success('Order successfully updated.');
    }
  };

  const redirectToOrderLineItems = (newOrUpdatedOrderId: string) => {
    UtilService.onPopupClose();

    if (!editMode) {
      if (isOrderFormSubmitting) {
        isOrderFormSubmitting(false);
      }

      history.push(`/order/${newOrUpdatedOrderId}/line-items`, {
        ...location.state,
        orderCreated: true,
      });

      // dispatch lock order
      dispatch(
        lockOrderForEdit(
          { orderId: newOrUpdatedOrderId },
          lockOrderForEditSuccessHandler
        )
      );
    } else {
      toast.success('Order successfully updated.');
    }
  };

  const redirectToStepTwo = (id: string) => {
    UtilService.onPopupClose();

    dispatch(setAsapStep(1));

    // append newly created order id to the url
    const queryParams = new URLSearchParams(location.search);
    queryParams.append('newOrderId', id);
    queryParams.append('step', '1');

    history.replace(
      `${location.pathname}?${queryParams.toString()}`,
      location.state
    );
  };

  const onSubmitOrderJobHandler = (jobId: string) => {
    methods.setValue('jobId', jobId);

    setSelectedJob((prevState) =>
      prevState ? { ...prevState, hasCreated: true } : prevState
    );

    const data = methods.getValues();
    const orderRequestBody: Order = orderRequestBodyMapper(data);

    // disaptch order submit;
    if (!editMode) {
      dispatch(
        createOrder(
          orderRequestBody,
          onCreateOrUpdateOrderSuccessHandler,
          onCreateOrUpdateOrderErrorHandler
        )
      );
    } else {
      if (!isOrderSubmitted && didIRemovedMyselfFromCollaborators()) {
        iRemovedMyselfFromOrder(orderRequestBody);

        return;
      }

      updateOrderHandler(orderRequestBody);
    }
  };

  const onAddressSelectedHanlder = (addresId: string | null) => {
    setSelectedAddressId(addresId);
    dispatch(setNonExistingShippingAddress(null));
  };

  const isAddressSelected = () => {
    return (
      transportationTypeWatched === OrderTransportationTypeEnums.WILL_CALL ||
      transportationTypeWatched === OrderTransportationTypeEnums.NONE ||
      ((transportationTypeWatched ===
        OrderTransportationTypeEnums.PLATO_CONTRACTED_TRANSPORTATION ||
        transportationTypeWatched === OrderTransportationTypeEnums.FEDEX_UPS) &&
        (!!selectedAddressId || !!selectedNonExistingAddress))
    );
  };

  const collaboratorsValidation = () => {
    return (
      (selectedCollaborators && collaboratorsSaved) || !selectedCollaborators
    );
  };

  const addressValidation = () => {
    return (
      existingShippingAddresSaved ||
      nonExistingShippingAddresCreated ||
      transportationTypeWatched === OrderTransportationTypeEnums.WILL_CALL ||
      transportationTypeWatched === OrderTransportationTypeEnums.NONE
    );
  };

  const shipViaValidation = () => {
    return canUpdateShipViaOptions &&
      transportationTypeWatched === OrderTransportationTypeEnums.FEDEX_UPS
      ? shipViaSaved
      : true;
  };

  const submitJobAndOrder = (data: OrderFormFields) => {
    // create job and order
    if (selectedJob && !selectedJob.hasCreated) {
      dispatch(
        createOrderJob(
          { dealershipId: dealershipId ?? '', name: selectedJob.label },
          onSubmitOrderJobHandler
        )
      );
    } else if (selectedJob && selectedJob.hasCreated) {
      const orderRequestBody: Order = orderRequestBodyMapper(data);

      if (!editMode) {
        dispatch(
          createOrder(
            orderRequestBody,
            onCreateOrUpdateOrderSuccessHandler,
            onCreateOrUpdateOrderErrorHandler
          )
        );
      } else {
        if (!isOrderSubmitted && didIRemovedMyselfFromCollaborators()) {
          iRemovedMyselfFromOrder(orderRequestBody);

          return;
        }

        updateOrderHandler(orderRequestBody);
      }
    }
  };

  const cleanOrderAfterUpdate = () => {
    setOrderFormSubmitting((submitting) => {
      setCollaboratorsSaved(false);
      setExistingShippingAddresSaved(false);
      setNonExistingShippingAddresCreated(false);
      setClearSearchedCollaborators(true);
      setAddMeAsCollaboratorModalOpened(false);

      return !submitting;
    });

    toast.success('You have successfully updated the order info.');

    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  const onGetOrderErrorHandler = (err: ServerErrorResponse) => {
    if (err.status === 403) {
      history.push('/forbidden');
    }

    if (err.status === 404) {
      history.push('/page-not-found');
    }
  };

  const handleChangeCallbackWrapper = (callback: () => void) => {
    setIsFormDirty(true);
    callback();
  };

  const onSubmit = (
    data: OrderFormFields,
    isWarningModalConfirmed?: boolean
  ): void => {
    const isOrderStatusTechEditingOrHigher =
      (order?.status?.id ?? 0) >= OrderStatusEnums['Tech Edit Started'];

    if (
      isOrderStatusTechEditingOrHigher &&
      !isWarningModalConfirmed &&
      isUserCSR &&
      !asapOrDuplicate
    ) {
      setIsEditWarningModalOpen(true);
      return;
    }

    setIsFormDirty(false);
    setOrderFormSubmitting(true);
    submitJobAndOrder(data);
  };

  const getSubmitBtnLabel = () => {
    if (editMode) {
      return 'Save Changes';
    }

    if (isQuoteFlow) {
      return 'Create Quote';
    }

    if (asapOrDuplicate) {
      return 'Continue';
    }

    return 'Create Draft';
  };

  const addMeAsCollaboratorConfirmHandler = (close: () => void) => {
    close();

    setAddMeAsCollaboratorModalOpened(false);

    dispatch(
      selectCollaborator({
        ...UtilService.omit(loggedInUser!, ['permissions']),
        collaboratorType: collaboratorAccessEnums.EDITOR,
        automaticallyAdded: true,
      } as Collaborator)
    );
  };

  const addMeAsCollaboratorCancelHandler = () => {
    setAddMeAsCollaboratorModalOpened(false);
    methods.handleSubmit((data) => onSubmit(data))();
  };

  const shouldSubmit = () => {
    return createOrderOnBehalfOfDealer &&
      csrSelectedDealershipUser &&
      (!editMode ||
        dealershipId !== order?.job?.dealershipId ||
        csrSelectedDealershipUser.value !== order?.ownerId)
      ? amISelectedAsCollaboratorOrOwner()
      : true;
  };

  const getOrderCreatorInfoUser = () => {
    if (editMode && order) {
      return order.creatorId === zeroGuid ? order.owner! : order.creator!;
    }

    return loggedInUser!;
  };

  const getTopBoundary = () => {
    if (asapOrDuplicate) return 188;
    if (editMode) return 105;

    return 64;
  };

  useEffect(() => {
    if (
      selectedCollaborators &&
      selectedCollaborators.some((x) => x.automaticallyAdded)
    ) {
      methods.handleSubmit((data) => onSubmit(data))();
    }
  }, [selectedCollaborators?.some((x) => x.automaticallyAdded)]);

  useEffect(() => {
    const isSalesAids = UtilService.isSalesAids(+typeWatched);

    setIsOrderSalesAids(isSalesAids);

    if (isSalesAids) {
      methods.clearErrors('countertopThickness');
      methods.clearErrors('ceilingHeight');
    }
  }, [typeWatched]);

  useEffect(() => {
    if (selectedJob?.hasCreated) {
      setJobId(selectedJob.value);
    } else if (selectedJob && !selectedJob.hasCreated) {
      setJobId('tempJobId');
    } else {
      setJobId('');
    }
  }, [selectedJob]);

  useEffect(() => {
    if (order && (editMode || asapOrDuplicate)) {
      setOrderDefaultValues();
    }
  }, [order]);

  // prepopulate address id or address non existing object address.
  useEffect(() => {
    if (editMode || asapOrDuplicate) {
      if (selectedShippingAddress?.originalAddressId) {
        setSelectedAddressId(selectedShippingAddress?.originalAddressId);
      } else {
        setNonExistingShippingAddress(selectedShippingAddress);
      }
    }
  }, [selectedShippingAddress]);

  useEffect(() => {
    if (
      (createdOrUpdatedOrderId &&
        collaboratorsValidation() &&
        shipViaValidation() &&
        addressValidation()) ||
      (createdOrUpdatedOrderId && collaboratorsValidation() && isQuoteFlow)
    ) {
      if (!editMode) {
        if (asapOrDuplicate) {
          redirectToStepTwo(createdOrUpdatedOrderId);

          dispatch(
            getOrder(
              { orderId: createdOrUpdatedOrderId },
              setOrderLoading,
              onGetOrderErrorHandler
            )
          );
        } else {
          // if not edit mode and not asap or duplicate
          // dispatch get order to populate new data
          dispatch(
            getOrder(
              { orderId: createdOrUpdatedOrderId },
              setOrderLoading,
              onGetOrderErrorHandler
            )
          );

          if (
            stylizationTypeWatched ===
            OrderStylizationTypeEnums.SALES_MATERIAL.toString()
          ) {
            redirectToOrderLineItems(createdOrUpdatedOrderId);
            return;
          }

          // redirect to styles.
          redirectToOrderStyles(createdOrUpdatedOrderId);
        }
      }

      if (editMode && orderFormSubmitting) {
        cleanOrderAfterUpdate();

        dispatch(
          getOrder(
            { orderId: createdOrUpdatedOrderId },
            setOrderLoading,
            onGetOrderErrorHandler
          )
        );
      }
    }
  }, [
    shipViaSaved,
    collaboratorsSaved,
    createdOrUpdatedOrderId,
    existingShippingAddresSaved,
    nonExistingShippingAddresCreated,
    orderFormSubmitting,
  ]);

  useEffect(() => {
    if (asapOrDuplicate && queryFields?.orderId) {
      setOrderLoading(true);
      dispatch(
        getOrder(
          {
            orderId: queryFields.orderId,
            isAsap: isAsap ?? false,
            isDuplicate: isDuplicate ?? false,
          },
          setOrderLoading,
          onGetOrderErrorHandler
        )
      );
    }
  }, [queryFields?.orderId]);

  useEffect(() => {
    if (isOrderFormSubmitting) {
      isOrderFormSubmitting(orderFormSubmitting);
    }
  }, [orderFormSubmitting]);

  useEffect(() => {
    if (
      order?.transportationType === +OrderTransportationTypeEnums.WILL_CALL ||
      order?.transportationType === +OrderTransportationTypeEnums.NONE
    ) {
      methods.setValue('shipOptionId', '');
    }
  }, [order?.transportationType]);

  useEffect(() => {
    if (!csrSelectedDealership && !availableDealershipId) return;

    if (
      (noAvailableDealerships || moreThenOneAvailableDealerships) &&
      isCSRSelectedDealershipDirty
    ) {
      setDealershipId(csrSelectedDealership?.value ?? null);
      return;
    }

    if (!csrSelectedDealership && !isCSRSelectedDealershipDirty) {
      setDealershipId(availableDealershipId);
    }
  }, [csrSelectedDealership, availableDealershipId]);

  useEffect(() => {
    if (dealershipId) {
      setIsDealershipLoading(true);

      dispatch(
        getDealership(
          {
            dealershipId,
          },
          undefined,
          setIsDealershipLoading
        )
      );
    }
  }, [dealershipId]);

  // when CSR changes the dealership
  useEffect(() => {
    if (selectedJob?.hasCreated && isCSRSelectedDealershipDirty) {
      setSelectedJob(null);
    }

    if (
      createOrderOnBehalfOfDealer &&
      csrSelectedDealership === null &&
      isCSRSelectedDealershipDirty
    ) {
      dispatch(setDealership(null));
      setSelectedJob(null);
    }
  }, [csrSelectedDealership]);

  useEffect(() => {
    const shouldSubmitBeDisabled =
      isAsap || (methods.formState.isDirty && isQuoteFlow)
        ? false
        : orderFormSubmitting ||
          !isAddressSelected() ||
          !canEdit ||
          (!methods.formState.isDirty &&
            !isFormDirty &&
            !isCSRSelectedDealershipUserDirty);

    setIsSubmitDisabled(shouldSubmitBeDisabled);
  }, [
    transportationTypeWatched,
    canEdit,
    methods.formState.isDirty,
    isFormDirty,
    isCSRSelectedDealershipUserDirty,
    selectedAddressId,
    selectedNonExistingAddress,
  ]);

  const handleConfirmEditWarningModal = () => {
    setIsEditWarningModalOpen(false);
    methods.handleSubmit((data) => onSubmit(data, true))();
  };

  const EditOrderWarningModal = (
    <ConfirmationModal
      title="Edit order"
      opened={isEditWarningModalOpen}
      confirm={handleConfirmEditWarningModal}
      cancel={() => setIsEditWarningModalOpen(false)}
      buttonText="Edit order"
      cancelButtonText="Cancel"
    >
      <P>You are trying to edit order in “Tech editing” or later statuses.</P>
    </ConfirmationModal>
  );

  useWindowScrollPosition(!!order);

  return (
    <>
      <FormProvider {...methods}>
        <Provider
          contextData={{
            csrSelectedDealershipId: csrSelectedDealership?.value,
            csrSelectedDealershipUserId: csrSelectedDealershipUser?.value,
            dealershipId,
            editMode,
            isCSRSelectedDealershipDirty,
            isCSRSelectedDealershipUserDirty,
            onDealershipChange: setCsrSelectedDealership,
            onDealershipUserChange: setCsrSelectedDealershipUser,
            onRemovedMyselfAsCollaborator: setRemovedMyselfAsCollaborator,
            removedMyselfAsCollaborator,
            setIsCSRSelectedDealershipDirty,
            setIsCSRSelectedDealershipUserDirty,
            handleChangeCallbackWrapper,
          }}
        >
          <Form
            maxWidth={744}
            onKeyDown={(e) => {
              if (e.key === 'Enter') e.preventDefault();
            }}
          >
            {/* csrSelected dealership ID: {dealershipId} <br />
          csrSelected user ID: {csrSelectedDealershipUser?.value} */}
            {createOrderOnBehalfOfDealer && <OrderDealershipSelection />}

            <WhiteBoxSection>
              <SectionHeading>Job & Order information</SectionHeading>
              <FormElement>
                <FormLabel>Job name</FormLabel>

                <OrderJob
                  onSelectJob={setSelectedJob}
                  selectedJob={selectedJob}
                  clearFormErrors={methods.clearErrors}
                  control={methods.control}
                  disabled={!canEdit}
                />

                {selectedJob && !selectedJob.hasCreated ? (
                  <FormElementHint>
                    Keep in mind that this job will be saved after order
                    submission.
                  </FormElementHint>
                ) : null}

                <FormError
                  label="Job name"
                  error={methods.formState.errors.jobId}
                  validationSchema={orderJobValidation({ required: true })}
                />
              </FormElement>

              <FormElement>
                <FormLabel>Order/room name</FormLabel>

                <Input
                  type="text"
                  data-test="input-name"
                  aria-invalid={
                    methods.formState.errors.name ? 'true' : 'false'
                  }
                  {...methods.register(
                    'name',
                    orderNameValidation({ required: true })
                  )}
                  disabled={!canEdit}
                />

                <FormError
                  label="Order / room name"
                  error={methods.formState.errors.name}
                  validationSchema={orderNameValidation({ required: true })}
                />
              </FormElement>

              <OrderTypeField />

              <OrderStylizationTypeField />

              <FormElement>
                <FormLabel>Ceiling Height</FormLabel>

                <Input
                  type="text"
                  data-test="input-ceilingHeight"
                  aria-invalid={
                    methods.formState.errors.ceilingHeight ? 'true' : 'false'
                  }
                  {...methods.register(
                    'ceilingHeight',
                    ceilingHeightValidation({
                      required: !isOrderSalesAids && !isQuoteFlow,
                    })
                  )}
                  readOnly={!canEdit}
                />

                <FormError
                  label="Ceiling Height"
                  error={methods.formState.errors.ceilingHeight}
                  validationSchema={ceilingHeightValidation({
                    required: !isOrderSalesAids && !isQuoteFlow,
                  })}
                />
              </FormElement>

              <FormElement>
                <FormLabel>Countertop Thickness</FormLabel>
                <Input
                  type="text"
                  data-test="input-countertopThickness"
                  aria-invalid={
                    methods.formState.errors.countertopThickness
                      ? 'true'
                      : 'false'
                  }
                  {...methods.register(
                    'countertopThickness',
                    countertopThicknessValidation({
                      required: !isOrderSalesAids && !isQuoteFlow,
                    })
                  )}
                  readOnly={!canEdit}
                  {...testId(OrderTestInputEnum.COUNTER_TOP_THICKNESS_INPUT)}
                />

                <FormError
                  label="Countertop Thickness"
                  error={methods.formState.errors.countertopThickness}
                  validationSchema={countertopThicknessValidation({
                    required: !isOrderSalesAids && !isQuoteFlow,
                  })}
                />
              </FormElement>

              <FormElement>
                <FormLabel>P.O Number</FormLabel>
                <Input
                  type="text"
                  data-test="input-postOfficeNumber"
                  aria-invalid={
                    methods.formState.errors.postOfficeNumber ? 'true' : 'false'
                  }
                  {...methods.register(
                    'postOfficeNumber',
                    postOfficeNumberValidation()
                  )}
                  disabled={!canEdit}
                  {...testId(OrderTestInputEnum.PO_NUMBER_INPUT)}
                />
                <FormError
                  label="P.O Number"
                  error={methods.formState.errors.postOfficeNumber}
                  validationSchema={postOfficeNumberValidation()}
                />
              </FormElement>

              <FormElement>
                <FormLabel>Date requested</FormLabel>
                <Input
                  type="text"
                  data-test="input-dateRequested"
                  aria-invalid={
                    methods.formState.errors.dateRequested ? 'true' : 'false'
                  }
                  {...methods.register(
                    'dateRequested',
                    dateRequestedValidation()
                  )}
                  disabled={!canEdit}
                />

                <FormError
                  label="Date requested"
                  error={methods.formState.errors.dateRequested}
                  validationSchema={dateRequestedValidation()}
                />
              </FormElement>

              {isOrderSalesMaterial && (
                <FormElement>
                  <FormLabel>Original W.O.</FormLabel>
                  <Input
                    type="text"
                    data-test="input-workOrderNumber"
                    aria-invalid={
                      methods.formState.errors.workOrderNumber
                        ? 'true'
                        : 'false'
                    }
                    {...methods.register(
                      'workOrderNumber',
                      workOrderNumberValidation()
                    )}
                    disabled={!canEdit}
                  />

                  <FormError
                    label="Original W.O."
                    error={methods.formState.errors.workOrderNumber}
                    validationSchema={workOrderNumberValidation()}
                  />
                </FormElement>
              )}

              {/* <FormColumns flex>
                <Column>
                  {isUserCSR && (
                    <FormElement>
                      <FormLabel>Deposit paid</FormLabel>

                      <Input
                        type="text"
                        data-test="input-depositPaid"
                        aria-invalid={
                          methods.formState.errors.depositPaid
                            ? 'true'
                            : 'false'
                        }
                        {...methods.register(
                          'depositPaid',
                          depositPaidValidation()
                        )}
                        disabled={!canEdit}
                      />
                      <FormError
                        label="Deposit paid"
                        error={methods.formState.errors.depositPaid}
                        validationSchema={depositPaidValidation()}
                      />
                    </FormElement>
                  )}
                </Column>

                <Spacer w="20px" />

                <Column>
                  {isUserCSR && (
                    <FormElement>
                      <FormLabel>Date</FormLabel>

                      <Controller
                        control={methods.control}
                        name="depositPaidDate"
                        render={({ field }) => {
                          return (
                            <FormDatePicker
                              {...UtilService.omit(field, ['ref'])}
                              placeholder="Select Deposit Paid date..."
                              disabled={!canEdit}
                              clearable
                              error={!!methods.formState.errors.depositPaidDate}
                              minDate={new Date()}
                              helperText=""
                              format={globalDateFormat}
                              autoComplete="off"
                            />
                          );
                        }}
                      />
                    </FormElement>
                  )}
                </Column>
              </FormColumns> */}

              <P>What best describes your order?</P>

              <Spacer h="10px" />

              <Wrapper flex middle>
                <RadioButton
                  {...methods.register(
                    'descriptionType',
                    descriptionTypeValidation({ required: canEdit })
                  )}
                  id={`descriptionType--${OrderDescriptionTypeEnums.REMODELED}`}
                  data-test={`descriptionType--${OrderDescriptionTypeEnums.REMODELED}`}
                  title="Remodeled"
                  value={OrderDescriptionTypeEnums.REMODELED}
                  disabled={!canEdit}
                />

                <Spacer w="20px" />

                <RadioButton
                  {...methods.register(
                    'descriptionType',
                    descriptionTypeValidation({ required: canEdit })
                  )}
                  id={`descriptionType--${OrderDescriptionTypeEnums.NEW_CONSTRUCTION}`}
                  data-test={`descriptionType--${OrderDescriptionTypeEnums.NEW_CONSTRUCTION}`}
                  title="New Construction"
                  value={OrderDescriptionTypeEnums.NEW_CONSTRUCTION}
                  disabled={!canEdit}
                />
              </Wrapper>

              <FormError
                label="Order description type"
                error={methods.formState.errors.descriptionType}
                validationSchema={descriptionTypeValidation({
                  required: canEdit,
                })}
              />
            </WhiteBoxSection>
            <WhiteBoxSection position="relative">
              <SectionHeading>Dealership information</SectionHeading>
              <OrderDealershipInfo />

              {isDealershipLoading && (
                <LoaderFullScreen borderRadius={16} flex middle center>
                  <Loader size={50} />
                </LoaderFullScreen>
              )}
            </WhiteBoxSection>

            {canViewScheduling && order && isOrderSubmitted && (
              <WhiteBoxSection>
                <SectionHeading>Scheduling</SectionHeading>
                <OrderScheduling />
              </WhiteBoxSection>
            )}

            <WhiteBoxSection id="order-creator-info">
              <SectionHeading>Order creator info</SectionHeading>

              {loggedInUser && (
                <UserInfo
                  user={getOrderCreatorInfoUser()}
                  disabled={!canEdit}
                />
              )}

              {(!editMode ||
                !selectedCollaborators?.some(
                  (selectedCollaborator) =>
                    selectedCollaborator.id === csrSelectedDealershipUser?.value
                )) &&
                csrSelectedDealershipUser && (
                  <>
                    <SectionHeading>Order owner info</SectionHeading>

                    <UserInfo
                      user={csrSelectedDealershipUser.user as User}
                      disabled={!canEdit}
                    />
                  </>
                )}

              {selectedCollaborators && (
                <>
                  <SectionHeading>Selected collaborators</SectionHeading>
                  <OrderSelectedCollaborators />
                </>
              )}
              <SectionHeading>Add collaborators on this order</SectionHeading>
              <OrderCollaborators
                clearSearchedCollaborators={clearSearchedCollaborators}
              />
            </WhiteBoxSection>

            {isQuoteFlow && (
              <RadioButton
                {...methods.register(
                  'transportationType',
                  transportationTypeValidation({ required: canEdit })
                )}
                hidden
                id={`transportationType--${OrderTransportationTypeEnums.NONE}`}
                data-test={`transportationType--${OrderTransportationTypeEnums.NONE}`}
                title="None"
                value={OrderTransportationTypeEnums.NONE}
                className="boxed"
                readOnly={!canEdit}
              />
            )}

            {!isQuoteFlow && (
              <WhiteBoxSection>
                <H2>Transportation</H2>
                <Spacer h="10px" />
                <P>Select your preferred method of transportation.</P>

                <Spacer h="30px" />

                <Wrapper flex middle>
                  <RadioButton
                    {...methods.register(
                      'transportationType',
                      transportationTypeValidation({ required: true })
                    )}
                    id={`transportationType--${OrderTransportationTypeEnums.PLATO_CONTRACTED_TRANSPORTATION}`}
                    data-test={`transportationType--${OrderTransportationTypeEnums.PLATO_CONTRACTED_TRANSPORTATION}`}
                    title="Plato contracted transportation"
                    value={
                      OrderTransportationTypeEnums.PLATO_CONTRACTED_TRANSPORTATION
                    }
                    className="boxed"
                    readOnly={!canEdit}
                  />

                  <Spacer w="20px" />

                  <RadioButton
                    {...methods.register(
                      'transportationType',
                      transportationTypeValidation({ required: canEdit })
                    )}
                    id={`transportationType--${OrderTransportationTypeEnums.WILL_CALL}`}
                    data-test={`transportationType--${OrderTransportationTypeEnums.WILL_CALL}`}
                    title="Will call"
                    value={OrderTransportationTypeEnums.WILL_CALL}
                    className="boxed"
                    readOnly={!canEdit}
                  />

                  <Spacer w="20px" />

                  <RadioButton
                    {...methods.register(
                      'transportationType',
                      transportationTypeValidation({ required: canEdit })
                    )}
                    id={`transportationType--${OrderTransportationTypeEnums.FEDEX_UPS}`}
                    data-test={`transportationType--${OrderTransportationTypeEnums.FEDEX_UPS}`}
                    title="FEDEX / UPS"
                    value={OrderTransportationTypeEnums.FEDEX_UPS}
                    className="boxed"
                    readOnly={!canEdit}
                  />
                </Wrapper>

                <FormError
                  label="Order transportation type"
                  error={methods.formState.errors.transportationType}
                  validationSchema={transportationTypeValidation({
                    required: canEdit,
                  })}
                />

                {transportationTypeWatched &&
                  transportationTypeWatched !==
                    OrderTransportationTypeEnums.WILL_CALL &&
                  canGetShipViaOptions && <ShipVia />}

                {transportationTypeWatched !==
                  OrderTransportationTypeEnums.WILL_CALL && (
                  <OrderTransportation
                    addressSelected={onAddressSelectedHanlder}
                  />
                )}
              </WhiteBoxSection>
            )}

            {+stylizationTypeWatched ===
              OrderStylizationTypeEnums.SALES_MATERIAL && (
              <WhiteBoxSection>
                <SectionHeading>Order notes</SectionHeading>
                <FormElement>
                  <Textarea
                    data-test="input-note"
                    placeholder="Notes"
                    aria-invalid={
                      methods.formState.errors.note ? 'true' : 'false'
                    }
                    {...methods.register('note', { maxLength: 1000 })}
                    readOnly={!canEdit}
                  />

                  <FormError
                    label="Order Notes"
                    error={methods.formState.errors.note}
                    validationSchema={{ maxLength: 1000 }}
                  />
                </FormElement>
              </WhiteBoxSection>
            )}
            {canViewDealershipPreferences && (
              <WhiteBoxSection>
                <DealershipPreferences />
              </WhiteBoxSection>
            )}
            <OrderFooter>
              <Container flex justifyEnd>
                <ButtonPrimary
                  onClick={
                    shouldSubmit()
                      ? methods.handleSubmit((data) => onSubmit(data))
                      : () => setAddMeAsCollaboratorModalOpened(true)
                  }
                  disabled={isSubmitDisabled}
                  type="button"
                >
                  {getSubmitBtnLabel()}
                  <Loader
                    hidden={!orderFormSubmitting}
                    insideButton
                    noSpacing
                    size={16}
                  />
                </ButtonPrimary>
              </Container>
            </OrderFooter>

            {orderLoading && (
              <LoaderFullScreen
                position="fixed"
                top={getTopBoundary()}
                bottom={72}
                flex
                middle
                center
              >
                <Loader size={50} />
              </LoaderFullScreen>
            )}

            {canUpdateOrderGrade &&
              order &&
              !isAsap &&
              !isDuplicate &&
              order.status?.id !== OrderStatusEnums['Not Submitted'] && (
                <GradesContainer>
                  <GradesInner flex justifyEnd>
                    <Grades />
                  </GradesInner>
                </GradesContainer>
              )}
          </Form>

          <ConfirmationModal
            title="Add Me as Collaborator"
            opened={addMeAsCollaboratorModalOpened}
            confirm={addMeAsCollaboratorConfirmHandler}
            cancel={addMeAsCollaboratorCancelHandler}
            buttonText="Add me and Create Order"
            cancelButtonText="Create Order without adding me"
          >
            <P>
              You wont be able to edit this order until it&apos;s submitted if
              you don&apos;t add yourself as Collaborator (Editor).
            </P>
          </ConfirmationModal>
        </Provider>
      </FormProvider>

      {EditOrderWarningModal}
    </>
  );
};

export default OrderForm;
