import { FC, useEffect, useState } from 'react';
import { RootState } from 'store';
import { useParams } from 'react-router';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { ReactComponent as PlatoLogoIcon } from 'assets/icons/plato-logo.svg';

import { TopicFilterEnums } from 'curtain/enums/TopicFilterEnums';

import { OrderPageParams } from 'order/interfaces/OrderPageParams';

import { ButtonPrimary } from 'shared/components/Button';
import { P } from 'shared/components/Typography';
import { SelectOptionProps } from 'shared/interface/SelectOptionProps';
import { silverChalice } from 'shared/config/Colors';
import { Spacer } from 'shared/components/Layout';
import { useAppDispatch } from 'shared/hooks/useAppDispatch';
import { Wrapper } from 'shared/components/Wrapper';
import Loader from 'shared/components/Loader';
import ScrollbarsCustom from 'shared/components/ScrollbarsCustom';
import SVG from 'shared/components/SVG';

import {
  getWorkables,
  setWorkables,
  setWorkablesMode,
} from '../../store/curtainWorkablesActions';

import { CurtainWorkablesTabEnums } from '../../enums/CurtainWorkablesTabEnums';
import { IWorkable } from '../../interface/Workable';
import CurtainWorkable from '../CurtainWorkable/CurtainWorkable';

const CurtainWorkableListContainer = styled.div`
  height: calc(100vh - 257px);
`;

const WorkablesEmptyState = styled(Wrapper)`
  height: 100%;

  > .icon > svg {
    height: 318px;
    width: 270px;
  }
`;

interface CurtainWorkableListProps {
  filterOption: SelectOptionProps;
}

const CurtainWorkableList: FC<CurtainWorkableListProps> = ({
  filterOption,
}) => {
  const dispatch = useAppDispatch();

  const { orderId } = useParams<OrderPageParams>();

  const [workablesLoading, setWorkablesLoading] = useState(false);

  const workables = useSelector(
    (state: RootState) => state.curtainWorkablesReducer.workables
  );

  const [localWorkables, setLocalWorkables] =
    useState<IWorkable[] | null>(null);

  const showWorkablesBasedOnFilter = (workable: IWorkable) => {
    switch (filterOption.value) {
      case TopicFilterEnums.UNRESOLVED:
        return !workable.resolved;
      case TopicFilterEnums.RESOLVED:
        return workable.resolved;
      default:
        return true;
    }
  };

  useEffect(() => {
    if (orderId) {
      setWorkablesLoading(true);
      dispatch(getWorkables({ orderId }, setWorkablesLoading));
    }

    return () => {
      dispatch(setWorkables(null));
    };
  }, []);

  useEffect(() => {
    if (workables) {
      setLocalWorkables(workables.filter(showWorkablesBasedOnFilter));
    }
  }, [workables, filterOption]);

  return (
    <CurtainWorkableListContainer>
      {workablesLoading && workables === null && <Loader size={40} />}

      <ScrollbarsCustom autoHide autoHideTimeout={500} autoHideDuration={300}>
        {!workablesLoading &&
          localWorkables?.map((workable) => {
            return <CurtainWorkable key={workable.id} workable={workable} />;
          })}
        {!workablesLoading && localWorkables?.length === 0 && (
          <WorkablesEmptyState flex column middle>
            <Spacer h="30px" />
            <P fontWeight={600} fontSize={22}>
              {['No', `${filterOption.value.replace('both', '')}`, 'workables.']
                .filter((x) => x)
                .join(' ')}
            </P>
          </WorkablesEmptyState>
        )}

        {!workablesLoading && workables && workables.length === 0 && (
          <WorkablesEmptyState flex column middle center>
            <P fontWeight={600} fontSize={12} color={silverChalice}>
              There are currently no workables created.
            </P>

            <Spacer h="28px" />

            <ButtonPrimary
              onClick={() =>
                dispatch(setWorkablesMode(CurtainWorkablesTabEnums.FORM_MODE))
              }
            >
              Add new
            </ButtonPrimary>
            <Spacer h="38px" />

            <SVG icon={<PlatoLogoIcon />} />
          </WorkablesEmptyState>
        )}
      </ScrollbarsCustom>
    </CurtainWorkableListContainer>
  );
};

export default CurtainWorkableList;
