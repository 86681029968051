import { RootState } from 'store';
import { toast } from 'react-toastify';
import { useDecision } from '@optimizely/react-sdk';
import { ChangeEvent, useEffect, useRef, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router';
import { useSelector } from 'react-redux';

import {
  DragDropContext,
  Droppable,
  Draggable,
  DropResult,
  DragStart,
} from 'react-beautiful-dnd';
import { ReactComponent as PlusIcon } from 'assets/icons/plus.svg';
import { ReactComponent as RemoveShoppingCartIcon } from 'assets/icons/removeShoppingCart.svg';

import { clearCurtain } from 'curtain/store/curtainActions';

import { LineItem } from 'order/wizard/orderStyles/interface/LineItem';
import { OrderFooter } from 'order/components/OrderFooter/OrderFooter';
import { OrderLineItem } from 'order/wizard/orderLineItems/OrderLineItem';
import { OrderLineItemsModal } from 'order/wizard/orderLineItems/OrderLineItemsModal';
import { OrderPageParams } from 'order/interfaces/OrderPageParams';
import { OrderPageStates } from 'order/interfaces/OrderPageStates';
import { OrderStylizationTypeEnums } from 'order/enums/orderEnums';
import { ProductLineEnums } from 'order/enums/ProductLineEnums';

import { OrderLineItemsTestTooltipEnum } from 'tests/enums/OrderLineItemsTestEnums';

import {
  getOrderLineItems,
  getOrderStyles,
  setOrderLineItems,
} from 'order/store/orderActions';

import { ButtonIcon, ButtonPrimary } from 'shared/components/Button';
import { Checkbox, TriStateCheckboxEnum } from 'shared/components/Checkbox';
import { Info } from 'shared/components/Info';
import { ModalWrapper } from 'shared/components/ModalWrapper';
import { Spacer } from 'shared/components/Layout';
import { Tooltip } from 'shared/components/Tooltip';
import { useAppDispatch } from 'shared/hooks/useAppDispatch';
import { useOrderProductType } from 'shared/hooks/useOrderProductType';
import { useQueryParams } from 'shared/hooks/useQueryParams';
import { Wrapper } from 'shared/components/Wrapper';
import EmptyState from 'shared/components/EmptyState';
import Loader from 'shared/components/Loader';
import OrderLogs from 'order/components/OrderLogs/OrderLogs';
import UtilService from 'shared/services/util.service';

import useWindowScrollPosition from 'shared/hooks/useWindowScrollPosition';
import ScrollbarsCustom from 'shared/components/ScrollbarsCustom';
import styled from 'styled-components';
import OrderStyle from 'order/components/OrderStyle/OrderStyle';
import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';
import SVG from 'shared/components/SVG';
import { H2, P } from 'shared/components/Typography';
import { nevada } from 'shared/config/Colors';
import { getAdditionalFields } from 'order/additonalFields/store/additionalFieldsActions';
import {
  changeLineItemNumbers,
  setLineItemsModalOpened,
} from './store/orderLineItemsActions';

import { LineItemModalEnums } from './enums/LineItemModalEnums';
import { OrderLineItemModalParams } from './interface/OrderLineItemModalParams';
import { getPopups } from '../../popups/store/popupActions';
import { Style } from '../orderStyles/interface/Style';

const StylesModalContainer = styled.div`
  padding: 19px 25px;
`;
const StylesModalHeaderContainer = styled.div`
  padding: 19px 25px;
`;

const ImportContainer = styled(Wrapper)``;

const ImportStyleSelectContainer = styled.div`
  margin: 2px 0 0 2px;
`;

const StyledPlusIcon = styled(PlusIcon)`
  margin-right: 10px;
`;

const OrderLineItems = () => {
  const location = useLocation<OrderPageStates>();
  const history = useHistory();
  const dispatch = useAppDispatch();

  const { orderId } = useParams<OrderPageParams>();

  const [decision] = useDecision('order_line_item_tab');

  const [loading, setLoading] = useState(false);

  const [queryFields] = useQueryParams<OrderLineItemModalParams>([
    'catalogLineItemId',
    'replacementLineItemId',
    'lineItemId',
    'productLineId',
    'styleId',
  ]);

  const [selectedStyle, setSelectedStyle] = useState<Style | null>(null);

  const [draggedDestinationIndex, setDraggedDestinationIndex] =
    useState<number | null>(null);

  const [draggedLineItem, setDraggedLineItem] = useState<LineItem | null>(null);
  const [noModsOnAnyLineItem, setNoModsOnAnyLineItem] = useState(true);

  const [showAllModifications, setShowAllModifications] = useState(
    TriStateCheckboxEnum.CHECKED
  );

  const [isChecked, setIsChecked] = useState(true);
  const [stylesModalOpened, setStylesModalOpened] = useState(false);

  const checkRef = useRef<HTMLInputElement>(null);
  const lineItemRef = useRef<HTMLDivElement>(null);

  const [expandedLineItemMods, setExpandedLineItemMods] = useState<string[]>(
    []
  );

  const modalOpenedForLineItemId = useSelector(
    (state: RootState) => state.orderLineItemsReducer.modalOpenedForLineItemId
  );

  const styles = useSelector((state: RootState) => state.orderReducer.styles);

  const lineItems = useSelector(
    (state: RootState) => state.orderReducer.lineItems
  );

  const orderWithStyles = useSelector(
    (state: RootState) =>
      state.orderReducer.order?.stylizationType !==
      OrderStylizationTypeEnums.SALES_MATERIAL
  );

  const popups = useSelector((state: RootState) => state.popupReducer.popups);
  const additionalFields = useSelector(
    (state: RootState) => state.aditionalFieldReducer.aditionalFields
  );

  const orderLineItemNotReachedYet =
    (lineItems?.length ?? 0) < 99 * (orderWithStyles ? 9 : 1);

  const customProductLine = useSelector((state: RootState) =>
    state.sharedReducer.productLines?.find(
      (productLine) => productLine.name === ProductLineEnums.PRODUCT_LINE_CUSTOM
    )
  );

  const activeLineItemID = useSelector(
    (state: RootState) => state.curtainReducer.activeLineItemID
  );

  const canEdit = useSelector((state: RootState) => state.orderReducer.canEdit);

  const isOrderSalesMaterial = useOrderProductType(
    OrderStylizationTypeEnums.SALES_MATERIAL
  );

  const onOrderCreatedHandler = () => {
    if (location.state?.orderCreated) {
      toast.success(
        'Order has been successfully created. Add some line items.'
      );

      history.replace(
        `${location.pathname}`,
        UtilService.omit(location.state, ['orderCreated'])
      );
    }
  };

  const onFailedChangeLineItemNumbers = () => {
    toast.error('Could not update line item client number.');
    setDraggedDestinationIndex(null);
    setDraggedLineItem(null);
  };

  const onSuccessChangeLineItemNumbers = (destinationIndex: number) => {
    toast.success('You have successfully reordered line items.');

    setDraggedDestinationIndex(destinationIndex);

    setTimeout(() => {
      setDraggedDestinationIndex(null);
      setDraggedLineItem(null);
    }, 3000);
  };

  const setChangedLineItemNumbers = (
    destinationIndex: number,
    reorderedLineItems: LineItem[]
  ) => {
    if (lineItems) {
      const reorderedLineItemRequestData = reorderedLineItems.map(
        (lineItem) => ({
          lineItemId: lineItem.lineItemId,
          number: lineItem.number,
        })
      );

      dispatch(
        changeLineItemNumbers(
          {
            orderId,
            lineItemChangeNumbers: reorderedLineItemRequestData,
          },
          () => onSuccessChangeLineItemNumbers(destinationIndex),
          onFailedChangeLineItemNumbers
        )
      );
    }
  };

  const onExpandLineItemHandler = (lineItemId: string, expanded: boolean) => {
    setExpandedLineItemMods((prevState) =>
      expanded
        ? [...prevState, lineItemId]
        : prevState.filter((liId) => liId !== lineItemId)
    );
  };

  const updatePwwNumbers = (reorderedLineItems: LineItem[]) => {
    let temp: LineItem[] = [];
    if (!styles?.length) {
      temp = reorderedLineItems.map((item: LineItem, index) => {
        return {
          ...item,
          pwwNumber: `1.${index + 1}`,
        };
      });
    } else {
      styles.forEach((style) => {
        const styleItems = reorderedLineItems
          .filter((li) => li.styleId === style.id)
          .map((item: LineItem, index) => {
            return {
              ...item,
              pwwNumber: `${style.number}.${index + 1}`,
            };
          });
        temp = [...temp, ...styleItems];
      });
    }

    return temp.sort((a, b) => UtilService.sortBy(a.number, b.number));
  };

  const onDragEnd = (result: DropResult) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    if (lineItems) {
      let reorderedLineItems = UtilService.reorder(
        lineItems,
        result.source.index,
        result.destination.index
      );

      reorderedLineItems = updatePwwNumbers(reorderedLineItems);
      dispatch(setOrderLineItems(reorderedLineItems));

      setChangedLineItemNumbers(result.destination.index, reorderedLineItems);

      onExpandLineItemHandler(
        reorderedLineItems[result.destination.index].lineItemId,
        isChecked
      );
    }
  };

  const onDragStart = (initial: DragStart) => {
    onExpandLineItemHandler(initial.draggableId, false);
    dispatch(clearCurtain());
  };

  const changeLineItemPosition = (
    currentPosition: number,
    newPosition: number
  ) => {
    if (lineItems) {
      let reorderedLineItems = UtilService.reorder(
        lineItems,
        currentPosition,
        newPosition
      );

      reorderedLineItems = updatePwwNumbers(reorderedLineItems);
      dispatch(setOrderLineItems(reorderedLineItems));

      setTimeout(() => {
        setChangedLineItemNumbers(newPosition, reorderedLineItems);
      }, 0);
    }
  };

  const getProductLineId = () => {
    if (!isOrderSalesMaterial)
      return selectedStyle?.productLine?.id ?? queryFields!.productLineId;

    return customProductLine?.id ?? null;
  };

  const openLineItemModal = () => {
    const params = new URLSearchParams();

    const productLineId = getProductLineId();

    if (productLineId) {
      params.append('productLineId', productLineId);
    }

    if (!isOrderSalesMaterial) {
      params.append('styleId', selectedStyle?.id ?? queryFields!.styleId);
    }

    if (queryFields?.catalogLineItemId) {
      params.append('catalogLineItemId', queryFields.catalogLineItemId);
    }

    if (queryFields?.lineItemId) {
      params.append('lineItemId', queryFields.lineItemId);
    }

    if (queryFields?.replacementLineItemId) {
      params.append('replacementLineItemId', queryFields.replacementLineItemId);
    }

    if (queryFields?.selectedLineItemDetailsId) {
      params.append(
        'selectedLineItemDetailsId',
        queryFields.selectedLineItemDetailsId
      );
    }

    params.sort();

    history.replace(
      `${location.pathname}?${params.toString()}`,
      location.state
    );

    dispatch(
      setLineItemsModalOpened(
        queryFields?.lineItemId ?? LineItemModalEnums.LINE_ITEMS_CATALOG
      )
    );

    // close curtain
    dispatch(clearCurtain());

    UtilService.onPopupOpen();

    setStylesModalOpened(false);
  };

  const loadOrderLineItems = () => {
    if (isOrderSalesMaterial !== null) {
      dispatch(setOrderLineItems(null));
      setLoading(true);
      dispatch(
        getOrderLineItems(
          {
            orderId,
            isSalesMaterial: isOrderSalesMaterial,
            productLineId: customProductLine?.id ?? '',
          },
          setLoading
        )
      );
    }
  };

  const onShowAllModsChangeHandler = (e: ChangeEvent<HTMLInputElement>) => {
    setShowAllModifications(
      e.target.checked
        ? TriStateCheckboxEnum.CHECKED
        : TriStateCheckboxEnum.UNCHECKED
    );

    setIsChecked(e.target.checked);

    if (lineItems) {
      setExpandedLineItemMods(
        e.target.checked ? lineItems.map((li) => li.lineItemId) : []
      );
    }
  };

  const updateShowModCheckboxState = () => {
    const everyLineItemExpanded = lineItems
      ?.filter((li) => (li.modifications?.length ?? 0) > 0)
      ?.every((li) => expandedLineItemMods.includes(li.lineItemId));

    // middle state
    if (
      lineItems &&
      expandedLineItemMods.length > 0 &&
      !everyLineItemExpanded &&
      checkRef.current
    ) {
      setShowAllModifications(TriStateCheckboxEnum.INDETERMINATE);

      checkRef.current.indeterminate = true;
    }

    // nothing expanded
    if (
      lineItems &&
      !expandedLineItemMods.length &&
      !everyLineItemExpanded &&
      checkRef.current
    ) {
      setShowAllModifications(TriStateCheckboxEnum.UNCHECKED);
      checkRef.current.indeterminate = false;
    }

    // everything expanded
    if (lineItems && everyLineItemExpanded && checkRef.current) {
      setShowAllModifications(TriStateCheckboxEnum.CHECKED);
      checkRef.current.indeterminate = false;
    }
  };

  const ifLineItemLimitNotReachedYetForStyle = (styleId: string) => {
    const lineItemsPerStyle = lineItems?.filter(
      (lineItem) => lineItem.styleId === styleId
    );

    return (lineItemsPerStyle?.length ?? 0) < 99;
  };

  useEffect(() => {
    if (
      ((isOrderSalesMaterial && queryFields?.productLineId) ||
        (styles && queryFields?.styleId)) &&
      queryFields
    ) {
      openLineItemModal();
    }
  }, [styles, isOrderSalesMaterial]);

  useEffect(() => {
    if (!isOrderSalesMaterial && !styles) {
      dispatch(getOrderStyles(orderId));
    }
  }, [styles, isOrderSalesMaterial]);

  useEffect(() => {
    if (isOrderSalesMaterial !== null && customProductLine) {
      loadOrderLineItems();
    }

    onOrderCreatedHandler();

    return () => {
      dispatch(setOrderLineItems(null));
      window.sessionStorage.setItem('lineItemNumber', '');
    };
  }, [isOrderSalesMaterial, customProductLine]);

  const scrollToLineItem = () => {
    const index = window.sessionStorage.getItem('lineItemNumber');
    if (index && index !== '') {
      const child = lineItemRef.current?.children[+index - 1];
      child?.scrollIntoView({ block: 'center', behavior: 'smooth' });
    }
  };

  useEffect(() => {
    if (lineItems) {
      setLoading(false);
      setNoModsOnAnyLineItem(
        !lineItems.some((li) => li.modifications?.length > 0)
      );
      setTimeout(() => {
        scrollToLineItem();
      }, 100);
    } else {
      setLoading(true);
    }

    setSelectedStyle(null);
  }, [lineItems]);

  useEffect(() => {
    if (lineItems && location.state?.openLineItemToAttachments) {
      const foundLineItem = lineItems.find(
        (li) => li.lineItemId === location.state.openLineItemToAttachments
      );

      if (!foundLineItem) return;

      const params =
        UtilService.prepareQueryParamsForLineItemModal(foundLineItem);

      history.replace(
        {
          pathname: location.pathname,
          search: params.toString(),
        },
        { navigateAttachments: true }
      );

      dispatch(setLineItemsModalOpened(foundLineItem.lineItemId));
    }
  }, [lineItems, location.state?.openLineItemToAttachments]);

  useEffect(() => {
    if (lineItems && draggedDestinationIndex !== null) {
      const lineItem = lineItems.find(
        (li) => li.number === draggedDestinationIndex + 1
      );

      if (lineItem) {
        setDraggedLineItem(lineItem);
      }
    }
  }, [lineItems, draggedDestinationIndex]);

  useEffect(() => {
    updateShowModCheckboxState();
  }, [expandedLineItemMods]);

  useEffect(() => {
    if (!popups) {
      dispatch(getPopups());
    }

    if (!additionalFields) {
      dispatch(getAdditionalFields());
    }
  }, []);

  const styleOptions = styles?.filter(
    (style) => style.isComplete && !style.isDeleted
  );

  const openStylesModal = () => {
    if (styleOptions && styleOptions.length === 1) {
      setSelectedStyle(styleOptions[0]);
    } else {
      setStylesModalOpened(true);
    }
  };

  const onSelectStyleHandler = (style: Style) => {
    setSelectedStyle(style);
  };

  const handleCloseClick = () => {
    setStylesModalOpened(false);
    setSelectedStyle(null);
  };

  useEffect(() => {
    if (selectedStyle) {
      openLineItemModal();
    }
  }, [selectedStyle]);

  useWindowScrollPosition(
    lineItems !== null && window.sessionStorage.getItem('lineItemNumber') === ''
  );

  return (
    <div>
      {(lineItems?.length ?? 0) > 0 && (
        <>
          <Wrapper flex justifyEnd>
            <Wrapper withTooltip>
              <Checkbox
                onChange={onShowAllModsChangeHandler}
                id="showAllModifications"
                ref={checkRef}
                checked={showAllModifications === TriStateCheckboxEnum.CHECKED}
                title="Show Modifications"
                disabled={noModsOnAnyLineItem}
              />
              {noModsOnAnyLineItem && (
                <Tooltip>
                  There are no modifications on any of the added line items.
                </Tooltip>
              )}
            </Wrapper>
          </Wrapper>

          <Spacer h="20px" />
        </>
      )}

      {decision.enabled && (
        <>
          {loading && <Loader size={50} />}

          {!loading && !lineItems?.length && lineItems !== null && (
            <EmptyState
              title="No line items defined yet!"
              icon={<RemoveShoppingCartIcon />}
              iconSize={96}
              marginTop={120}
            />
          )}

          {!loading && !!lineItems?.length && (
            <DragDropContext onDragEnd={onDragEnd} onDragStart={onDragStart}>
              <Droppable droppableId="droppable">
                {(droppableProvided) => (
                  <div
                    {...droppableProvided.droppableProps}
                    ref={droppableProvided.innerRef}
                  >
                    <div ref={lineItemRef}>
                      {lineItems?.map((lineItem, index) => (
                        <Draggable
                          key={lineItem.lineItemId}
                          draggableId={lineItem.lineItemId}
                          index={index}
                          isDragDisabled={!canEdit}
                        >
                          {(draggableProvided) => (
                            <OrderLineItem
                              expanded={expandedLineItemMods.includes(
                                lineItem.lineItemId
                              )}
                              onExpand={onExpandLineItemHandler}
                              lineItem={lineItem}
                              draggableProvided={draggableProvided}
                              totalItems={lineItems.length}
                              index={index}
                              changeLineItemPosition={changeLineItemPosition}
                              draggedItemHighlight={
                                draggedLineItem?.lineItemId ===
                                  lineItem.lineItemId ||
                                activeLineItemID === lineItem.lineItemId
                              }
                            />
                          )}
                        </Draggable>
                      ))}
                    </div>
                    {droppableProvided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          )}
        </>
      )}

      {!orderLineItemNotReachedYet && (
        <>
          <Spacer h="50px" />
          <Wrapper flex center>
            <Info
              type="warning"
              fieldTestId={OrderLineItemsTestTooltipEnum.LINE_ITEMS_LIMIT_TTP}
            >
              Line Item limit ({99 * (orderWithStyles ? 9 : 1)}) has been
              reached. In order to add new line items to this order you would
              need to remove an existing one.
            </Info>
          </Wrapper>
          <Spacer h="50px" />
        </>
      )}

      <OrderLogs />

      <OrderFooter>
        <Wrapper flex middle justifyEnd>
          <Wrapper withTooltip>
            <ButtonPrimary
              onClick={
                isOrderSalesMaterial ? openLineItemModal : openStylesModal
              }
              disabled={
                loading ||
                !canEdit ||
                !orderLineItemNotReachedYet ||
                (!isOrderSalesMaterial && !styleOptions?.length)
              }
            >
              <StyledPlusIcon />
              Add line item
            </ButtonPrimary>

            {!orderLineItemNotReachedYet && (
              <Tooltip>Line Items limit reached</Tooltip>
            )}
          </Wrapper>
        </Wrapper>

        <ModalWrapper
          className="modal--full-screen"
          modal
          closeOnEscape={false}
          nested
          onOpen={UtilService.onPopupOpen}
          onClose={UtilService.onPopupClose}
          lockScroll
          open={
            modalOpenedForLineItemId === LineItemModalEnums.LINE_ITEMS_CATALOG
          }
        >
          {(close: () => void) => <OrderLineItemsModal close={close} />}
        </ModalWrapper>

        <ModalWrapper
          open={stylesModalOpened}
          closeOnDocumentClick={false}
          lockScroll
          closeOnEscape={false}
          modal
        >
          <StylesModalContainer>
            <StylesModalHeaderContainer>
              <Wrapper flex middle>
                <Wrapper flex middle mrAuto>
                  <H2>Select a style</H2>
                </Wrapper>

                <ButtonIcon onClick={handleCloseClick}>
                  <SVG icon={<CloseIcon />} />
                </ButtonIcon>
              </Wrapper>
              <Spacer h="5px" />
              <P color={nevada}>
                Please select a style which you want to add line item to.
              </P>
            </StylesModalHeaderContainer>
            <ImportContainer>
              <ScrollbarsCustom
                autoHide
                autoHeight
                autoHeightMin={500}
                autoHeightMax={500}
                autoHideTimeout={400}
                autoHideDuration={300}
              >
                <ImportStyleSelectContainer>
                  {styleOptions?.map((style) => {
                    return (
                      <Wrapper
                        disabled={
                          !ifLineItemLimitNotReachedYetForStyle(style?.id ?? '')
                        }
                      >
                        <OrderStyle
                          key={style.id}
                          style={style}
                          expandable={false}
                          selected={selectedStyle?.id === style.id}
                          noActions
                          onSelect={onSelectStyleHandler}
                          marginBottom={style.override ? 0 : 16}
                        />
                      </Wrapper>
                    );
                  })}
                </ImportStyleSelectContainer>
              </ScrollbarsCustom>
            </ImportContainer>
          </StylesModalContainer>
        </ModalWrapper>
      </OrderFooter>
    </div>
  );
};

export default OrderLineItems;
