import styled from 'styled-components';
import { RootState } from 'store';
import { useSelector } from 'react-redux';
import { useState, FC, useEffect, useContext } from 'react';

import { OrderFormContext } from 'order/wizard/orderForm/OrderFormContext/orderFormContext';

import { DefaultAddressesLabels, AddressType } from 'order/enums/orderEnums';
import { FormLabel } from 'shared/components/FormLabel';
import { Select } from 'shared/components/Select';
import { SelectOptionProps } from 'shared/interface/SelectOptionProps';
import { ShippingAddress } from 'shared/interface/ShippingAddress';
import UtilService from 'shared/services/util.service';

import AddressDetails from '../../AddressDetails/AddressDetails';

interface OrderDefaultAddressProps {
  existingAddressChange: (addressId: string | null) => void;
}

const DefaultAddressContainer = styled.div``;

const OrderDefaultAddress: FC<OrderDefaultAddressProps> = ({
  existingAddressChange,
}) => {
  const { handleChangeCallbackWrapper, isCSRSelectedDealershipDirty } =
    useContext(OrderFormContext);

  const canEdit = useSelector((state: RootState) => state.orderReducer.canEdit);

  // pull out addresses from redux store
  const defaultAddresses = useSelector(
    (state: RootState) => state.dealershipReducer.dealership?.addresses
  );

  // pull out selected shipping address
  const selectedShippingAddress = useSelector(
    (state: RootState) => state.orderReducer.selectedShippingAddress
  );

  // selected address object
  const [selectedAddress, setSelectedAddress] =
    useState<ShippingAddress | null>(null);

  // address options
  const [addressOptions, setAddressOptions] =
    useState<SelectOptionProps[] | null>(null);

  // selected address option
  const [selectedAddressOption, setSelectedAddressOption] =
    useState<SelectOptionProps | null>(null);

  // change address option handler
  const onDefaultAddressChangeHandler = (val: SelectOptionProps) => {
    setSelectedAddressOption(val);

    const foundAddress =
      val && defaultAddresses?.find((address) => address.id === val.value);

    setSelectedAddress(val && foundAddress ? foundAddress : null);

    // emit selected address id
    existingAddressChange(foundAddress ? foundAddress.id : null);
  };

  // populate select with options
  useEffect(() => {
    if (defaultAddresses) {
      setAddressOptions(
        UtilService.mapAddresstoSelectOption(
          defaultAddresses
        ) as SelectOptionProps[]
      );

      const truckShippingAddress = defaultAddresses.find(
        (address) => address.type === AddressType.TruckShipping
      );

      if (truckShippingAddress) {
        setSelectedAddress(truckShippingAddress);
        existingAddressChange(truckShippingAddress.id);
      } else {
        setSelectedAddress(null);
      }
    }
  }, [defaultAddresses]);

  useEffect(() => {
    if (addressOptions && selectedShippingAddress) {
      const foundAddressOption = addressOptions.find((address) => {
        if (
          !isCSRSelectedDealershipDirty &&
          selectedShippingAddress.originalAddressId
        ) {
          return address.value === selectedShippingAddress.originalAddressId;
        }

        return address.label === DefaultAddressesLabels.TruckShipping;
      });

      const foundAddress = defaultAddresses?.find((defaultAddress) => {
        if (
          !isCSRSelectedDealershipDirty &&
          selectedShippingAddress.originalAddressId
        ) {
          return (
            defaultAddress.id === selectedShippingAddress.originalAddressId
          );
        }

        return defaultAddress.type === AddressType.TruckShipping;
      });

      setSelectedAddress(foundAddress || null);
      setSelectedAddressOption(foundAddressOption || null);
      existingAddressChange(foundAddress?.id ?? null);

      return;
    }

    setSelectedAddressOption(
      addressOptions?.find(
        (addressOption) =>
          addressOption.label === DefaultAddressesLabels.TruckShipping
      ) || null
    );
  }, [addressOptions, selectedShippingAddress]);

  return (
    <DefaultAddressContainer>
      <FormLabel>Select an address</FormLabel>
      <Select
        placeholder="Select default address"
        value={selectedAddressOption}
        isDisabled={!addressOptions?.length || !canEdit}
        options={addressOptions}
        onChange={(val: SelectOptionProps) =>
          handleChangeCallbackWrapper?.(() =>
            onDefaultAddressChangeHandler(val)
          )
        }
      />

      {selectedAddress && <AddressDetails address={selectedAddress} />}
    </DefaultAddressContainer>
  );
};

export default OrderDefaultAddress;
