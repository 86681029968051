import { RootState } from 'store';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import styled from 'styled-components';
import { Controller, useForm } from 'react-hook-form';
import { FC, useEffect, useState } from 'react';

import { OrderPageParams } from 'order/interfaces/OrderPageParams';
import { OrderStatusEnums } from 'order/enums/orderEnums';

import { updateOrderStatus } from 'order/store/orderActions';

import { PMedium } from 'shared/components/Typography';
import { Select } from 'shared/components/Select';
import { SelectOptionProps } from 'shared/interface/SelectOptionProps';
import { useAppDispatch } from 'shared/hooks/useAppDispatch';
import { useHasPermissions } from 'shared/hooks/useHasPermissions';
import { userPermissionsValues } from 'shared/enum/userPermissionsEnum';
import { Wrapper } from 'shared/components/Wrapper';
import Loader from 'shared/components/Loader';
import { testId } from 'tests/utils';
import { OrderHeaderTestElementsEnum } from 'tests/enums/OrderHeaderEnums';
import { OrderFlowsEnum, useIsOrderFlow } from 'shared/hooks/useIsOrderFlow';
import { getOrderAssignees } from 'order/components/OrderAssignees/store/orderAssigneesActions';

const OrderStatusContainer = styled(Wrapper)`
  margin-right: 40px;
`;

interface OrderStatusForm {
  status: string;
}

const OrderStatus: FC = (props) => {
  const dispatch = useAppDispatch();
  const { orderId } = useParams<OrderPageParams>();

  const order = useSelector((state: RootState) => state.orderReducer.order);

  // order status number with fallback if order or status is not loaded yet
  const orderStatus = order?.status?.id ?? 0;

  const canUpdateOrderStatus =
    useHasPermissions(userPermissionsValues.ORDER_UPDATE_STATUS) &&
    orderStatus !== OrderStatusEnums.Canceled &&
    orderStatus !== OrderStatusEnums.Invoiced;

  const isQuoteFlow = useIsOrderFlow(OrderFlowsEnum.QUOTE);

  const [orderStatusOptions, setOrderStatusOptions] =
    useState<SelectOptionProps[] | null>(null);

  const [statusUpdating, setStatusUpdating] = useState(false);

  const getOrderDefaultStatus = () => {
    return {
      status: orderStatus > 0 ? orderStatus.toString() : '',
    };
  };

  const methods = useForm<OrderStatusForm>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: getOrderDefaultStatus(),
  });

  const statusWatched = methods.watch('status');

  const generateStatusOptions = () => {
    // generate status options based on order status enums
    const keys = Object.keys(
      OrderStatusEnums
    ) as (keyof typeof OrderStatusEnums)[];

    // split enum array
    const halfOfKeys = keys.slice(keys.length / 2);

    const options = halfOfKeys
      .filter(
        (key) =>
          OrderStatusEnums[key] !== OrderStatusEnums.Drawing &&
          OrderStatusEnums[key] !== OrderStatusEnums.Writing &&
          OrderStatusEnums[key] !== OrderStatusEnums.Released &&
          OrderStatusEnums[key] !== OrderStatusEnums.Canceled
      )
      .map<SelectOptionProps>((key) => ({
        value: OrderStatusEnums[key].toString(),
        label: key,
        isDisabled: OrderStatusEnums[key] < 2,
      }));

    setOrderStatusOptions(options);
  };

  const generateQuoteStatusOptions = () => {
    const keys = Object.keys(
      OrderStatusEnums
    ) as (keyof typeof OrderStatusEnums)[];

    const options = keys
      .filter(
        (key) =>
          OrderStatusEnums[key] === OrderStatusEnums['Not Submitted'] ||
          OrderStatusEnums[key] === OrderStatusEnums.Received ||
          OrderStatusEnums[key] === OrderStatusEnums['Taken For Processing'] ||
          OrderStatusEnums[key] === OrderStatusEnums.Acknowledged
      )
      .map<SelectOptionProps>((key) => ({
        value: OrderStatusEnums[key].toString(),
        label: key,
        isDisabled: OrderStatusEnums[key] < 2,
      }));

    setOrderStatusOptions(options);
  };

  const updateOrderStatusSuccessHandler = () => {
    toast.success('You have successfully changed status of the order.');
    if (
      +statusWatched === OrderStatusEnums['Taken For Processing'] ||
      +statusWatched === OrderStatusEnums['Taken For Drawing']
    ) {
      dispatch(getOrderAssignees({ orderId }));
    }
  };

  useEffect(() => {
    if (isQuoteFlow) {
      generateQuoteStatusOptions();
    } else {
      generateStatusOptions();
    }
  }, [isQuoteFlow]);

  useEffect(() => {
    // reset form when order status is changed in the redux store
    methods.reset(getOrderDefaultStatus());
  }, [order?.status]);

  useEffect(() => {
    // dispatch status update
    if (methods.formState.dirtyFields.status && +statusWatched) {
      dispatch(
        updateOrderStatus(
          { orderId, orderStatusId: +statusWatched },
          updateOrderStatusSuccessHandler,
          setStatusUpdating
        )
      );
    }
  }, [statusWatched]);

  if (
    !canUpdateOrderStatus &&
    orderStatus > OrderStatusEnums['Not Submitted']
  ) {
    return (
      <OrderStatusContainer
        {...props}
        {...testId(OrderHeaderTestElementsEnum.STATUS_STATIC)}
      >
        <PMedium>{order?.status?.name ?? 'N/A'}</PMedium>
      </OrderStatusContainer>
    );
  }

  return (
    <OrderStatusContainer
      minWidth={180}
      {...props}
      {...testId(OrderHeaderTestElementsEnum.STATUS_DYNAMIC)}
    >
      <Controller
        control={methods.control}
        name="status"
        render={({ field: { onChange, value, ref } }) => (
          <Select
            ref={ref}
            onChange={(val: SelectOptionProps) => onChange(val.value)}
            options={orderStatusOptions}
            value={orderStatusOptions?.find((option) => option.value === value)}
          />
        )}
      />

      {statusUpdating && <Loader noSpacing size={20} />}
    </OrderStatusContainer>
  );
};

export default OrderStatus;
