import styled from 'styled-components';

import { Divider, Spacer } from 'shared/components/Layout';
import { H2, H5, H6, P } from 'shared/components/Typography';
import { Wrapper } from 'shared/components/Wrapper';
import { RootState } from 'store';
import { useSelector } from 'react-redux';

import { DealershipReducerInterface } from '../store/dealershipReducer';

const GridTableRow = styled.div`
  display: grid;
  grid-template-columns: 145px 1fr;
  gap: 40px;
`;

const H6Styled = styled(H6)`
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 0.08em;
  margin-top: 5px;
`;

const H5Styled = styled(H5)`
  font-weight: 700;
`;

const H5StyledGray = styled(H5Styled)`
  color: ${({ theme }) => theme.lynch};
`;

const MultiplierWrapper = styled(Wrapper)`
  &:not(:last-child) {
    margin-bottom: 20px;
  }
`;

const MultiplierDivider = styled.div`
  height: 1px;
  width: 20px;
  margin: 0 10px 0 auto;
  border-radius: 2px;
  background: ${({ theme }) => theme.mischka};
`;

const PStyled = styled(P)`
  font-size: 12px;
  line-height: 16px;
`;

const H2Styled = styled(H2)`
  font-weight: 700;
  color: ${({ theme }) => theme.lynch};
`;

const StyledDivider = styled(Divider)`
  margin: 30px 0;
`;

export const DealershipPricingTable = () => {
  const { priceVariablesGlobal }: DealershipReducerInterface = useSelector(
    (state: RootState) => state.dealershipReducer
  );

  return (
    <div>
      <GridTableRow>
        <H6Styled>Multipliers</H6Styled>
        <Wrapper flex column>
          {priceVariablesGlobal &&
            Object.entries(priceVariablesGlobal.multipliers).map(
              ([key, value]) => (
                <MultiplierWrapper flex middle key={key}>
                  <H5Styled>{key}</H5Styled>
                  <MultiplierDivider />
                  <H2Styled>{value}</H2Styled>
                </MultiplierWrapper>
              )
            )}
        </Wrapper>
      </GridTableRow>

      <StyledDivider />

      <GridTableRow>
        <H6Styled>Jobsite Delivery</H6Styled>
        <div>
          <Wrapper flex middle>
            <H5Styled>${priceVariablesGlobal?.jobSite}</H5Styled>
            <Spacer w="8px" />
            <H5StyledGray>NET</H5StyledGray>
          </Wrapper>
          <Spacer h="12px" />
          <PStyled>
            Jobsite delivery fee will be charged per stop and additional fees
            may apply. It is the dealer’s responsibility to verify that the
            jobsite is legally accessible to the vehicle and trailer that would
            be making the delivery. Any damage to the truck or trailer incurred
            during delivery, or any fines imposed while attempting a jobsite
            delivery are the sole responsibility of the dealer.
          </PStyled>
        </div>
      </GridTableRow>

      <StyledDivider />

      <GridTableRow>
        <H6Styled>Freight</H6Styled>
        <div>
          <Wrapper flex middle>
            <H5Styled>{priceVariablesGlobal?.freight}</H5Styled>
            <Spacer w="8px" />
            <H5StyledGray>%</H5StyledGray>
          </Wrapper>

          <Spacer h="12px" />

          <PStyled>Freight is a percentage applied to NET order cost.</PStyled>
        </div>
      </GridTableRow>

      <StyledDivider />

      <GridTableRow>
        <H6Styled>Fuel Surcharge</H6Styled>
        <div>
          <Wrapper flex middle>
            <H5Styled>{priceVariablesGlobal?.fuelSurcharge}</H5Styled>
            <Spacer w="8px" />
            <H5StyledGray>%</H5StyledGray>
          </Wrapper>

          <Spacer h="12px" />
          <PStyled>
            Fuel Surcharge is a percentage applied to NET order cost.
          </PStyled>
        </div>
      </GridTableRow>
    </div>
  );
};
