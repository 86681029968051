import { useEffect, useState } from 'react';
import axios from 'axios';
import mime from 'mime-types';
import { Chooser, ChooserFile, ChooserOptions } from 'shared/interface/Dropbox';

import env from 'env_config';
import useScript from './useScript';
import { fileTypesAllowed } from '../config/Variables';

const useDropboxPicker = (): [() => void, File | null, number | null] => {
  const [dropbox, setDropbox] = useState<Chooser>();
  const [file, setFile] = useState<File | null>(null);
  const [fileSelectedCount, setFileSelectedCount] =
    useState<number | null>(null);

  const status = useScript({
    type: 'text/javascript',
    src: 'https://www.dropbox.com/static/api/2/dropins.js',
    id: 'dropboxjs',
    apiKey: env.dropboxApiKey,
  });

  const generateDropboxFiles = (dropboxFiles: ChooserFile[]) => {
    let generatedFile: File | null = null;

    dropboxFiles.forEach(async (doc: ChooserFile) => {
      await axios.get(doc.link, { responseType: 'blob' }).then((response) => {
        generatedFile = new File([response.data], doc.name, {
          type: mime.lookup(doc.name) as string,
        });
      });

      setFile(generatedFile!);
    });
  };

  const options: ChooserOptions = {
    success: (data: ChooserFile[]) => {
      generateDropboxFiles(data);
      setFileSelectedCount(data.length);
    },
    linkType: 'direct',
    multiselect: true,
    extensions: Object.values(fileTypesAllowed),
    folderselect: false,
    sizeLimit: 29360128,
  };

  const openPicker = () => {
    if (dropbox) {
      dropbox.choose(options);
    }
  };

  useEffect(() => {
    if (status === 'ready') {
      setDropbox(
        (window as unknown as Window & Record<'Dropbox', Chooser>).Dropbox
      );
    }
  }, [status]);

  return [openPicker, file, fileSelectedCount];
};

export default useDropboxPicker;
