import { useLocation } from 'react-router-dom';
import { RootState } from 'store';
import { toast } from 'react-toastify';
import { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { ReactComponent as CheckIcon } from 'assets/icons/check-dark.svg';
import { ReactComponent as CommentIcon } from 'assets/icons/comment-gray.svg';
import { ReactComponent as FlagIcon } from 'assets/icons/flag.svg';
import { ReactComponent as TrashIcon } from 'assets/icons/trash-nicer.svg';
import { ReactComponent as EditIcon } from 'assets/icons/edit.svg';

import { TopicFilterEnums } from 'curtain/enums/TopicFilterEnums';
import { ICurtainTopic } from 'curtain/interfaces/ICurtainTopic';
import { IGetTopicsRequest } from 'curtain/interfaces/IGetTopicsRequest';

import {
  getTopicsByChannel,
  getTopicsByEntity,
  getOtherTopics,
  setActiveTopic,
  resolveTopic,
  unresolveTopic,
  setTopicToResolved,
  setTopicToUnresolved,
  setTopicsFilter,
  changeTopicPriority,
  setTopicPriority,
  getNumberOfUnreadTopics,
  setUpdatedTopic,
  setTopicTypeDefaultValue,
  setTopicItemDefaultValue,
  deleteTopic,
} from 'curtain/store/curtainActions';
import { ConfirmationModal } from 'shared/components/ConfirmationModal';

import { Priority } from 'order/enums/priorityEnum';
import { OrderStatusEnums } from 'order/enums/orderEnums';

import { mineShaft } from 'shared/config/Colors';
import { PriorityBadge } from 'shared/components/PriorityBadge';
import { PXSmall, H5 } from 'shared/components/Typography';
import { ResolveButton } from 'shared/components/ResolveButton';
import { Spacer } from 'shared/components/Layout';
import { Tooltip } from 'shared/components/Tooltip';
import { useAppDispatch } from 'shared/hooks/useAppDispatch';
import { useHasPermissions } from 'shared/hooks/useHasPermissions';
import { useIsOrderInStatus } from 'shared/hooks/useIsOrderInStatus';
import { userPermissionsValues } from 'shared/enum/userPermissionsEnum';
import { Wrapper } from 'shared/components/Wrapper';
import EmptyState from 'shared/components/EmptyState';
import HangingNote from 'shared/components/HangingNote';
import Loader from 'shared/components/Loader';
import SVG from 'shared/components/SVG';
import UtilService from 'shared/services/util.service';

import {
  ButtonPrimary,
  TabbableButton,
  ButtonIcon,
} from 'shared/components/Button';

import { NewCollaborationBadge } from 'shared/components/NewCollaborationBadge';
import { TopicType } from 'curtain/enums/TopicTypeEnum';
import {
  getUnresolvedTopics,
  setShouldNavigateToTopic,
  updateLineItemNoOfTopics,
  updateLineItemNoOfUnreadTopics,
  updateStyleNoOfTopics,
  updateStyleNoOfUnreadTopics,
} from '../../order/store/orderActions';
import { getChannelsAndTopics } from '../../order/wizard/topic/store/TopicActions';
import { ChannelTypeEnum } from '../enums/ChannelTypeEnum';
import { usePriorityChange } from '../../shared/hooks/usePriorityChange';
import { TopicMeta } from './TopicMeta';

const TopicButton = styled(TabbableButton)`
  padding: 16px 46px 20px 20px;
  display: flex;
  flex-direction: column;
  width: 100%;
  border-radius: 0;
  text-align: start;
  border-bottom: 1px solid ${({ theme }) => theme.alto};

  &:hover {
    background-color: ${({ theme }) => theme.wildSand};
  }

  &:focus:not(:hover):not(:active) {
    border-radius: 0;
  }
`;

const TopicContainer = styled(Wrapper)`
  position: relative;
`;

const ChangePriorityButton = styled(ButtonIcon)`
  align-items: center;
  border-radius: 50%;
  border: 2px solid ${({ theme }) => theme.silverSand};
  bottom: 16px;
  display: flex;
  height: 24px;
  justify-content: center;
  position: absolute;
  right: 23px;
  width: 24px;

  &:focus:not(:hover):not(:active) {
    border-radius: 50%;
  }

  &:hover {
    ${Tooltip} {
      opacity: 1;
    }
  }

  &.important {
    background-color: ${({ theme }) => theme.valencia};

    path {
      fill: ${({ theme }) => theme.white};
    }
  }

  &.attention {
    background-color: ${({ theme }) => theme.anzac};

    path {
      fill: ${({ theme }) => theme.white};
    }
  }
`;

const RemoveTopicButton = styled(ButtonIcon)`
  align-items: center;
  border-radius: 50%;
  border: 2px solid ${({ theme }) => theme.silverSand};
  bottom: 16px;
  display: flex;
  height: 24px;
  justify-content: center;
  position: absolute;
  right: 55px;
  width: 24px;
  top: 16px;
  &:focus:not(:hover):not(:active) {
    border-radius: 50%;
  }

  &:hover {
    ${Tooltip} {
      opacity: 1;
    }
  }
`;

const EditTopicButton = styled(ButtonIcon)`
  align-items: center;
  border-radius: 50%;
  border: 2px solid ${({ theme }) => theme.silverSand};
  bottom: 16px;
  display: flex;
  height: 24px;
  justify-content: center;
  position: absolute;
  right: 86px;
  width: 24px;
  top: 16px;
  &:focus:not(:hover):not(:active) {
    border-radius: 50%;
  }

  &:hover {
    ${Tooltip} {
      opacity: 1;
    }
  }
`;

const DraftLabel = styled.span`
  opacity: 0.5;
  padding-left: 5px;
`;

const CurtainTopicList: FC<{ other?: boolean }> = ({ other }) => {
  const [areTopicsLoading, setAreTopicsLoading] = useState(false);
  const dispatch = useAppDispatch();

  const queryParams = new URLSearchParams(useLocation().search);

  const order = useSelector((state: RootState) => state.orderReducer.order);

  const shouldNavigateToTopic = useSelector(
    (state: RootState) => state.orderReducer.shouldNavigateToTopic
  );

  const topics: ICurtainTopic[] = useSelector((state: RootState) =>
    other ? state.curtainReducer.otherTopics : state.curtainReducer.topics
  );

  const activeChannel = useSelector(
    (state: RootState) => state.curtainReducer.activeChannel
  );

  const activeLineItemID = useSelector(
    (state: RootState) => state.curtainReducer.activeLineItemID
  );

  const activeEntityID = useSelector(
    (state: RootState) => state.curtainReducer.activeEntityID
  );

  const activeEntityType = useSelector(
    (state: RootState) => state.curtainReducer.activeEntityType
  );

  const activeEntityName = useSelector(
    (state: RootState) => state.curtainReducer.activeEntityName
  );

  const topicsFilter = useSelector(
    (state: RootState) => state.curtainReducer.topicsFilter
  );

  const { styleChannels, lineItemChannels, otherTopics } = useSelector(
    (state: RootState) => state.topicReducer
  );

  const isOrderSubmitted = useIsOrderInStatus({
    greaterThen: true,
    status: OrderStatusEnums['Not Submitted'],
  });

  const isUserCSR = useHasPermissions(
    userPermissionsValues.CSR_VIEW_ORDERBOARD
  );

  const canChangeTopicPriority = useHasPermissions(
    userPermissionsValues.TOPIC_CHANGE_PRIORITY
  );

  const orderLineItems = useSelector(
    (state: RootState) => state.orderReducer.lineItems
  );

  const styles = useSelector((state: RootState) => state.orderReducer.styles);

  const [topicResolveInProgress, setTopicResolveInProgress] = useState(false);
  const [topicPriorityInProgress, setTopicPriorityInProgress] = useState(false);

  const [openDeleteModal, setOpenDeleteModal] = useState(false);

  const [selectedTopicForDelete, setSelectedTopicForDelete] =
    useState<ICurtainTopic | undefined>(undefined);

  const { changePriority } = usePriorityChange();

  const toggleResolveState = () => {
    if (topicsFilter === TopicFilterEnums.RESOLVED) {
      dispatch(setTopicsFilter(TopicFilterEnums.UNRESOLVED));
    } else {
      dispatch(setTopicsFilter(TopicFilterEnums.RESOLVED));
    }
  };

  useEffect(() => {
    setAreTopicsLoading(true);
    if (order && order.id) {
      if (activeChannel) {
        const data: IGetTopicsRequest = {
          orderId: order.id,
          channelId: activeChannel.id,
        };
        dispatch(
          getTopicsByChannel(data, (isLoading: boolean) =>
            setAreTopicsLoading(isLoading)
          )
        );
      } else if (activeLineItemID) {
        const data: IGetTopicsRequest = {
          orderId: order.id,
          channelId: activeLineItemID,
        };
        dispatch(
          getTopicsByChannel(data, (isLoading: boolean) =>
            setAreTopicsLoading(isLoading)
          )
        );
      } else if (activeEntityID) {
        dispatch(
          getTopicsByEntity(
            { orderId: order.id, entityId: activeEntityID },
            () => {
              setAreTopicsLoading(false);
            }
          )
        );
      } else {
        dispatch(
          getOtherTopics(
            { orderId: order.id, topicFlag: TopicFilterEnums.UNRESOLVED },
            () => setAreTopicsLoading(false)
          )
        );
      }
    }
  }, [order]);

  const refetchTopics = () => {
    if (order?.id) {
      const getStyleTopics =
        !styleChannels || activeChannel?.type === ChannelTypeEnum.STYLE;
      const getLineItemTopics =
        !lineItemChannels || activeChannel?.type === ChannelTypeEnum.LINE_ITEM;

      dispatch(
        getChannelsAndTopics({
          orderId: order.id,
          getStyleTopics,
          getLineItemTopics,
          getOtherTopics: !otherTopics || other,
        })
      );
    }
  };

  const loadNumberOfUnreadTopics = () => {
    dispatch(getNumberOfUnreadTopics(order?.id ?? ''));

    if (activeChannel && activeChannel.entityId) {
      if (activeChannel.type === ChannelTypeEnum.STYLE) {
        const activeStyle = styles?.find(
          (style) => style.id === activeChannel.entityId
        );

        if (activeStyle && activeStyle.unreadTopicCount > 0) {
          dispatch(
            updateStyleNoOfUnreadTopics({
              id: activeChannel.entityId,
              topicCount: activeStyle.unreadTopicCount - 1,
            })
          );
        }
      } else {
        const activeLineItem = orderLineItems?.find(
          (lineItem) => lineItem.lineItemId === activeChannel.entityId
        );
        if (activeLineItem && activeLineItem.numberOfUnreadTopics > 0) {
          dispatch(
            updateLineItemNoOfUnreadTopics({
              lineItemId: activeChannel.entityId,
              topicCount: activeLineItem.numberOfUnreadTopics - 1,
            })
          );
        }
      }
    }
  };
  const loadNumberOfUnresolvedTopics = () => {
    if (!isOrderSubmitted) {
      dispatch(getUnresolvedTopics(order?.id ?? ''));
    }
  };

  const updateNumberOfTopics = () => {
    if (activeChannel && activeChannel.entityId) {
      if (activeChannel.type === ChannelTypeEnum.STYLE) {
        const activeStyle = styles?.find(
          (style) => style.id === activeChannel.entityId
        );

        if (activeStyle && activeStyle.topicCount > 0) {
          dispatch(
            updateStyleNoOfTopics({
              id: activeChannel.entityId,
              topicCount: activeStyle.topicCount - 1,
            })
          );
        }
      } else {
        const activeLineItem = orderLineItems?.find(
          (lineItem) => lineItem.lineItemId === activeChannel.entityId
        );
        if (activeLineItem && activeLineItem.numberOfTopics > 0) {
          dispatch(
            updateLineItemNoOfTopics({
              lineItemId: activeChannel.entityId,
              topicCount: activeLineItem.numberOfTopics - 1,
            })
          );
        }
      }
    }
  };

  const onSuccessResolveTopic = (topicId: string) => {
    refetchTopics();

    setTopicResolveInProgress(false);

    dispatch(
      setTopicToResolved({
        topicId,
        isOrderSubmitted: isOrderSubmitted ?? false,
        other,
      })
    );
    changePriority(topicId, activeChannel?.entityId, Priority.None);
    loadNumberOfUnreadTopics();
    loadNumberOfUnresolvedTopics();
    toast.success('You have successfully resolved a topic.');
  };

  const onFailedResolveOtherTopic = () => {
    setTopicResolveInProgress(false);
    toast.error("Topic hasn't been resolved. Please try again.");
  };

  const onSuccessUnresolveTopic = (topicId: string) => {
    refetchTopics();

    setTopicResolveInProgress(false);

    dispatch(
      setTopicToUnresolved({
        topicId,
        isOrderSubmitted: isOrderSubmitted ?? false,
        other,
      })
    );
    changePriority(topicId, activeChannel?.entityId, Priority.Attention);
    loadNumberOfUnresolvedTopics();
    toast.success('You have successfully unresolved a topic.');
  };

  const onFailedUnresolveOtherTopic = () => {
    toast.error("Topic hasn't been unresolved. Please try again.");
    setTopicResolveInProgress(false);
  };

  const onSuccessChangePriority = (topicId: string, priority: Priority) => {
    refetchTopics();

    setTopicPriorityInProgress(false);
    dispatch(setTopicPriority(topicId, priority));
    changePriority(topicId, activeChannel?.entityId, priority);
    toast.success("You have successfully changed topic's priority");
  };

  const onFailChangePriority = () => {
    setTopicPriorityInProgress(false);
    toast.success("Topic's priority change failed. Please try again.");
  };

  const getPriorityClass = (priority: Priority) => {
    switch (priority) {
      case Priority.None:
        return '';
      case Priority.Important:
        return 'important';
      case Priority.Attention:
        return 'attention';
      default:
        return '';
    }
  };

  const onChangeTopicPriorityHandler = (topic: ICurtainTopic) => {
    let priority = Priority.None;
    switch (topic.priority) {
      case Priority.None:
        priority = Priority.Attention;
        break;
      case Priority.Attention:
        priority = Priority.Important;
        break;
      case Priority.Important:
        priority = Priority.None;
        break;
      default:
        priority = Priority.None;
        break;
    }
    dispatch(
      changeTopicPriority(
        topic.id,
        priority,
        () => onSuccessChangePriority(topic.id, priority),
        onFailChangePriority
      )
    );
  };

  const resolvedState = topicsFilter === TopicFilterEnums.RESOLVED;

  const topicIdFromUrl = queryParams.get('topicId');
  const topicTypeFromUrl = queryParams.get('topicType');

  const filteredTopics = shouldNavigateToTopic
    ? topics
    : topics?.filter(
        (topic) =>
          (resolvedState && topic.isResolved) ||
          (!resolvedState && !topic.isResolved)
      );

  useEffect(() => {
    if (
      topicIdFromUrl &&
      topicTypeFromUrl &&
      shouldNavigateToTopic &&
      filteredTopics
    ) {
      const foundTopic = filteredTopics?.find(
        (topic) => topic.id === topicIdFromUrl
      );

      if (foundTopic) {
        dispatch(setActiveTopic(foundTopic));
      }
      dispatch(setShouldNavigateToTopic(false));
    }
  }, [topicIdFromUrl, topics, shouldNavigateToTopic]);

  const onTopicEditClickHandler = (topic: ICurtainTopic) => {
    dispatch(setUpdatedTopic(topic));

    if (activeEntityType === TopicType.STYLE) {
      dispatch(
        setTopicTypeDefaultValue({
          value: TopicType.STYLE,
          label: 'Style',
        })
      );
    }

    if (activeEntityType === TopicType.LINE_ITEM) {
      dispatch(
        setTopicTypeDefaultValue({
          value: TopicType.LINE_ITEM,
          label: 'Line Item',
        })
      );
    }

    if (other) {
      dispatch(
        setTopicTypeDefaultValue({
          value: TopicType.OTHER,
          label: 'Other',
        })
      );
    }

    if ((activeEntityID && activeEntityName) || activeChannel) {
      dispatch(
        setTopicItemDefaultValue({
          value: activeEntityID ?? activeChannel?.entityId,
          label: activeEntityName ?? activeChannel?.name,
        })
      );
    }
  };

  const { changeEntityPriority } = usePriorityChange();

  const onDeleteTopicModalOpen = (topic: ICurtainTopic) => {
    setOpenDeleteModal(true);
    setSelectedTopicForDelete(topic);
  };

  const onDeleteTopicHandler = () => {
    dispatch(
      deleteTopic(selectedTopicForDelete!.id, () => {
        changeEntityPriority(activeEntityID, activeEntityType as TopicType);

        refetchTopics();
        loadNumberOfUnreadTopics();
        updateNumberOfTopics();
        setOpenDeleteModal(false);
        toast.success('Topic has been deleted sucessfully');
      })
    );
  };

  const getDraftLabel = (topicId: string) => {
    const draftKey = `draft_${activeChannel?.id ?? '0'}_${topicId}`;
    if (window.sessionStorage.getItem(draftKey)) {
      return <DraftLabel>(Draft)</DraftLabel>;
    }
    return null;
  };

  const showCollaborationBadge = (topic: ICurtainTopic) => {
    return (!topic.isRead && isUserCSR) || (!topic.dealerRead && !isUserCSR);
  };

  const renderTopics = () => {
    if (!areTopicsLoading) {
      if (filteredTopics?.length > 0) {
        return filteredTopics
          .filter((topic) => !topic.isDeleted)
          .map((topic) => (
            <TopicContainer flex key={topic.id} flexGrow>
              <TopicButton
                key={topic.id}
                onClick={() => {
                  dispatch(setActiveTopic(topic));
                }}
              >
                <Wrapper
                  position="relative"
                  withTooltip={(topic.name?.length ?? 0) > 180}
                  tooltipDelay={500}
                  width={80}
                >
                  <H5 wordBreak="break-word">
                    {UtilService.trimStringToNumberOfChars(topic.name, 180)}
                    {getDraftLabel(topic.id)}
                  </H5>

                  <Tooltip maxWidth={220} minWidth={220} position="bottom">
                    {topic.name}
                  </Tooltip>
                </Wrapper>

                <Spacer h="4px" />

                <TopicMeta topic={topic} />

                <Spacer h="10px" />

                <Wrapper flex middle>
                  <CommentIcon />

                  <Spacer w="6px" />

                  <PXSmall>{topic.commentsCount}</PXSmall>

                  {topic.changeRequested && (
                    <>
                      <Spacer w="20px" />
                      <HangingNote mrAuto />
                    </>
                  )}

                  {!topic.isDeleted && topic.priority !== Priority.None && (
                    <>
                      <Spacer w="20px" />
                      <PriorityBadge priority={topic.priority} />
                    </>
                  )}

                  {showCollaborationBadge(topic) && (
                    <>
                      <Spacer w="20px" />
                      <NewCollaborationBadge />
                    </>
                  )}
                </Wrapper>
              </TopicButton>

              {canChangeTopicPriority &&
                !topic.isResolved &&
                !topic.isDeleted &&
                order?.status?.id !== OrderStatusEnums['Not Submitted'] && (
                  <ChangePriorityButton
                    className={getPriorityClass(topic.priority)}
                    disabled={topicPriorityInProgress}
                    onClick={() => {
                      onChangeTopicPriorityHandler(topic);
                      setTopicPriorityInProgress(true);
                    }}
                  >
                    <Tooltip position="left">
                      Click to change the priority of the topic.
                    </Tooltip>
                    <FlagIcon />
                  </ChangePriorityButton>
                )}

              {!topic.isDeleted &&
                (!isOrderSubmitted || canChangeTopicPriority) && (
                  <ResolveButton
                    disabled={topicResolveInProgress}
                    onClick={() => {
                      setTopicResolveInProgress(true);

                      if (topic.isResolved) {
                        dispatch(
                          unresolveTopic(
                            topic.id,
                            () => onSuccessUnresolveTopic(topic?.id),
                            onFailedUnresolveOtherTopic
                          )
                        );
                      } else {
                        dispatch(
                          resolveTopic(
                            topic?.id,
                            () => onSuccessResolveTopic(topic?.id),
                            onFailedResolveOtherTopic
                          )
                        );
                      }
                    }}
                    className={`${topic.isResolved && 'resolved-topic'}`}
                  >
                    <SVG icon={<CheckIcon />} color={mineShaft} />
                  </ResolveButton>
                )}
              {!topic.isDeleted && isUserCSR && (
                <>
                  <EditTopicButton
                    onClick={() => onTopicEditClickHandler(topic)}
                  >
                    <Tooltip position="left">Click to edit the topic.</Tooltip>
                    <EditIcon />
                  </EditTopicButton>
                  <RemoveTopicButton
                    onClick={() => onDeleteTopicModalOpen(topic)}
                  >
                    <Tooltip position="left">
                      Click to remove the topic.
                    </Tooltip>
                    <TrashIcon />
                  </RemoveTopicButton>
                  <ConfirmationModal
                    confirm={onDeleteTopicHandler}
                    buttonText="Delete"
                    cancel={() => setOpenDeleteModal(false)}
                    title="Are you sure?"
                    message="This topic will be permanently removed."
                    opened={openDeleteModal}
                  />
                </>
              )}
            </TopicContainer>
          ));
      }

      return (
        <EmptyState title={`No ${topicsFilter} topics`}>
          <ButtonPrimary onClick={toggleResolveState}>
            Switch to{' '}
            {topicsFilter === TopicFilterEnums.RESOLVED
              ? TopicFilterEnums.UNRESOLVED
              : TopicFilterEnums.RESOLVED}
          </ButtonPrimary>
        </EmptyState>
      );
    }

    return null;
  };
  return (
    <Wrapper flex column>
      {areTopicsLoading && <Loader />}
      {renderTopics()}
    </Wrapper>
  );
};

CurtainTopicList.defaultProps = {
  other: false,
};

export { CurtainTopicList };
