import { DraggableProvided } from 'react-beautiful-dnd';
import { FC } from 'react';
import { RootState } from 'store';
import { useSelector } from 'react-redux';
import styled, { css } from 'styled-components';

import { ReactComponent as CreateNoteIcon } from 'assets/icons/add-note.svg';
import { ReactComponent as EditIcon } from 'assets/icons/edit.svg';
import { ReactComponent as EditNoteIcon } from 'assets/icons/edit-note.svg';
import { ReactComponent as TrashIcon } from 'assets/icons/trash-nicer.svg';
import { ReactComponent as ReplaceIcon } from 'assets/icons/find_replace.svg';

import { checkAsapItem } from 'order/wizard/orderASAP/store/OrderAsapActions';

import DragButton from 'shared/components/DragButton';
import PerFactoryBadges from 'shared/components/PerFactoryBadges';
import SVG from 'shared/components/SVG';
import UtilService from 'shared/services/util.service';
import { BoxShadowCSS } from 'shared/config/GlobalStyles';
import { ButtonIcon } from 'shared/components/Button';
import { Checkbox } from 'shared/components/Checkbox';
import { ConfirmationModal } from 'shared/components/ConfirmationModal';
import { FloatingCheckbox } from 'shared/components/FloatingCheckbox';
import { H6, P } from 'shared/components/Typography';
import { Info } from 'shared/components/Info';
import { Modal } from 'shared/components/Modal';
import { ModalWrapper } from 'shared/components/ModalWrapper';
import { Spacer } from 'shared/components/Layout';
import { Tooltip } from 'shared/components/Tooltip';
import { Wrapper } from 'shared/components/Wrapper';
import { useAppDispatch } from 'shared/hooks/useAppDispatch';
import { useHasPermissions } from 'shared/hooks/useHasPermissions';
import { userPermissionsValues } from 'shared/enum/userPermissionsEnum';

import {
  silverSandTwo,
  lynch,
  mineShaft,
  valencia,
  anzac,
} from 'shared/config/Colors';

import { IModification } from './Modifications/interface/IModification';
import { ModificationModalStepsEnums } from './Modifications/enums/ModificationModalStepsEnums';

import {
  removeLineItemModification,
  setIsLineItemsStepDirty,
} from '../store/orderLineItemsActions';

import ModificationForm from './Modifications/ModificationForm';
import ModificationsModalHeader from './Modifications/ModificationsModalHeader';

import {
  clearModsModal,
  setModificationFlow,
  setSelectedModification,
  updateModificationsModal,
} from './Modifications/store/lineItemModificationsActions';

import ImageBox from '../../../../shared/components/ImageBox';
import { ModificationsModal } from './Modifications/ModificationsModal';

const ModificationContainer = styled.div`
  margin-bottom: 8px;
`;

interface ModificationContainerInnerProps {
  invalid: boolean;
}

const ModificationContainerInner = styled.div<ModificationContainerInnerProps>`
  ${BoxShadowCSS}
  background-color: ${({ theme }) => theme.white};
  border-radius: 8px;
  padding: 16px 32px;
  position: relative;
  width: 100%;
  border: 1px solid transparent;

  ${({ invalid, theme }) =>
    invalid &&
    css`
      background-color: ${theme.fairPink};
      border-color: ${theme.valencia};
    `}
`;

interface ModificationProps {
  modification: IModification;
  noActions?: boolean;
  canCheck?: boolean;
  styleId?: string;
  lineItemId?: string;
  draggableProvided?: DraggableProvided;
}

export const Modification: FC<ModificationProps> = ({
  modification,
  noActions,
  canCheck,
  styleId,
  lineItemId,
  draggableProvided,
}) => {
  const dispatch = useAppDispatch();

  const isUserDealerOrSalesRep = useHasPermissions([
    userPermissionsValues.DEALER_VIEW_ORDERBOARD,
    userPermissionsValues.SALES_REPRESENTATIVE_VIEW_ORDER_BOARD,
  ]);

  const checkedModifications = useSelector(
    (state: RootState) => state.orderAsapReducer.checkedModifications
  );

  const canEdit = useSelector((state: RootState) => state.orderReducer.canEdit);

  const onModificationDeleteClickHandler = () => {
    dispatch(setIsLineItemsStepDirty(true));
    dispatch(removeLineItemModification(modification.id));
  };

  const onModificationModalOpenHandler = () => {
    dispatch(setSelectedModification(modification));
    dispatch(
      updateModificationsModal(
        ModificationModalStepsEnums.UPDATE_MODIFICATION,
        ModificationModalStepsEnums.UPDATE_MOD_TITLE,
        ModificationModalStepsEnums.UPDATE_MOD_DESC
      )
    );
  };

  const onLineItemCheckedHandler = (checked: boolean) => {
    if (checked) {
      // check parent style
      dispatch(
        checkAsapItem({
          checked,
          itemId: styleId ?? '',
          itemType: 'Styles',
        })
      );
    }

    if (checked) {
      // check parent line item
      dispatch(
        checkAsapItem({
          checked,
          itemId: lineItemId ?? '',
          itemType: 'LineItems',
        })
      );
    }

    // check current modification
    dispatch(
      checkAsapItem({
        checked,
        itemId: modification.id!,
        itemType: 'Modifications',
      })
    );
  };

  const handleReplaceClick = () => {
    dispatch(
      setModificationFlow({
        replaceModification: true,
        modificationToReplace: modification,
      })
    );
    dispatch(
      updateModificationsModal(
        ModificationModalStepsEnums.SEARCH_MODIFICATIONS,
        ModificationModalStepsEnums.REPLACE_MOD_TITLE,
        ModificationModalStepsEnums.SEARCH_MOD_DESC
      )
    );
  };

  return (
    <ModificationContainer
      ref={draggableProvided?.innerRef}
      {...draggableProvided?.draggableProps}
    >
      <ModificationContainerInner
        invalid={
          modification.isValid === undefined ? false : !modification.isValid
        }
      >
        {canCheck && (
          <FloatingCheckbox>
            <Checkbox
              id={modification.id}
              checked={checkedModifications.some(
                (checkedModification) => checkedModification === modification.id
              )}
              onChange={(e) => onLineItemCheckedHandler(e.target.checked)}
            />
          </FloatingCheckbox>
        )}

        {draggableProvided && (
          <DragButton
            {...draggableProvided?.dragHandleProps}
            disabled={!canEdit}
          />
        )}

        <Wrapper flex middle between>
          <P fontSize={12} fontWeight={600}>
            QTY {modification.quantity}
          </P>

          <Spacer w="16px" />

          <ImageBox
            imageUrl={
              modification?.imageUrl ??
              modification?.modificationDetails?.imageUrl
            }
          />

          <Spacer w="16px" />

          <Wrapper flex column maxWidth={105} flexGrow>
            <H6>
              {(
                modification.modificationDetails?.name ?? modification.name
              ).toUpperCase()}
            </H6>
            <P fontSize={12} color={mineShaft}>
              {UtilService.toCapitalize(
                modification.modificationDetails?.description ??
                  modification.description
              )}
            </P>
          </Wrapper>

          <Wrapper flex column maxWidth={190} flexGrow mlAuto mrAuto>
            <P fontSize={12} color={mineShaft}>
              {modification.note}
            </P>

            <Spacer h="8px" />

            <P fontSize={12} color={mineShaft}>
              {modification.additionalNote}
            </P>
          </Wrapper>

          <Wrapper flex column center middle>
            <P fontWeight={600} fontSize={12}>
              Unit Price
            </P>
            <P color={mineShaft} fontSize={12}>
              ${modification.price}
            </P>
          </Wrapper>

          <Spacer w="16px" />

          <PerFactoryBadges
            perDrawing={modification.perDrawing}
            perFactoryDrawing={modification.perFactoryDrawing}
          />

          {noActions && <Spacer w="150px" />}

          {!noActions && (
            <>
              <Spacer w="48px" />

              <ModalWrapper
                modal
                nested
                closeOnDocumentClick={false}
                closeOnEscape={false}
                onOpen={onModificationModalOpenHandler}
                onClose={() => {
                  dispatch(clearModsModal());
                }}
                trigger={
                  <ButtonIcon onClick={onModificationModalOpenHandler}>
                    <Wrapper withTooltip>
                      <SVG
                        icon={
                          modification.note === '' ||
                          modification.additionalNote === '' ? (
                            <CreateNoteIcon />
                          ) : (
                            <EditNoteIcon />
                          )
                        }
                        color={
                          modification.note !== '' ||
                          modification.additionalNote !== ''
                            ? lynch
                            : silverSandTwo
                        }
                        hoverColor={lynch}
                      />
                      <Tooltip position="top" maxWidth={110} minWidth={110}>
                        Note
                      </Tooltip>
                    </Wrapper>
                  </ButtonIcon>
                }
              >
                {(close: () => void) => (
                  <Modal close={close} noHeader withoutFooter={1}>
                    <ModificationsModalHeader />
                    <ModificationForm closeModal={close} />
                  </Modal>
                )}
              </ModalWrapper>

              <Spacer w="9px" />

              <ModalWrapper
                modal
                nested
                closeOnDocumentClick={false}
                closeOnEscape={false}
                onOpen={onModificationModalOpenHandler}
                onClose={() => {
                  dispatch(clearModsModal());
                }}
                trigger={
                  <ButtonIcon>
                    <Wrapper withTooltip>
                      <SVG
                        icon={<EditIcon />}
                        color={silverSandTwo}
                        hoverColor={lynch}
                      />
                      <Tooltip position="top" maxWidth={110} minWidth={110}>
                        Edit
                      </Tooltip>
                    </Wrapper>
                  </ButtonIcon>
                }
              >
                {(close: () => void) => (
                  <Modal close={close} noHeader withoutFooter={1}>
                    <ModificationsModalHeader />
                    <ModificationForm closeModal={close} />
                  </Modal>
                )}
              </ModalWrapper>

              <Spacer w="9px" />

              <ConfirmationModal
                trigger={
                  <Wrapper withTooltip>
                    <ButtonIcon>
                      <Wrapper withTooltip>
                        <SVG
                          icon={<TrashIcon />}
                          color={silverSandTwo}
                          hoverColor={lynch}
                        />
                        <Tooltip position="top" maxWidth={110} minWidth={110}>
                          Delete
                        </Tooltip>
                      </Wrapper>
                    </ButtonIcon>

                    {canEdit &&
                      isUserDealerOrSalesRep &&
                      modification.dealerAvailable === false && (
                        <Tooltip position="left" bgColor={anzac}>
                          Modification set by CSR! If you delete it - you wont
                          be able to bring it back to the line item.
                        </Tooltip>
                      )}
                  </Wrapper>
                }
                confirm={onModificationDeleteClickHandler}
                title="Are you sure?"
              >
                {isUserDealerOrSalesRep &&
                  modification.dealerAvailable === false && (
                    <>
                      <Info type="warning">
                        Modification set by CSR! If you delete it - you wont be
                        able to bring it back to this line item.
                      </Info>

                      <Spacer h="20px" />
                    </>
                  )}

                <P>
                  This modification and its data will be deleted from line item.
                  Change will be permanent once you save the line item.
                </P>
              </ConfirmationModal>

              <Spacer w="9px" />

              <ModalWrapper
                modal
                nested
                closeOnDocumentClick={false}
                closeOnEscape={false}
                onOpen={handleReplaceClick}
                trigger={
                  <ButtonIcon>
                    <Wrapper withTooltip>
                      <SVG
                        icon={<ReplaceIcon />}
                        color={silverSandTwo}
                        hoverColor={lynch}
                      />
                      <Tooltip position="top" maxWidth={110} minWidth={110}>
                        Replace
                      </Tooltip>
                    </Wrapper>
                  </ButtonIcon>
                }
              >
                {(close: () => void) => (
                  <Modal close={close} noHeader withoutFooter={1}>
                    <ModificationsModal closeModal={close} />
                  </Modal>
                )}
              </ModalWrapper>
            </>
          )}
        </Wrapper>
      </ModificationContainerInner>

      {modification.isValid === undefined
        ? false
        : !modification.isValid && (
            <Wrapper>
              <Spacer h="5px" />
              <P fontSize={12} color={valencia} textAlign="right">
                {modification.invalidateReason}
              </P>
              <Spacer h="5px" />
            </Wrapper>
          )}
    </ModificationContainer>
  );
};
