import { RootState } from 'store';
import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useParams } from 'react-router';
import { useSelector } from 'react-redux';

import { FormLabel } from 'shared/components/FormLabel';
import { Divider, Spacer } from 'shared/components/Layout';
import { useAppDispatch } from 'shared/hooks/useAppDispatch';
import { zeroGuid } from 'shared/config/Variables';
import Loader from 'shared/components/Loader';

import { CodeFunction } from 'order/wizard/orderStyles/interface/CodeFunction';
import { OrderPageParams } from 'order/interfaces/OrderPageParams';
import { ProductLineEnums } from 'order/enums/ProductLineEnums';
import { Style } from 'order/wizard/orderStyles/interface/Style';
import { StyleDoorBuilder } from 'order/wizard/orderStyles/interface/StyleDoorBuilder';
import { StyleSpecifications } from 'order/wizard/orderStyles/interface/StyleSpecifications';
import { StylesStepsEnum } from 'order/wizard/orderStyles/enums/StylesStepsEnum';
import { H5 } from 'shared/components/Typography';

import {
  IDoorCode,
  IDoorCodeField,
  IDoorCodeWithoutHardware,
} from 'order/wizard/orderStyles/interface/DoorCodes';

import {
  getDoorCodes,
  getMaterialDoorCodes,
  setDoorCodes,
} from '../../../store/doorBuilder/orderStylesDoorBuilderActions';
import { DoorElement } from './DoorElement';
import {
  Column,
  DoorUpchagesColumns,
  DoorUpchagesColumnsWrapper,
  DoorUpchargesContainer,
  LabelWrapper,
} from './DoorUpchargesStyled';

const drawerCodePrefixes = [
  'SL1',
  'SL2',
  'SL4',
  'SL5',
  'I-1',
  'I-2',
  'I-4',
  'I-5',
];

const DoorUpcharges = () => {
  const dispatch = useAppDispatch();

  const { orderId } = useParams<OrderPageParams>();

  const doorCodes = useSelector(
    (state: RootState) => state.orderStylesReducer.doorBuilderOptions.doorCodes
  );

  const storedStyle = useSelector(
    (state: RootState) => state.orderStylesReducer.style
  );

  const { materialGroup, grainDirection, materialColor } = useSelector(
    (state: RootState) =>
      state.orderStylesReducer.specifications || ({} as StyleSpecifications)
  );

  const isProductLineInovae2OOrRevolae = useSelector(
    (state: RootState) =>
      state.orderStylesReducer.productLine?.name ===
        ProductLineEnums.PRODUCT_LINE_INOVAE2O ||
      state.orderStylesReducer.productLine?.name ===
        ProductLineEnums.PRODUCT_LINE_REVOLAE
  );

  const isICSProductLine = useSelector(
    (state: RootState) =>
      state.orderStylesReducer.productLine?.name ===
      ProductLineEnums.PRODUCT_LINE_ICS
  );

  const [frameSizeChanged, setFrameSizeChanged] = useState(false);
  const [metalFinishChanged, setMetalFinishChanged] = useState(false);

  const [isDoorBuilderReset, setIsDoorBuilderReset] = useState(false);

  const initialSetup = {
    wall: [
      { code: 'Wall 1', quantity: 0, populated: false },
      { code: 'Wall 2', quantity: 0, populated: false },
      { code: 'Wall 3', quantity: 0, populated: false },
      { code: 'Wall 4', quantity: 0, populated: false },
      { code: 'Wall 5', quantity: 0, populated: false },
    ] as IDoorCodeField[],
    base: [
      { code: 'Base 1', quantity: 0, populated: false },
      { code: 'Base 2', quantity: 0, populated: false },
      { code: 'Base 3', quantity: 0, populated: false },
      { code: 'Base 4', quantity: 0, populated: false },
      { code: 'Base 5', quantity: 0, populated: false },
    ] as IDoorCodeField[],
    drawer: [
      { code: 'Drawer 1', quantity: 0, populated: false },
      { code: 'Drawer 2', quantity: 0, populated: false },
      { code: 'Drawer 3', quantity: 0, populated: false },
      { code: 'Drawer 4', quantity: 0, populated: false },
      { code: 'Drawer 5', quantity: 0, populated: false },
    ] as IDoorCodeField[],
  } as IDoorCodeWithoutHardware;

  const [upchargeValues, setUpchargeValues] =
    useState<IDoorCodeWithoutHardware | undefined>(initialSetup);

  const [upcargesLoading, setUpchargesLoading] = useState(false);

  // FORM CONTEXT
  const { formState, watch, unregister, setValue } =
    useFormContext<StyleDoorBuilder>();

  const doorOverlayWatched = watch('doorOverlay');
  const wallDoorStyleWatched = watch('doorStyleWall');
  const baseDoorStyleWatched = watch('doorStyleBase');
  const wallDoorConfigurationWatched = watch('configurationWall');
  const baseDoorConfigurationWatched = watch('configurationBase');
  const drawerFrontStyleWatched = watch('drawerStyle');
  const archStyleWatched = watch('archStyle');
  const materialDrawerWatched = watch('materialDrawer');

  const frameSizeWatched = watch('frameSize');
  const metalFinishWatched = watch('metalFinish');

  const archStyleOptions = useSelector(
    (state: RootState) =>
      state.orderStylesReducer.doorBuilderOptions.archStyleOptions
  );

  const unregisterDoorCodes = (
    doorUpchargeFields: (keyof StyleDoorBuilder)[]
  ) => {
    doorUpchargeFields.forEach((el) => {
      unregister(el);
    });
  };

  const loadDoorCodes = (style?: Style) => {
    setUpchargesLoading(true);

    dispatch(
      getDoorCodes(
        {
          archStyleId: style?.archStyle?.id ?? archStyleWatched?.value,
          baseDoorConfigurationId:
            style?.baseDoorConfiguration?.id ??
            baseDoorConfigurationWatched?.value,
          baseDoorStyleId:
            style?.baseDoorStyle?.id ?? baseDoorStyleWatched?.value,
          doorConfigurationId:
            style?.wallDoorConfiguration?.id ??
            wallDoorConfigurationWatched?.value,
          doorOverlayId: style?.doorOverlay?.id ?? doorOverlayWatched?.id,
          doorStyleId: style?.wallDoorStyle?.id ?? wallDoorStyleWatched?.value,
          drawerFrontStyleId:
            style?.drawerFrontStyle?.id ?? drawerFrontStyleWatched?.value,
          orderId,
          styleId: storedStyle?.id,
        },
        setUpchargesLoading
      )
    );
  };

  const loadMaterialDoorCodes = (style?: Style) => {
    setUpchargesLoading(true);
    dispatch(
      getMaterialDoorCodes(
        {
          doorOverlayId: style?.doorOverlay.id ?? doorOverlayWatched.id,
          wallDoorStyleId:
            style?.wallDoorStyle.id ?? wallDoorStyleWatched.value,
          baseDoorStyleId:
            style?.baseDoorStyle.id ?? baseDoorStyleWatched.value,
          materialDrawerId:
            style?.materialDrawer.id ?? materialDrawerWatched.value,
          materialGroupId: style?.materialGroup.id ?? materialGroup?.value,
          materialColorId: style?.materialColor.id ?? materialColor?.value,
          orderId,
          styleId: storedStyle?.id,
          grainDirectionId: grainDirection ? +grainDirection.value : undefined,
        },
        setUpchargesLoading
      )
    );
  };

  useEffect(() => {
    if (storedStyle) {
      setIsDoorBuilderReset(!storedStyle.doorOverlay?.id);
    }
  }, [storedStyle]);

  useEffect(() => {
    if (
      storedStyle &&
      !formState.isDirty &&
      (storedStyle.step ?? 0) > +StylesStepsEnum.DOOR_BUILDER
    ) {
      (isProductLineInovae2OOrRevolae ? loadMaterialDoorCodes : loadDoorCodes)(
        storedStyle
      );
    }
  }, [storedStyle]);

  // reset door codes when one of these fields changes
  useEffect(() => {
    if (
      doorCodes &&
      (formState.dirtyFields.doorOverlay ||
        formState.dirtyFields.doorStyleWall ||
        formState.dirtyFields.configurationWall ||
        formState.dirtyFields.configurationBase ||
        formState.dirtyFields.archStyle ||
        formState.dirtyFields.drawerStyle ||
        formState.dirtyFields.oneInchDoor)
    ) {
      dispatch(setDoorCodes(null));

      setUpchargeValues({ ...initialSetup });

      unregisterDoorCodes([
        'wallDoorUpcharges',
        'baseDoorUpcharges',
        'drawerUpcharges',
      ]);
    }
  }, [
    doorOverlayWatched,
    wallDoorStyleWatched,
    wallDoorConfigurationWatched,
    baseDoorConfigurationWatched,
    archStyleWatched,
    drawerFrontStyleWatched,
  ]);

  const getCodeUpcharge: CodeFunction = ({
    value,
    fs,
    frameSizeHasUpchage,
    metalFinishHasUpchage,
    hasQuantity,
    hideUpcharge,
  }) => {
    if (hideUpcharge) {
      return !fs.isDirty ? (value as unknown as string) : '';
    }

    // when form loads - load saved data
    if (!fs.isDirty) {
      return value as unknown as string;
    }

    // is dirty but not finish or frame
    if (
      fs.isDirty &&
      isDoorBuilderReset &&
      !frameSizeHasUpchage &&
      !metalFinishHasUpchage
    ) {
      return value as unknown as string;
    }
    // is dirty but finish or frame size

    if (
      (fs.isDirty || frameSizeChanged || metalFinishChanged) &&
      hasQuantity &&
      (frameSizeHasUpchage || metalFinishHasUpchage)
    ) {
      return (
        (frameSizeHasUpchage ? frameSizeWatched : metalFinishWatched)
          .upcharge ?? ''
      ).toString();
    }

    return '';
  };

  const getCodeReason: CodeFunction = ({
    value,
    fs,
    frameSizeHasUpchage,
    metalFinishHasUpchage,
    hasQuantity,
    hideUpcharge,
  }) => {
    if (hideUpcharge) {
      return !fs.isDirty ? (value as unknown as string) : '';
    }

    // when frame size is dirty and frame size has upcharge
    if (
      hasQuantity &&
      ((frameSizeChanged && frameSizeHasUpchage) ||
        (metalFinishChanged && metalFinishHasUpchage))
    ) {
      return frameSizeHasUpchage ? 'Door Frame' : 'Metal Finish';
    }

    // when form loads - load saved data
    if (!fs.isDirty) {
      return value as unknown as string;
    }

    if (
      fs.isDirty &&
      isDoorBuilderReset &&
      !frameSizeHasUpchage &&
      !metalFinishHasUpchage
    ) {
      return value as unknown as string;
    }

    if (
      fs.isDirty &&
      hasQuantity &&
      (frameSizeHasUpchage || metalFinishHasUpchage)
    ) {
      return frameSizeHasUpchage ? 'Door Frame' : 'Metal Finish';
    }

    return '';
  };

  const getCodeIsAutomatic: CodeFunction = ({
    value,
    fs,
    frameSizeHasUpchage,
    metalFinishHasUpchage,
    hideUpcharge,
  }) => {
    if (hideUpcharge) {
      return false;
    }

    // when frame size is dirty and frame size has upcharge
    if (
      (frameSizeChanged && frameSizeHasUpchage) ||
      (metalFinishChanged && metalFinishHasUpchage)
    ) {
      return true;
    }

    // when form loads - load saved data
    if (!fs.isDirty) {
      return (
        (frameSizeHasUpchage ||
          metalFinishHasUpchage ||
          (value as unknown as boolean)) ??
        false
      );
    }

    if (
      fs.isDirty &&
      isDoorBuilderReset &&
      !frameSizeHasUpchage &&
      !metalFinishHasUpchage
    ) {
      return (value as unknown as boolean) ?? false;
    }

    if (fs.isDirty && (frameSizeHasUpchage || metalFinishHasUpchage)) {
      return true;
    }

    return false;
  };

  const findFieldBasedOnIds = (
    storedFields: IDoorCodeField[],
    loadedField: IDoorCodeField
  ) => {
    return storedFields.find((storedField) => {
      const materialDrawerCheck =
        (storedField.materialDrawerId ?? true) ===
        (loadedField.materialDrawerId ?? true);

      const materialGroupCheck =
        (storedField.materialGroupId ?? true) ===
        (loadedField.materialGroupId ?? true);

      const grainDirectionCheck =
        (storedField.grainDirectionId ?? true) ===
        (loadedField.grainDirectionId ?? true);

      const doorConfigurationCheck =
        (storedField.doorConfigurationId ?? true) ===
        (loadedField.doorConfigurationId ?? true);

      const drawerFrontStyleCheck =
        storedField.drawerFrontStyleId === zeroGuid
          ? true
          : (storedField.drawerFrontStyleId ?? true) ===
            (loadedField.drawerFrontStyleId ?? true);

      const doorOverlayCheck =
        (storedField.doorOverlayId ?? true) ===
        (loadedField.doorOverlayId ?? true);

      const doorStyleCheck =
        (storedField.doorStyleId ?? true) === (loadedField.doorStyleId ?? true);

      return (
        materialDrawerCheck &&
        materialGroupCheck &&
        grainDirectionCheck &&
        doorConfigurationCheck &&
        drawerFrontStyleCheck &&
        doorOverlayCheck &&
        doorStyleCheck
      );
    });
  };

  const populateDoorUpcharges = (
    doorCodesLoaded: IDoorCode | IDoorCodeWithoutHardware,
    populateField: boolean = true
  ) => {
    const updatedDoorCodes = { ...initialSetup } as IDoorCodeWithoutHardware;
    const frameSizeHasUpchage = (frameSizeWatched?.upcharge ?? 0) > 0;
    const metalFinishHasUpchage = (metalFinishWatched?.upcharge ?? 0) > 0;

    Object.keys(doorCodesLoaded).forEach((key) => {
      const k = key as keyof IDoorCodeWithoutHardware;
      const value = doorCodesLoaded[k];

      value.forEach((loadedField, index) => {
        if (!updatedDoorCodes[k][index]) return;
        const codeFallback =
          loadedField.code?.trim() ?? updatedDoorCodes[k][index].code;

        updatedDoorCodes[k][index].code = codeFallback;

        updatedDoorCodes[k][index].quantity = loadedField.quantity;

        updatedDoorCodes[k][index].populated = populateField;

        // code field key
        const codesFieldsKey =
          k === 'drawer'
            ? (`${k}Upcharges` as keyof Style)
            : (`${k}DoorUpcharges` as keyof Style);

        const field = findFieldBasedOnIds(
          storedStyle![codesFieldsKey] as IDoorCodeField[],
          loadedField
        );

        const hideUpcharge =
          k === 'drawer' &&
          drawerCodePrefixes.some((d) => loadedField.code?.trim() === d);

        // upcharge
        const upcharge = (field?.upcharge || '').toString();

        // upcharge reason
        const reason = field?.reason ?? '';

        // is fields are populated automatically
        const isAutomatic = field?.isAutomatic ?? false;

        const hasQuantity = (loadedField.quantity ?? 0) > 0;

        updatedDoorCodes[k][index].isAutomatic =
          hasQuantity || frameSizeHasUpchage || metalFinishHasUpchage
            ? (getCodeIsAutomatic({
                value: isAutomatic,
                frameSizeHasUpchage,
                metalFinishHasUpchage,
                fs: formState,
                hideUpcharge,
              }) as boolean)
            : false;

        setValue(
          `${codesFieldsKey}.${codeFallback}.upcharge` as unknown as keyof StyleDoorBuilder,
          getCodeUpcharge({
            value: upcharge,
            frameSizeHasUpchage,
            metalFinishHasUpchage,
            fs: formState,
            hasQuantity,
            hideUpcharge,
          })
        );

        setValue(
          `${codesFieldsKey}.${codeFallback}.reason` as unknown as keyof StyleDoorBuilder,
          getCodeReason({
            value: reason,
            frameSizeHasUpchage,
            metalFinishHasUpchage,
            fs: formState,
            hasQuantity,
            hideUpcharge,
          })
        );

        setValue(
          `${codesFieldsKey}.${codeFallback}.isAutomatic` as unknown as keyof StyleDoorBuilder,
          hasQuantity || frameSizeHasUpchage || metalFinishHasUpchage
            ? getCodeIsAutomatic({
                value: isAutomatic,
                frameSizeHasUpchage,
                metalFinishHasUpchage,
                fs: formState,
              })
            : false
        );
      });
    });

    setUpchargeValues(updatedDoorCodes);
  };

  useEffect(() => {
    if (!doorCodes && storedStyle && isDoorBuilderReset) {
      const preparedDoorCodes = {
        wall: storedStyle.wallDoorUpcharges,
        base: storedStyle.baseDoorUpcharges,
        drawer: storedStyle.drawerUpcharges,
      } as IDoorCodeWithoutHardware;

      populateDoorUpcharges(preparedDoorCodes, false);
    }
  }, [storedStyle, isDoorBuilderReset]);

  useEffect(() => {
    if (doorCodes && storedStyle) {
      populateDoorUpcharges(doorCodes);
    }
  }, [doorCodes, storedStyle, frameSizeWatched, metalFinishWatched]);

  useEffect(() => {
    if (formState.dirtyFields.frameSize) {
      setFrameSizeChanged(true);
    }
  }, [frameSizeWatched]);

  useEffect(() => {
    if (formState.dirtyFields.metalFinish) {
      setMetalFinishChanged(true);
    }
  }, [metalFinishWatched]);

  // non inovae2.0
  useEffect(() => {
    if (
      !isProductLineInovae2OOrRevolae &&
      ((archStyleOptions?.length ?? 0) > 0 ? archStyleWatched : true) &&
      baseDoorConfigurationWatched &&
      baseDoorStyleWatched &&
      doorOverlayWatched &&
      drawerFrontStyleWatched &&
      wallDoorConfigurationWatched &&
      wallDoorStyleWatched &&
      (formState.isDirty || isICSProductLine)
    ) {
      loadDoorCodes();
    }
  }, [
    isProductLineInovae2OOrRevolae,
    drawerFrontStyleWatched,
    archStyleOptions,
    archStyleWatched,
    drawerFrontStyleWatched,
  ]);

  useEffect(() => {
    if (
      isProductLineInovae2OOrRevolae &&
      doorOverlayWatched &&
      wallDoorStyleWatched &&
      baseDoorStyleWatched &&
      materialDrawerWatched
    ) {
      loadMaterialDoorCodes();
    }
  }, [isProductLineInovae2OOrRevolae, materialDrawerWatched]);

  return upchargeValues ? (
    <DoorUpchargesContainer>
      <FormLabel>
        <LabelWrapper flex middle>
          <H5>Door Upcharges</H5>
          <Spacer w="15px" />
          {upcargesLoading && <Loader size={16} noSpacing />}
        </LabelWrapper>
      </FormLabel>
      <Spacer h="32px" />
      <DoorUpchagesColumnsWrapper>
        <DoorUpchagesColumns flex>
          <Spacer w="100px" />
          <Column maxWidth={80}>Qty</Column>
          <Column maxWidth={112}>Upcharge $</Column>
          <Spacer w="16px" />
          <Column>Upcharge Reason</Column>
        </DoorUpchagesColumns>
        <Divider />
        <Spacer h="16px" />
        <DoorElement
          upchargeValues={upchargeValues.wall}
          elementName="Wall"
          inputRegisterName="wallDoorUpcharges"
        />
        <DoorElement
          upchargeValues={upchargeValues.base}
          elementName="Base"
          inputRegisterName="baseDoorUpcharges"
        />
        <DoorElement
          upchargeValues={upchargeValues.drawer}
          elementName="Drawer"
          inputRegisterName="drawerUpcharges"
        />
      </DoorUpchagesColumnsWrapper>
    </DoorUpchargesContainer>
  ) : (
    <Loader size={40} />
  );
};

export default DoorUpcharges;
