import styled from 'styled-components';
import { RootState } from 'store';
import { useSelector } from 'react-redux';
import { useState, FC, useEffect, useContext } from 'react';

import { OrderFormContext } from 'order/wizard/orderForm/OrderFormContext/orderFormContext';
import {
  getShippingAddress,
  setNonExistingShippingAddress,
  setShippingAddress,
} from 'order/store/orderActions';
import AddressDetails from 'order/components/AddressDetails/AddressDetails';

import { CustomAddressForm } from 'overview/dealership/components/CustomAddressForm';
import { dealershipActions } from 'overview/dealership/store/dealershipActions';

import { FormLabel } from 'shared/components/FormLabel';
import { Modal } from 'shared/components/Modal';
import { ModalWrapper } from 'shared/components/ModalWrapper';
import { Select } from 'shared/components/Select';
import { SelectOptionProps } from 'shared/interface/SelectOptionProps';
import { ShippingAddress } from 'shared/interface/ShippingAddress';
import { useAppDispatch } from 'shared/hooks/useAppDispatch';
import UtilService from 'shared/services/util.service';
import { ButtonPrimary } from 'shared/components/Button';
import { Wrapper } from 'shared/components/Wrapper';

interface OrderCustomAddressProps {
  existingAddressChange: (addressId: string | null) => void;
}

const DefaultAddressContainer = styled.div``;

const EditButton = styled(ButtonPrimary)`
  height: 15px;
`;

const OrderCustomAddress: FC<OrderCustomAddressProps> = ({
  existingAddressChange,
}) => {
  const dispatch = useAppDispatch();

  const { dealershipId, handleChangeCallbackWrapper } =
    useContext(OrderFormContext);

  const [customAddressModalOpened, setCustomAddressModalOpened] =
    useState(false);

  const order = useSelector((state: RootState) => state.orderReducer.order);

  const selectedShippingAddress = useSelector(
    (state: RootState) => state.orderReducer.selectedShippingAddress
  );

  const canEdit = useSelector((state: RootState) => state.orderReducer.canEdit);

  // pull out addresses from redux store
  const customAddresses = useSelector(
    (state: RootState) => state.dealershipReducer.customAddresses
  );

  // selected address object
  const selectedNonExistingAddress = useSelector(
    (state: RootState) => state.orderReducer.nonExistingAddress
  );

  // selected existing address object
  const [selectedExistingAddress, setSelectedExistingAddress] =
    useState<ShippingAddress | null>(null);

  // address options
  const [addressOptions, setAddressOptions] =
    useState<SelectOptionProps[] | null>(null);

  // selected address option
  const [selectedAddressOption, setSelectedAddressOption] =
    useState<SelectOptionProps | null>(null);

  const [customAddressesLoading, setCustomAddressesLoading] = useState(false);

  const [editMode, setEditMode] = useState(false);

  // change address option handler
  const onDefaultAddressChangeHandler = (val: SelectOptionProps) => {
    setSelectedAddressOption(val);
    setEditMode(false);

    // not clear
    if (val) {
      const foundAddress = customAddresses?.find(
        (address) => address.id === val.value
      );

      setSelectedExistingAddress(foundAddress || null);

      // emit selected existing address id
      existingAddressChange(foundAddress ? foundAddress.id : null);

      setCustomAddressModalOpened(val?.value === 'new-address');
    } else {
      // clear select
      setSelectedExistingAddress(null);
      existingAddressChange(null);
      dispatch(setNonExistingShippingAddress(null));
    }
  };

  const onAfterCancelModalHandler = () => {
    if (!editMode) {
      setSelectedAddressOption(null);
    }
    setCustomAddressModalOpened(false);
  };

  const loadDealershipAddresses = () => {
    setCustomAddressesLoading(true);

    dispatch({
      type: dealershipActions.GET_DEALERSHIP_CUSTOM_ADDRESSES,
      payload: dealershipId,
      loading: setCustomAddressesLoading,
    });
  };

  const onAfterSubmitModalHandler = (address: ShippingAddress) => {
    setCustomAddressModalOpened(false);

    setSelectedAddressOption({
      ...selectedAddressOption!,
      label: address.shipTo,
    });

    dispatch(setNonExistingShippingAddress(address));
  };

  const onCloseModal = (close: () => void) => {
    close();
    setCustomAddressModalOpened(false);
    if (!editMode) {
      setSelectedAddressOption(null);
    }
  };

  const reloadAddresses = (close: () => void) => {
    close();
    setCustomAddressModalOpened(false);
    dispatch(setShippingAddress(null));
    dispatch(getShippingAddress(order?.id ?? ''));
  };

  useEffect(() => {
    if (dealershipId) {
      setTimeout(() => {
        loadDealershipAddresses();
      }, 1500);
    }
  }, [dealershipId]);

  // populate select with options
  useEffect(() => {
    if (customAddresses) {
      const c: SelectOptionProps[] = UtilService.mapAddresstoSelectOption(
        customAddresses,
        true
      ) as SelectOptionProps[];
      const addNewAddressOption: SelectOptionProps = {
        value: 'new-address',
        label: '+ Add new address',
      };

      setAddressOptions([addNewAddressOption, ...c]);
    }
  }, [customAddresses]);

  useEffect(() => {
    if (addressOptions && selectedShippingAddress) {
      const foundAddressOption = addressOptions.find(
        (addressOption) =>
          addressOption.value === selectedShippingAddress.originalAddressId
      );

      const foundCustomAddress = customAddresses?.find(
        (customAddress) =>
          customAddress.id === selectedShippingAddress.originalAddressId
      );

      if (selectedShippingAddress.originalAddressId) {
        setSelectedAddressOption(foundAddressOption || null);
        setSelectedExistingAddress(foundCustomAddress || null);
      } else {
        setSelectedAddressOption({
          ...selectedAddressOption!,
          label: selectedShippingAddress.shipTo,
        });

        dispatch(setNonExistingShippingAddress(selectedShippingAddress));
      }
    }
  }, [addressOptions, selectedShippingAddress]);

  useEffect(() => {
    return () => {
      dispatch({
        type: dealershipActions.SET_DEALERSHIP_CUSTOM_ADDRESSES,
        payload: null,
      });
    };
  }, []);

  const handleEditAddress = () => {
    setEditMode(true);
    setCustomAddressModalOpened(true);
  };

  return (
    <DefaultAddressContainer>
      <FormLabel>Select an address</FormLabel>

      <Select
        value={selectedAddressOption}
        placeholder={
          customAddressesLoading
            ? 'Loading custom addresses...'
            : 'Select or add custom shipping address'
        }
        isClearable
        options={addressOptions}
        isDisabled={customAddressesLoading || !canEdit}
        onChange={(val: SelectOptionProps) =>
          handleChangeCallbackWrapper?.(() =>
            onDefaultAddressChangeHandler(val)
          )
        }
      />

      {(selectedNonExistingAddress || selectedExistingAddress) && (
        <Wrapper flex>
          <AddressDetails
            address={
              selectedNonExistingAddress
                ? selectedNonExistingAddress!
                : selectedExistingAddress!
            }
          />
          {selectedShippingAddress &&
            (selectedShippingAddress?.originalAddressId ===
              selectedExistingAddress?.id ||
              selectedNonExistingAddress?.originalAddressId ===
                selectedShippingAddress?.originalAddressId) && (
              <Wrapper mlAuto flex middle>
                <EditButton
                  type="button"
                  disabled={!canEdit}
                  onClick={handleEditAddress}
                >
                  Edit
                </EditButton>
              </Wrapper>
            )}
        </Wrapper>
      )}

      <ModalWrapper
        modal
        lockScroll
        closeOnDocumentClick={false}
        open={customAddressModalOpened}
        closeOnEscape={false}
      >
        {(close: () => void) => (
          <Modal
            title={editMode ? 'Edit address' : 'Add address'}
            close={() => onCloseModal(close)}
            withoutFooter={1}
          >
            <CustomAddressForm
              onClose={editMode ? () => reloadAddresses(close) : close}
              afterSubmit={onAfterSubmitModalHandler}
              dontCallApi={!editMode}
              afterCancel={onAfterCancelModalHandler}
              data={
                editMode && selectedShippingAddress
                  ? selectedShippingAddress!
                  : undefined
              }
              editMode={editMode}
              orderId={order?.id}
            />
          </Modal>
        )}
      </ModalWrapper>
    </DefaultAddressContainer>
  );
};

export default OrderCustomAddress;
