import { FC } from 'react';
import { formatDistance } from 'date-fns';

import { ICurtainTopic } from 'curtain/interfaces/ICurtainTopic';

import { PSmall } from 'shared/components/Typography';

interface TopicMetaProps {
  topic: ICurtainTopic;
}

export const TopicMeta: FC<TopicMetaProps> = ({ topic }) => {
  return (
    <PSmall>
      {[
        topic.participants
          .map(({ firstName, lastName }) => `${firstName} ${lastName}`)
          .join(', '),
        formatDistance(
          new Date(topic.lastCommentUtc ?? topic.createdOnUtc),
          new Date(),
          {
            addSuffix: true,
          }
        ),
      ].join(`, `)}
      {topic.private && ' (Private)'}
    </PSmall>
  );
};
