import { Controller, useFormContext } from 'react-hook-form';
import { RootState } from 'store';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { OrderFormFields } from 'order/wizard/orderForm/OrderForm';

import { FormLabel } from 'shared/components/FormLabel';
import { getShipViaOptions } from 'order/store/orderActions';
import { Select } from 'shared/components/Select';
import { SelectOptionProps } from 'shared/interface/SelectOptionProps';
import { Spacer } from 'shared/components/Layout';
import { useAppDispatch } from 'shared/hooks/useAppDispatch';
import { Wrapper } from 'shared/components/Wrapper';
import FormError from 'shared/components/FormError';

const ShipVia = () => {
  const methodsContext = useFormContext<OrderFormFields>();

  const dispatch = useAppDispatch();

  const [loading, setLoading] = useState(false);

  const canEdit = useSelector((state: RootState) => state.orderReducer.canEdit);

  const [shipViaOptions, setShipViaOptions] =
    useState<SelectOptionProps[] | null>(null);

  const getShipViaOptionsSuccessHandler = (options: SelectOptionProps[]) => {
    setShipViaOptions(options);
  };

  useEffect(() => {
    setLoading(true);

    dispatch(getShipViaOptions(getShipViaOptionsSuccessHandler, setLoading));

    return () => {
      methodsContext.setValue('shipOptionId', '');
    };
  }, []);

  return (
    <Wrapper>
      <Spacer h="30px" />

      <FormLabel>Ship Via</FormLabel>

      <Controller
        control={methodsContext.control}
        name="shipOptionId"
        rules={{ required: true }}
        render={({ field: { onChange, value, ref } }) => (
          <Select
            ref={ref}
            placeholder="Select Ship Via option..."
            aria-invalid={
              methodsContext.formState.errors.shipOptionId ? 'true' : 'false'
            }
            isClearable
            options={shipViaOptions}
            value={shipViaOptions?.find(
              (option: SelectOptionProps) => option.value === value
            )}
            onChange={(val: SelectOptionProps) => onChange(val?.value ?? '')}
            isDisabled={loading || !shipViaOptions?.length || !canEdit}
          />
        )}
      />

      <FormError
        label="Ship Via"
        error={methodsContext.formState.errors.shipOptionId}
        validationSchema={{ required: true }}
      />
    </Wrapper>
  );
};

export default ShipVia;
