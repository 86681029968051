import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useParams } from 'react-router';
import styled from 'styled-components';

import { ReactComponent as BellNotifyIcon } from 'assets/icons/bell-notify.svg';

import { OrderPageParams } from 'order/interfaces/OrderPageParams';

import { NotifyEntity } from 'curtain/interfaces/NotifyEntity';

import {
  getNotSentTopics,
  notifyCollaborators,
} from 'curtain/store/curtainActions';

import EmptyState from 'shared/components/EmptyState';
import Loader, { LoaderFullScreen } from 'shared/components/Loader';
import SVG from 'shared/components/SVG';
import ScrollbarsCustom from 'shared/components/ScrollbarsCustom';
import UtilService from 'shared/services/util.service';
import { ButtonIcon } from 'shared/components/Button';
import { ConfirmationModal } from 'shared/components/ConfirmationModal';
import { P } from 'shared/components/Typography';
import { ServerErrorResponse } from 'shared/interface/serverResponses/ServerErrorResponse';
import { Spacer } from 'shared/components/Layout';
import { Wrapper } from 'shared/components/Wrapper';
import { lynch } from 'shared/config/Colors';
import { useAppDispatch } from 'shared/hooks/useAppDispatch';

import { Tooltip } from '../../../shared/components/Tooltip';

const CurtainNotifyCollaboratorsContainer = styled.div``;

const TopicHeader = styled(P)`
  margin-bottom: 16px;
`;

const Topic = styled(P)`
  margin-bottom: 16px;
  margin-left: 20px;
`;

const CurtainNotifyCollaborators = () => {
  const dispatch = useAppDispatch();
  const { orderId } = useParams<OrderPageParams>();

  const [notifyCollabModalOpened, setNotifyCollabModalOpened] = useState(false);

  const [notifyEntitiesLoading, setNotifyEntitiesLoading] = useState(false);

  const [notifyEntities, setNotifyEntities] =
    useState<NotifyEntity[] | null>(null);

  const onLoadNotSentTopicsSuccessHandler = (entities: NotifyEntity[]) => {
    setNotifyEntities(entities);
    setNotifyEntitiesLoading(false);
  };

  const onLoadNotSentTopicsHandler = () => {
    if (!orderId) return;

    setNotifyEntitiesLoading(true);

    dispatch(
      getNotSentTopics(
        orderId,
        onLoadNotSentTopicsSuccessHandler,
        setNotifyEntitiesLoading
      )
    );
  };

  const onNotifyCollaboratorsSuccessHandler = () => {
    setNotifyCollabModalOpened(false);

    toast.success(
      'Email has been successfully sent to the collaborators on this order.'
    );
  };

  const onNotifyCollaboratorsFailedHandler = (err: ServerErrorResponse) => {
    setNotifyCollabModalOpened(false);

    UtilService.handleError({
      err,
    });
  };

  const onNotifyCollabsClickHandler = () => {
    dispatch(
      notifyCollaborators(
        orderId,
        onNotifyCollaboratorsSuccessHandler,
        onNotifyCollaboratorsFailedHandler
      )
    );
  };

  useEffect(() => {
    if (notifyCollabModalOpened) {
      onLoadNotSentTopicsHandler();
    }

    return () => {
      setNotifyEntities(null);
    };
  }, [notifyCollabModalOpened]);

  return (
    <CurtainNotifyCollaboratorsContainer>
      <ButtonIcon onClick={() => setNotifyCollabModalOpened(true)}>
        <Wrapper withTooltip>
          <SVG icon={<BellNotifyIcon />} hoverColor={lynch} />
          <Tooltip position="left" maxWidth={200} minWidth={200}>
            Notify collaborators about the new open topics
          </Tooltip>
        </Wrapper>
      </ButtonIcon>

      <ConfirmationModal
        title="Notify Collaborators"
        opened={notifyCollabModalOpened}
        buttonText="Send Email Notification"
        confirm={onNotifyCollabsClickHandler}
        cancel={() => setNotifyCollabModalOpened(false)}
        bottomSpacing={20}
        buttonDisabled={notifyEntities ? notifyEntities.length === 0 : true}
      >
        <P>Collaborators will be notified with the following topics</P>

        <Spacer h="32px" />

        <Wrapper position="relative">
          <ScrollbarsCustom autoHeight autoHeightMin={190} autoHeightMax={190}>
            {notifyEntities === null && notifyEntitiesLoading && (
              <LoaderFullScreen flex middle center>
                <Loader size={50} />
              </LoaderFullScreen>
            )}

            {!notifyEntitiesLoading &&
              notifyEntities &&
              notifyEntities.length === 0 && (
                <EmptyState
                  title="No Topics"
                  message="There are no unnotified topics in this moment."
                />
              )}

            {!notifyEntitiesLoading &&
              notifyEntities?.map((entity) => (
                <Wrapper key={entity.channelId}>
                  <TopicHeader fontSize={14} fontWeight={600}>
                    {entity.channelName || 'Other'}
                  </TopicHeader>

                  {entity.notSentTopicResponses.map((topic) => (
                    <Topic
                      wordBreak="break-word"
                      key={`${entity.channelId}--${topic.topicId}`}
                      fontSize={14}
                    >
                      {topic.topicName}
                    </Topic>
                  ))}
                </Wrapper>
              ))}
          </ScrollbarsCustom>
        </Wrapper>
      </ConfirmationModal>
    </CurtainNotifyCollaboratorsContainer>
  );
};

export default CurtainNotifyCollaborators;
