import { FC, SetStateAction, useEffect, useState } from 'react';
import { RootState } from 'store';
import { toast } from 'react-toastify';
import { uniqueId } from 'lodash';
import { useHistory, useParams } from 'react-router';
import { useSelector } from 'react-redux';
import Scrollbars, { positionValues } from 'react-custom-scrollbars';
import styled from 'styled-components';

import { ReactComponent as TagIcon } from 'assets/icons/tag.svg';
import { ReactComponent as IconSearch } from 'assets/icons/search.svg';
import { ReactComponent as ArrowRightIcon } from 'assets/icons/arrow-right.svg';

import {
  GradingOption,
  OrderStatusEnums,
  OrderStylizationTypeEnums,
  OrderTypeEnums,
} from 'order/enums/orderEnums';

import SyncToolFailure from 'order/components/OrderHeader/SyncToolFailure/SyncToolFailure';
import { ImportStylesModalHeaderEnums } from 'order/wizard/orderStyles/enums/ImportStylesModalEnum';
import { ImportStylesModalStepsEnums } from 'order/wizard/import/enums/ImportStylesModalStepsEnums';
import { TableTabsType } from 'order/interfaces/OrderTable';
import { deleteOrder } from 'order/store/orderActions';
import { setImportStylesModalMeta } from 'order/wizard/import/store/ImportActions';

import {
  OrderFilterValues,
  OrderFilterValuesRipped,
} from 'order/interfaces/OrderFilterValues';

import {
  SortDirection,
  TableColumns,
  TableTabsEnums,
} from 'order/enums/orderTableEnums';

import { orderCancelRequest } from 'overview/orders/store/ordersActions';

import AvatarCircle from 'shared/components/AvatarCircle';
import ElipsisText from 'shared/components/ElipsisText';
import EmptyState from 'shared/components/EmptyState';
import Flash from 'shared/components/Flash';
import Loader from 'shared/components/Loader';
import SVG from 'shared/components/SVG';
import ScrollbarsCustom from 'shared/components/ScrollbarsCustom';
import ThreeDotMenu from 'shared/components/ThreeDotMenu';
import UtilService from 'shared/services/util.service';
import { BaseField } from 'shared/interface/BaseField';
import { ButtonIcon } from 'shared/components/Button';
import { ConfirmationModal } from 'shared/components/ConfirmationModal';
import { CsrTabsEnum } from 'shared/enum/CsrTabsEnum';
import { OrderItem } from 'shared/interface/OrderRow';
import { OrderMetrics } from 'shared/interface/OrderMetrics';
import { PaginatedItems } from 'shared/interface/PaginatedItems';
import { PriorityBadge } from 'shared/components/PriorityBadge';
import { ServerErrorResponse } from 'shared/interface/serverResponses/ServerErrorResponse';
import { SkeletonItem } from 'shared/components/SkeletonGrid';
import { Spacer } from 'shared/components/Layout';
import { Table, Thead, Tr, Th, Tbody, Td } from 'shared/components/Table';
import { Tooltip } from 'shared/components/Tooltip';
import { Wrapper } from 'shared/components/Wrapper';
import { useAppDispatch } from 'shared/hooks/useAppDispatch';
import { useHasPermissions } from 'shared/hooks/useHasPermissions';
import { userPermissionsValues } from 'shared/enum/userPermissionsEnum';

import {
  silverChalice,
  silverChaliceTwo,
  valencia,
} from 'shared/config/Colors';

import { NewCollaborationBadge } from 'shared/components/NewCollaborationBadge';
import { ScheduleType } from 'order/enums/scheduleType';
import { ImportLineItemsFlow } from '../../order/wizard/orderStyles/enums/ImportLineItemsFlow';
import { Order } from '../../order/interfaces/Order';
import { OrderPageParams } from '../../order/interfaces/OrderPageParams';

const ScrollTable = styled(Table)`
  max-height: 400px;
  height: auto !important;
  scroll-behavior: auto;

  > thead {
    background-color: ${({ theme }) => theme.white};
    position: sticky;
    top: 12px;
    z-index: 5;

    &::before {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      top: -12px;
      height: 12px;
      background-color: ${({ theme }) => theme.white};
    }
  }
`;

const TheadStyled = styled(Thead)<{ scrolledFromTop: boolean }>`
  transition: box-shadow 300ms ease;

  ${({ scrolledFromTop, theme }) =>
    scrolledFromTop && `box-shadow: -1px 2px 3px 0 ${theme.alto}`};
`;

const FixedTr = styled(Tr)`
  position: sticky;
  bottom: 0;
  background-color: ${({ theme }) => theme.white};

  > ${Td} {
    padding-bottom: 5px;
  }
`;

const TagWrapper = styled.div`
  margin-right: 25px;

  > .icon {
    display: block;
  }
`;

interface StyledTrProps {
  onClick?: (orderId: string) => void;
}

const StyledTr = styled(Tr)<StyledTrProps>`
  ${({ onClick }) => onClick && 'cursor: pointer'};

  &.highlight {
    background: #e3aa5333;
    border-radius: 16px 16px 0px 0px;
  }

  > ${Td} {
    height: 48px;
  }
`;

const RelativeTd = styled(Td)`
  position: relative;
`;

export const AssigneeWrapper = styled.div<{ index: number }>`
  cursor: pointer;
  left: ${(p) => p.index * 15}px;
  position: absolute;
  top: 15px;
  z-index: ${(p) => p.index};

  &:hover {
    z-index: ${(p) => p.index + 2};

    ${Tooltip} {
      opacity: 1;
    }
  }
`;

const ItalicTd = styled(Td)`
  font-style: italic;
  color: ${({ theme }) => theme.silverSandTwo};
`;

export interface OrdersTableProps {
  tab?: TableTabsType;
  isLoading?: boolean;
  setSearch: (value: OrderFilterValues | OrderFilterValuesRipped) => void;
  search: OrderFilterValues | OrderFilterValuesRipped;
  isQuoteBoard?: boolean;
}

const OrdersTable: FC<OrdersTableProps> = ({
  tab,
  isLoading,
  setSearch,
  search,
  isQuoteBoard,
}: OrdersTableProps) => {
  const history = useHistory();
  const dispatch = useAppDispatch();

  const { orderId: orderIdFromUrl } = useParams<OrderPageParams>();

  const [tableDivRef, setTableDivRef] = useState<Scrollbars | null>(null);
  const [scrollInProgress, setScrollInProgress] = useState(false);
  const [scrolledFromTop, setScrolledFromTop] = useState(false);
  const [orderToDeleteId, setOrderToDeleteId] = useState('');
  const [sortDirty, setSortDirty] = useState(false);
  const [orderToDeleteName, setOrderToDeleteName] = useState('');
  const [orderToDeleteSerialNumber, setOrderToDeleteSerialNumber] =
    useState('');

  const orderTable = useSelector(
    (state: RootState) =>
      (isQuoteBoard
        ? state.quotesReducer.orderTable
        : state.ordersReducer.orderTable) as PaginatedItems<OrderItem> & {
        metrics: OrderMetrics;
      }
  );

  const currentOrder = useSelector(
    (state: RootState) => state.orderReducer.order
  );

  const { importLineItemsFlow } = useSelector(
    (state: RootState) => state.importStylesReducer
  );

  const isUserCSR = useHasPermissions(
    userPermissionsValues.CSR_VIEW_ORDERBOARD
  );

  const isUserSalesRep = useHasPermissions(
    userPermissionsValues.SALES_REPRESENTATIVE_VIEW_ORDER_BOARD
  );

  const orderCanRequestCancellation = useHasPermissions(
    userPermissionsValues.ORDER_CAN_REQUEST_CANCELLATION
  );

  const canDeleteOrder = useHasPermissions(userPermissionsValues.ORDER_DELETE);

  const isImportStyle = tab === TableTabsEnums.IMPORT_STYLE;

  const fontSize = 12;
  const tdFontSize = 14;

  const [sortColumn, setSortColumn] = useState(
    search.sortColumn as TableColumns
  );

  const [sortDir, setSortDir] = useState(search.sortDirection);

  const handleRowClick = (orderID: string | undefined) => {
    if (orderID != null) {
      history.push(`/order/${orderID}/overview`, {
        backToTab: (search as OrderFilterValues).tab,
        backToQuotesBoard: isQuoteBoard,
      });

      window.scrollTo(0, 0);
    }
  };

  useEffect(() => {
    if (search.page === 1 && tableDivRef) {
      tableDivRef.scrollTop(0);
    }

    if (search) {
      setSortColumn(search.sortColumn as TableColumns);
      setSortDir(search.sortDirection);
    }
  }, [search]);

  const renderSkeleton = () => {
    return Array.from({ length: 10 }, () => (
      <Tr key={uniqueId()}>
        {Array.from({ length: isUserCSR || isUserSalesRep ? 8 : 10 }, () => (
          <Td key={uniqueId()}>
            <SkeletonItem padding={10} borderRadius={5} h={5} w={100} />
          </Td>
        ))}
      </Tr>
    ));
  };

  const onSortHandle = (columnName: SetStateAction<TableColumns>) => {
    setSortDirty(true);
    setSortColumn(columnName);

    if (columnName === sortColumn && sortDir === SortDirection.ASC) {
      setSortDir(SortDirection.DESC);
    } else {
      setSortDir(SortDirection.ASC);
    }
  };

  useEffect(() => {
    if (!sortDirty) return;

    const params = isImportStyle
      ? ({
          order: search.order,
          sortDirection: sortDir,
          sortColumn,
          excludeOrdersWithoutStyle: true,
          page: 1,
          orderType: search.orderType,
        } as OrderFilterValuesRipped)
      : ({
          ...search,
          sortDirection: sortDir,
          sortColumn,
          page: 1,
        } as OrderFilterValues);

    setSearch(params);
  }, [sortColumn, sortDir]);

  const handleUpdate = (values: positionValues) => {
    const { scrollHeight, scrollTop, clientHeight } = values;

    if (!scrolledFromTop && scrollTop > 0) {
      setScrolledFromTop(true);
    }

    if (scrolledFromTop && scrollTop === 0) {
      setScrolledFromTop(false);
    }

    const bottom = scrollHeight - clientHeight - 50;

    if (
      scrollTop !== 0 &&
      scrollTop >= bottom &&
      !isLoading &&
      orderTable?.hasNextPage
    ) {
      if (scrollInProgress) {
        setSearch({
          ...search,
          page: search.page + 1,
        });
      }
    }
  };

  const onOrderDuplicateHandler = (order: OrderItem) => {
    const queryParams = new URLSearchParams();
    queryParams.append('orderId', order.id!);

    history.push(`/order/duplicate?${queryParams.toString()}`, {
      backToQuotesBoard: isQuoteBoard,
    });
  };

  const setOrderPropsForDelete = (
    orderId: string,
    orderName: string,
    serialNumber: string
  ) => {
    setOrderToDeleteId(orderId);
    setOrderToDeleteName(orderName);
    setOrderToDeleteSerialNumber(serialNumber);
  };

  const onSuccessfulOrderDelete = () => {
    // reset all fields when delete is done
    setOrderPropsForDelete('', '', '');
    setSearch({ ...search, page: 1 });
    toast.success('You have successfully delete a order.');
  };

  const onFailedOrderDelete = (err: ServerErrorResponse) => {
    setOrderPropsForDelete('', '', '');

    toast.error(
      err.status === 403
        ? "You don't have permission to delete this order."
        : 'Order cannot be deleted.'
    );
  };

  const onOrderDeleteHandler = () => {
    dispatch(
      deleteOrder(
        {
          orderId: orderToDeleteId ?? '',
        },
        onSuccessfulOrderDelete,
        onFailedOrderDelete
      )
    );
  };

  const onOrderCreateASAPHandler = (order: OrderItem) => {
    const queryParams = new URLSearchParams();
    queryParams.append('orderId', order.id!);

    history.push(`/order/asap?${queryParams.toString()}`);
  };

  const onOrderCancelRequestSuccessHandler = (
    orderId: string,
    close: () => void
  ) => {
    close();
  };

  const onOrderCancelRequestClickHandler = (
    order: OrderItem,
    close: () => void
  ) => {
    dispatch(
      orderCancelRequest({ orderId: order.id }, () =>
        onOrderCancelRequestSuccessHandler(order.id!, close)
      )
    );
  };

  const onSelectOrderForImportingStyles = (order: OrderItem | Order) => {
    let orderTitle = '';
    if ('room' in order) {
      orderTitle = order.room;
    } else if ('name' in order) {
      orderTitle = order.name;
    }
    dispatch(
      setImportStylesModalMeta({
        desc: ImportStylesModalHeaderEnums.SELECT_STYLE_DESC,
        orderId: order.id,
        step: ImportStylesModalStepsEnums.SELECT_STYLES,
        title: orderTitle as ImportStylesModalHeaderEnums,
        previousTitle: orderTitle,
      })
    );
  };

  const mapProductLines = (productLines: BaseField[] | null) => {
    let productLineText = '';
    if (productLines && productLines.length > 0) {
      productLineText = UtilService.mapProductLineShortName(
        productLines[0].name
      );

      if (productLines.length > 2) {
        productLineText += `, ${UtilService.mapProductLineShortName(
          productLines[1].name
        )},...`;
      } else if (productLines.length === 2) {
        productLineText += `, ${UtilService.mapProductLineShortName(
          productLines[1].name
        )}`;
      }
    }

    return productLineText;
  };

  const getTargetDate = (order: OrderItem) => {
    const csrTab = (search as OrderFilterValues).tab as CsrTabsEnum;
    if (order.scheduleType !== ScheduleType.Regular) {
      return '--';
    }

    switch (csrTab) {
      case CsrTabsEnum['Orders to Write']:
        return order.writingDate
          ? UtilService.formatDate({
              date: order.writingDate,
              asElement: false,
              withTime: false,
            })
          : '--';
      case CsrTabsEnum['Orders to 2nd Check']:
        return order.secondCheckDate
          ? UtilService.formatDate({
              date: order.secondCheckDate,
              asElement: false,
              withTime: false,
            })
          : '--';
      case CsrTabsEnum['Orders to Draw']:
        return order.draftingDate
          ? UtilService.formatDate({
              date: order.draftingDate,
              asElement: false,
              withTime: false,
            })
          : '--';
      case CsrTabsEnum['Orders to Acknowledge']:
        return order.acknowledgementDate
          ? UtilService.formatDate({
              date: order.acknowledgementDate,
              asElement: false,
              withTime: false,
            })
          : '--';
      default:
        return '--';
    }
  };

  const shouldDuplicateOrAsapBeDisabled = (
    orderItem: OrderItem,
    type: 'Duplicate' | 'ASAP'
  ) => {
    if (
      orderItem.stylizationType === OrderStylizationTypeEnums.SALES_MATERIAL
    ) {
      return false;
    }

    if (type === 'ASAP') {
      return (
        orderItem.isOverriden ||
        (orderItem.numberOfStyles ?? orderItem.productLines?.length ?? 0) ===
          0 ||
        (orderItem.asapRequested ?? false)
      );
    }

    return (
      orderItem.isOverriden ||
      (orderItem.numberOfStyles ?? orderItem.productLines?.length ?? 0) === 0
    );
  };

  const getDuplicateOrAsapTooltipText = (
    orderItem: OrderItem,
    type: 'Duplicate' | 'ASAP'
  ) => {
    if (
      (orderItem.numberOfStyles ?? orderItem.productLines?.length ?? 0) === 0
    ) {
      return `Cannot ${type} this Order because it contains no Styles.`;
    }

    if (orderItem.isOverriden) {
      return `Cannot ${type} this Order because all Styles are overriden.`;
    }

    if (type === 'ASAP' && orderItem.asapRequested) {
      return `This Order is already created as ASAP.`;
    }

    return '';
  };

  const isNotSubmittedTab = UtilService.isNotSubmittedTab(
    isUserCSR || isUserSalesRep,
    search
  );

  const ColumnHeader = (columnName: string, column: TableColumns) => (
    <Th
      fontSize={fontSize}
      data-name="15"
      data-direction={sortDir}
      data-active={sortColumn === column}
      onClick={() => onSortHandle(column)}
    >
      {columnName}
    </Th>
  );

  useEffect(() => {
    if (
      currentOrder &&
      importLineItemsFlow === ImportLineItemsFlow.MOVE_LINE_ITEMS
    ) {
      onSelectOrderForImportingStyles(currentOrder);
    }
  }, [currentOrder]);

  return (
    <>
      <ScrollbarsCustom
        autoHeight
        autoHeightMax={500}
        autoHeightMin={500}
        autoHide
        autoHideDuration={300}
        autoHideTimeout={500}
        onScrollStart={() => setScrollInProgress(true)}
        onScrollStop={() => setScrollInProgress(false)}
        onUpdate={handleUpdate}
        ref={(ref) => setTableDivRef(ref)}
      >
        <ScrollTable>
          <TheadStyled scrolledFromTop={scrolledFromTop}>
            <Tr>
              {(isUserCSR || isUserSalesRep) && (
                <Th
                  fontSize={fontSize}
                  data-name="2"
                  width={250}
                  data-direction={sortDir}
                  data-active={sortColumn === TableColumns.DEALERSHIP}
                  onClick={() => onSortHandle(TableColumns.DEALERSHIP)}
                >
                  Dealership
                </Th>
              )}

              <Th
                fontSize={fontSize}
                data-direction={sortDir}
                data-active={sortColumn === TableColumns.JOB}
                onClick={() => onSortHandle(TableColumns.JOB)}
                data-name="3"
              >
                Job
              </Th>

              <Th
                fontSize={fontSize}
                data-direction={sortDir}
                data-active={sortColumn === TableColumns.ROOM}
                onClick={() => onSortHandle(TableColumns.ROOM)}
                data-name="4"
              >
                Room
              </Th>

              {(isUserCSR || isUserSalesRep) && tab === TableTabsEnums.METRICS && (
                <>
                  <Th data-name="5" fontSize={fontSize}>
                    No Charge
                  </Th>
                  <Th data-name="6" fontSize={fontSize}>
                    Replacement
                  </Th>
                  <Th data-name="7" fontSize={fontSize}>
                    Cabinet
                  </Th>
                  <Th data-name="8" fontSize={fontSize}>
                    Total drawings
                  </Th>
                  <Th data-name="9" fontSize={fontSize}>
                    Per Fact. Drawing
                  </Th>
                  <Th data-name="10" fontSize={fontSize}>
                    Per Drawing
                  </Th>
                  <Th data-name="11" fontSize={fontSize}>
                    $Value
                  </Th>
                </>
              )}

              {(tab === TableTabsEnums.GENERAL ||
                tab === TableTabsEnums.IMPORT_STYLE) && (
                <>
                  <Th
                    fontSize={fontSize}
                    data-name="12"
                    data-direction={sortDir}
                    data-active={sortColumn === TableColumns.NUMBER}
                    onClick={() => onSortHandle(TableColumns.NUMBER)}
                  >
                    W.O.#
                  </Th>

                  <Th
                    fontSize={fontSize}
                    data-name="13"
                    data-direction={sortDir}
                    data-active={sortColumn === TableColumns.TYPE}
                    onClick={() => onSortHandle(TableColumns.TYPE)}
                  >
                    Type
                  </Th>
                </>
              )}

              {!isUserCSR &&
                !isUserSalesRep &&
                tab !== TableTabsEnums.IMPORT_STYLE && (
                  <Th
                    fontSize={fontSize}
                    data-name="14"
                    data-direction={sortDir}
                    data-active={sortColumn === TableColumns.ORDERED_BY}
                    onClick={() => onSortHandle(TableColumns.ORDERED_BY)}
                  >
                    Ordered by
                  </Th>
                )}

              {(isUserCSR || isUserSalesRep) &&
                tab === TableTabsEnums.TIMELINE && (
                  <Th
                    fontSize={fontSize}
                    data-name="14.5"
                    data-direction={sortDir}
                    data-active={sortColumn === TableColumns.SUBMISSION_DATE}
                    onClick={() => onSortHandle(TableColumns.SUBMISSION_DATE)}
                  >
                    Submission Date
                  </Th>
                )}

              {((!isUserCSR && !isUserSalesRep) ||
                tab === TableTabsEnums.TIMELINE) &&
                tab !== TableTabsEnums.IMPORT_STYLE && (
                  <>
                    {isNotSubmittedTab
                      ? ColumnHeader(
                          'Requested Date',
                          TableColumns.REQUESTED_DATE
                        )
                      : ColumnHeader('Ship Week', TableColumns.SHIP_WEEK)}
                  </>
                )}

              {(isUserCSR || isUserSalesRep) &&
                tab === TableTabsEnums.TIMELINE && (
                  <>
                    <Th data-name="16" fontSize={fontSize}>
                      Assignees
                    </Th>
                  </>
                )}

              {isUserCSR && tab === TableTabsEnums.TIMELINE && (
                <>
                  <Th
                    fontSize={fontSize}
                    data-name="17"
                    data-direction={sortDir}
                    data-active={sortColumn === TableColumns.TARGET_DATE}
                    onClick={() => onSortHandle(TableColumns.TARGET_DATE)}
                  >
                    Target Date
                  </Th>
                </>
              )}

              {((!isUserCSR && !isUserSalesRep) ||
                tab === TableTabsEnums.TIMELINE) &&
                tab !== TableTabsEnums.IMPORT_STYLE && (
                  <Th
                    fontSize={fontSize}
                    data-name="18"
                    data-direction={sortDir}
                    data-active={sortColumn === TableColumns.STATUS}
                    onClick={() => onSortHandle(TableColumns.STATUS)}
                  >
                    Status
                  </Th>
                )}

              {!isUserCSR && !isUserSalesRep && (
                <Th
                  fontSize={fontSize}
                  data-name="19"
                  data-direction={sortDir}
                  data-active={sortColumn === TableColumns.SUBMISSION_DATE}
                  onClick={() => onSortHandle(TableColumns.SUBMISSION_DATE)}
                >
                  Submission Date
                </Th>
              )}

              {(isUserCSR || isUserSalesRep) && tab === TableTabsEnums.GENERAL && (
                <>
                  <Th
                    fontSize={fontSize}
                    data-name="21"
                    data-direction={sortDir}
                    data-active={sortColumn === TableColumns.PRODUCT_LINE}
                    onClick={() => onSortHandle(TableColumns.PRODUCT_LINE)}
                  >
                    Product Line
                  </Th>

                  <Th
                    fontSize={fontSize}
                    data-name="22"
                    data-direction={sortDir}
                    data-active={sortColumn === TableColumns.GRADE}
                    onClick={() => onSortHandle(TableColumns.GRADE)}
                  >
                    Grade
                  </Th>
                </>
              )}

              {tab === TableTabsEnums.GENERAL && (
                <Th
                  fontSize={fontSize}
                  data-name="23"
                  data-direction={sortDir}
                  data-active={sortColumn === TableColumns.PRIORITY}
                  onClick={() => onSortHandle(TableColumns.PRIORITY)}
                >
                  Priority
                </Th>
              )}

              <Th data-name="24 actions" />
            </Tr>
          </TheadStyled>

          <Tbody>
            {orderTable?.items.map((order: OrderItem) => (
              <StyledTr
                // temp solution until duplicates are fixed.
                key={uniqueId()}
                onClick={
                  !isImportStyle ? () => handleRowClick(order.id) : undefined
                }
                className={
                  (isUserCSR || isUserSalesRep) && order.redTag
                    ? 'highlight'
                    : ''
                }
              >
                {(isUserCSR || isUserSalesRep) && (
                  <Td
                    data-name="2"
                    fontSize={tdFontSize}
                    className="padding"
                    width={250}
                  >
                    <Wrapper flex middle>
                      {order.redTag && (
                        <TagWrapper>
                          <SVG icon={<TagIcon />} color={valencia} />
                        </TagWrapper>
                      )}

                      <ElipsisText maxWidth={order.redTag ? 153 : 202}>
                        {order.dealership?.name}
                      </ElipsisText>
                    </Wrapper>
                  </Td>
                )}

                <Td data-name="3" fontSize={tdFontSize} width={160}>
                  <ElipsisText maxWidth={160} minWidth={160}>
                    {order.job}
                  </ElipsisText>
                </Td>

                <Td data-name="4" fontSize={tdFontSize} width={160}>
                  <ElipsisText maxWidth={160} minWidth={160}>
                    {order.room}
                  </ElipsisText>
                </Td>

                {(isUserCSR || isUserSalesRep) &&
                  tab === TableTabsEnums.METRICS && (
                    <>
                      <Td data-name="5" fontSize={tdFontSize}>
                        {order.noCharge ? 'Yes' : 'No'}
                      </Td>
                      <Td data-name="6" fontSize={tdFontSize}>
                        {order.replacement ? 'Yes' : 'No'}
                      </Td>
                      <Td data-name="7" fontSize={tdFontSize}>
                        {order.cabinetQuantity}
                      </Td>
                      <Td data-name="8" fontSize={tdFontSize}>
                        {order.totalDrawingQuantity}
                      </Td>
                      <Td data-name="9" fontSize={tdFontSize}>
                        {order.perFactoryDrawingQuantity}
                      </Td>
                      <Td data-name="10" fontSize={tdFontSize}>
                        {order.perDrawingQuantity}
                      </Td>
                      <Td data-name="11" fontSize={tdFontSize}>
                        ${order.price.toFixed(2)}
                      </Td>
                    </>
                  )}

                {(tab === TableTabsEnums.GENERAL ||
                  tab === TableTabsEnums.IMPORT_STYLE) && (
                  <>
                    <Td data-name="12" fontSize={tdFontSize}>
                      {order.serialNumber}
                    </Td>
                    <Td data-name="13" fontSize={tdFontSize}>
                      {OrderTypeEnums[order.type]}
                    </Td>
                  </>
                )}

                {!isUserCSR &&
                  !isUserSalesRep &&
                  tab !== TableTabsEnums.IMPORT_STYLE && (
                    <Td data-name="14" fontSize={tdFontSize}>
                      {order.orderedBy}
                    </Td>
                  )}

                {(isUserCSR || isUserSalesRep) &&
                  tab === TableTabsEnums.TIMELINE && (
                    <Td data-name="14.5" fontSize={tdFontSize}>
                      {order.submittedOnUtc
                        ? UtilService.formatDate({
                            date: order.submittedOnUtc,
                          })
                        : '--'}
                    </Td>
                  )}

                {((!isUserCSR && !isUserSalesRep) ||
                  tab === TableTabsEnums.TIMELINE) &&
                  tab !== TableTabsEnums.IMPORT_STYLE && (
                    <>
                      {isNotSubmittedTab ? (
                        <Td data-name="15" fontSize={tdFontSize}>
                          {order.dateRequested || '--'}
                        </Td>
                      ) : (
                        <Td data-name="15" fontSize={tdFontSize}>
                          {UtilService.getShipWeekLabel({
                            defaultValue: '--',
                            shipWeekDescription: order.shipWeekDescription,
                            shipWeekDate: order.shipWeekDate,
                            scheduleType: order.scheduleType,
                          })}
                        </Td>
                      )}
                    </>
                  )}

                {(isUserCSR || isUserSalesRep) &&
                  tab === TableTabsEnums.TIMELINE && (
                    <RelativeTd data-name="16">
                      {order.assignees?.map((assignee, index) => (
                        <AssigneeWrapper key={assignee.id} index={index}>
                          <AvatarCircle user={assignee} size={32} />
                          <Tooltip position="bottom">
                            {assignee.firstName} {assignee.lastName}
                          </Tooltip>
                        </AssigneeWrapper>
                      ))}
                    </RelativeTd>
                  )}

                {isUserCSR && tab === TableTabsEnums.TIMELINE && (
                  <Td data-name="17" fontSize={tdFontSize}>
                    {getTargetDate(order)}
                  </Td>
                )}

                {((!isUserCSR && !isUserSalesRep) ||
                  tab === TableTabsEnums.TIMELINE) &&
                  tab !== TableTabsEnums.IMPORT_STYLE && (
                    <Td data-name="18" fontSize={tdFontSize}>
                      <Wrapper flex middle>
                        {order.status?.name}

                        {order.cancellationRequested && (
                          <>
                            <Spacer w="10px" />
                            <Flash type="Order" />
                          </>
                        )}

                        <SyncToolFailure
                          orderRow={order}
                          position="left"
                          margin="0 0 0 10px"
                        />
                      </Wrapper>
                    </Td>
                  )}

                {!isUserCSR && !isUserSalesRep && (
                  <Td data-name="19" fontSize={tdFontSize}>
                    {order.submittedOnUtc
                      ? UtilService.formatDate({
                          date: order.submittedOnUtc,
                        })
                      : '--'}
                  </Td>
                )}

                {(isUserCSR || isUserSalesRep) &&
                  tab === TableTabsEnums.GENERAL && (
                    <>
                      <Td data-name="21" fontSize={tdFontSize}>
                        {mapProductLines(order.productLines)}
                      </Td>
                      <Td data-name="22" fontSize={tdFontSize}>
                        {order.grade ? GradingOption[order.grade] : ''}
                      </Td>
                    </>
                  )}

                {tab === TableTabsEnums.GENERAL && (
                  <Td data-name="23" fontSize={tdFontSize}>
                    <PriorityBadge priority={order.priority} />
                    {(order.numberOfUnreadTopics ?? 0) > 0 && (
                      <>
                        <Spacer h="5px" />
                        <NewCollaborationBadge />
                      </>
                    )}
                  </Td>
                )}

                <Td
                  data-name="24 actions"
                  align="center"
                  onClick={(e) => e.stopPropagation()}
                >
                  {isImportStyle ? (
                    <ButtonIcon
                      onClick={() => onSelectOrderForImportingStyles(order)}
                      disabled={
                        order.id === orderIdFromUrl &&
                        importLineItemsFlow ===
                          ImportLineItemsFlow.COPY_LINE_ITEMS
                      }
                    >
                      <SVG
                        icon={<ArrowRightIcon />}
                        color={silverChaliceTwo}
                        hoverColor={silverChalice}
                      />
                    </ButtonIcon>
                  ) : (
                    <ThreeDotMenu
                      menuItems={[
                        {
                          label: 'Duplicate',
                          action: () => onOrderDuplicateHandler(order),
                          disabled: shouldDuplicateOrAsapBeDisabled(
                            order,
                            'Duplicate'
                          ),
                          tooltipMessage: getDuplicateOrAsapTooltipText(
                            order,
                            'Duplicate'
                          ),
                        },
                        ...(order.type !== OrderTypeEnums.Quote
                          ? [
                              {
                                label: 'Create ASAP',
                                action: () => onOrderCreateASAPHandler(order),
                                disabled: shouldDuplicateOrAsapBeDisabled(
                                  order,
                                  'ASAP'
                                ),
                                tooltipMessage: getDuplicateOrAsapTooltipText(
                                  order,
                                  'ASAP'
                                ),
                              },
                            ]
                          : []),
                        ...(UtilService.isOrderInStatus({
                          status: OrderStatusEnums['Not Submitted'],
                          orderStatus: order.status,
                          greaterThen: true,
                        }) && orderCanRequestCancellation
                          ? [
                              {
                                label: order.cancellationRequested
                                  ? 'Remove Cancellation Request'
                                  : 'Request Cancellation',
                                requestCancellation: true,
                                disabled:
                                  order.status.id === OrderStatusEnums.Canceled,
                                action: (close: unknown) =>
                                  onOrderCancelRequestClickHandler(
                                    order,
                                    close as () => void
                                  ),
                              },
                            ]
                          : []),
                        ...(UtilService.isOrderInStatus({
                          status: OrderStatusEnums.Received,
                          orderStatus: order.status,
                          greaterThen: false,
                        }) && canDeleteOrder
                          ? [
                              {
                                label: 'Delete',
                                color: valencia,
                                action: () =>
                                  setOrderPropsForDelete(
                                    order.id,
                                    order.room,
                                    order.serialNumber
                                  ),
                              },
                            ]
                          : []),
                      ]}
                    />
                  )}
                </Td>
              </StyledTr>
            ))}

            {/* Empty state */}
            {!isLoading && orderTable && !orderTable.items.length && (
              <Tr>
                {/* hack to span td across all cols regardless the number of cols */}
                <Td colSpan={42}>No {isQuoteBoard ? 'quotes' : 'orders'}.</Td>
              </Tr>
            )}

            {/* No more order to load */}
            {!isLoading &&
              orderTable &&
              orderTable.items.length > 0 &&
              !orderTable.hasNextPage && (
                <Tr noHover>
                  {/* hack to span td across all cols regardless the number of cols */}
                  <Td colSpan={42}>
                    <Wrapper flex center>
                      <EmptyState
                        icon={<IconSearch />}
                        title={`No more ${isQuoteBoard ? 'quotes' : 'orders'}`}
                        marginBottom={30}
                      />
                    </Wrapper>
                  </Td>
                </Tr>
              )}

            {/* METRICS ROW for metrics tab */}
            {(isUserCSR || isUserSalesRep) &&
              tab === TableTabsEnums.METRICS &&
              orderTable?.metrics && (
                <FixedTr>
                  <ItalicTd fontSize={tdFontSize} className="padding">
                    <span>Total: {orderTable.metrics.totalOrders}</span>
                  </ItalicTd>
                  <Td />
                  <Td />
                  <Td />
                  <Td />
                  <ItalicTd fontSize={tdFontSize}>
                    {orderTable.metrics.numberOfCabinets}
                  </ItalicTd>
                  <ItalicTd fontSize={tdFontSize}>
                    {orderTable.metrics.numberOfPerDrawing +
                      orderTable.metrics.numberOfPerFactoryDrawing}
                  </ItalicTd>
                  <ItalicTd fontSize={tdFontSize}>
                    {orderTable.metrics.numberOfPerFactoryDrawing}
                  </ItalicTd>
                  <ItalicTd fontSize={tdFontSize}>
                    {orderTable.metrics.numberOfPerDrawing}
                  </ItalicTd>
                  <ItalicTd fontSize={tdFontSize}>
                    ${orderTable.metrics.totalValue.toFixed(2)}
                  </ItalicTd>
                  <Td />
                </FixedTr>
              )}

            {/* First load skeleton */}
            {isLoading && !orderTable && renderSkeleton()}
          </Tbody>
        </ScrollTable>

        {isLoading && orderTable && (
          <Wrapper flex center>
            <Loader size={40} />
          </Wrapper>
        )}
      </ScrollbarsCustom>

      <ConfirmationModal
        cancel={() => setOrderPropsForDelete('', '', '')}
        confirm={onOrderDeleteHandler}
        htmlMessage={`Are you sure you want to permanently delete order <span style="color: #677D9E;font-weight:bold;">${orderToDeleteName}</span>, <span style="color: #677D9E;font-weight:bold;">W.O.# ${orderToDeleteSerialNumber}</span>?`}
        opened={!!orderToDeleteId}
        title="Confirmation"
      />
    </>
  );
};

OrdersTable.defaultProps = {
  tab: TableTabsEnums.GENERAL,
  isLoading: false,
  isQuoteBoard: false,
};

export { OrdersTable };
